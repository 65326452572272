import React, { Fragment } from 'react'
import { IAssigneeDetails, IReport, ITextInput } from '../../helpers/Interfaces';
import { splitWord, validDateField, validStringField } from '../../helpers/HelperFunctions';
import DocumentButton from '../DocumentButton/DocumentButton';
import InputDropDown from '../InputDropDown/InputDropDown';

type AssignViewProps = {
    assigneeDetails: IAssigneeDetails;
    isAdmin: boolean;
    isScriber: boolean;
    isProofer: boolean;
    isReviewer: boolean;
    scribers: ITextInput[];
    proofers: ITextInput[];
    reviewers: ITextInput[];
    selectedAssignees: any;
    setSelectedAssignees: CallableFunction;
    loading: boolean;
    report: IReport;
    releaseDocument: () => void;
}

function AssignView(props: AssignViewProps) {

    function anyStageInProgress() {
        if (props.assigneeDetails.scribeStatus === "InProgress" || props.assigneeDetails.proofStatus === "InProgress" ||
            props.assigneeDetails.reviewStatus === "InProgress") {
            return true;
        } else {
            return false;
        }
    }

    return (<div className="assign-view-container">
        <div className="content">
            <div className="assign-content">
                <label className="header">Scribe</label>
                {props.assigneeDetails.scribeAllocationDate
                    ? (<div className="assign-data">
                        <div className='row-data'>
                            {AssignData("Scribe Allocation", validStringField(props.assigneeDetails.scribeBy))}
                            {AssignData("Scribe Allocated Date", validDateField(props.assigneeDetails.scribeAllocationDate))}
                            {AssignData("Progress", validStringField(splitWord(props.assigneeDetails.scribeStatus)))}
                            {AssignData("Scribe Completed Date", validDateField(props.assigneeDetails.scribeCompletedDate))}
                        </div>
                    </div>)
                    : (<div className='to-assign-data'>
                        <label className="assign-mem">Scriber not Assigned</label>
                        <div className="search-area">
                            {props.isAdmin || props.isScriber || props.isProofer || props.isReviewer
                                ? (<div className="input-field">
                                    <InputDropDown title={'Select a Scriber'} values={props.scribers} type={'dropdown'}
                                        defaultValue={props.selectedAssignees?.scribeAssignedTo}
                                        onSelect={(value: any) => {
                                            if (value?.length > 0) {
                                                let newSelectedAssignees = props.selectedAssignees;
                                                newSelectedAssignees.scribeAssignedTo = value[0].value;
                                                props.setSelectedAssignees(newSelectedAssignees);
                                            }
                                        }} />
                                </div>)
                                : (<Fragment />)
                            }
                        </div>
                    </div>)
                }
            </div>
            {props.assigneeDetails.scribeCompletedDate
                ? (<div className="assign-content">
                    <label className="header">Proof</label>
                    {props.assigneeDetails.proofAllocationDate
                        ? (<div className="assign-data">
                            <div className='row-data'>
                                {AssignData("Proof Allocation", validStringField(props.assigneeDetails.proofBy))}
                                {AssignData("Proof Allocated Date", validDateField(props.assigneeDetails.proofAllocationDate))}
                                {AssignData("Progress", validStringField(splitWord(props.assigneeDetails.proofStatus)))}
                                {AssignData("Proof Completed Date", validDateField(props.assigneeDetails.proofCompletedDate))}
                            </div>
                        </div>)
                        : (<div className='to-assign-data'>
                            <label className="assign-mem">Proofer not Assigned</label>
                            <div className="search-area">
                                {props.isAdmin || props.isProofer || props.isReviewer
                                    ? (<div className="input-field">
                                        <InputDropDown title={'Select a Proofer'} values={props.proofers} type={'dropdown'}
                                            defaultValue={props.selectedAssignees?.proofAssignedTo}
                                            onSelect={(value: any) => {
                                                if (value?.length > 0) {
                                                    let newSelectedAssignees = props.selectedAssignees;
                                                    newSelectedAssignees.proofAssignedTo = value[0].value;
                                                    props.setSelectedAssignees(newSelectedAssignees);
                                                }
                                            }} />
                                    </div>)
                                    : (<Fragment />)
                                }
                            </div>
                        </div>)
                    }
                </div>)
                : (<Fragment />)
            }
            {props.assigneeDetails.proofCompletedDate
                ? (<div className="assign-content">
                    <label className="header">Review</label>
                    {props.assigneeDetails.reviewAllocationDate
                        ? (<div className="assign-data">
                            <div className='row-data'>
                                {AssignData("Review Allocation", validStringField(props.assigneeDetails.reviewBy))}
                                {AssignData("Review Allocated Date", validDateField(props.assigneeDetails.reviewAllocationDate))}
                                {AssignData("Progress", validStringField(splitWord(props.assigneeDetails.reviewStatus)))}
                                {AssignData("Review Completed Date", validDateField(props.assigneeDetails.reviewCompletedDate))}
                            </div>
                        </div>)
                        : (<div className='to-assign-data'>
                            <label className="assign-mem">Reviewer not Assigned</label>
                            <div className="search-area">
                                {((props.isAdmin || props.isReviewer) && props.report.documentStatus !== 'Submitted' && props.report.documentStatus !== 'Completed')
                                    ? (<div className="input-field">
                                        <InputDropDown title={'Select a Reviewer'} values={props.reviewers} type={'dropdown'}
                                            defaultValue={props.selectedAssignees?.reviewAssignedTo}
                                            onSelect={(value: any) => {
                                                if (value?.length > 0) {
                                                    let newSelectedAssignees = props.selectedAssignees;
                                                    newSelectedAssignees.reviewAssignedTo = value[0].value;
                                                    props.setSelectedAssignees(newSelectedAssignees);
                                                }
                                            }} />
                                    </div>)
                                    : (<Fragment />)
                                }
                            </div>
                        </div>)
                    }
                </div>)
                : (<Fragment />)
            }
        </div>
        <div className="footer">
            {props.isAdmin && anyStageInProgress()
                ? (<div className="button-content">
                    <div className="button">
                        <DocumentButton text={'Release'} onClick={() => props.releaseDocument()} loading={props.loading} />
                    </div>
                </div>)
                : (<Fragment />)
            }
        </div>
    </div>);
}

function AssignData(header: string, data: string) {
    return (<div className="data">
        <label className="assign-header">{header}</label>
        <label className="assign-body">{data}</label>
    </div>)
}

export default AssignView