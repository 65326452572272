import { FilledInput, InputAdornment } from '@mui/material';
import { Fragment, ReactElement, SetStateAction, useState } from "react";
import SearchIcon from "../../assets/images/Search.svg";
import DropdownIcon from "../../assets/images/Dropdown.svg";
import { Popover } from 'antd';

import "./DropDown.scss";

function DropDown(props: {
    onSelect: (selectedValue: string) => void; initialDetails: string[]; searchable: boolean; setSelectedFilter: () => void
}) {

    const [searchField, setSearchField] = useState("");

    const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSearchField(e.target.value);
    };

    function SearchList(prop: { filteredPersons: any }) {

        const filtered = prop.filteredPersons.map((value: any, key: number) => <Card key={key} text={value} />);

        return (<div>{filtered}</div>);
    }

    function Card(prop: { text: string; }) {
        return (
            <div onClick={(value: any) => { props.onSelect(prop.text); }} className="item">
                {prop.text}
            </div>
        )
    }

    const Scroll = (props: { children: any }) => {
        return (
            <div style={{ overflowY: 'scroll', maxHeight: '20vh' }}>
                {props.children}
            </div>
        );
    }

    const filteredSearch = props.initialDetails.filter(data => {
        if (typeof data === 'string') {
            return data.toLowerCase().includes(searchField.toLowerCase());
        } else {
            return false;
        }
    });

    function searchList() {
        return (
            <Scroll>
                <SearchList filteredPersons={filteredSearch} />
            </Scroll>
        );
    }

    return (
        <Popover
            content={
                <div className="drp-search-container">
                    {props.searchable
                        ? <FilledInput id="filled-adornment" disableUnderline={true} type={"text"} placeholder={"Search"}
                            classes={{ root: "search-container", input: "input" }} onChange={handleChange}
                            startAdornment={
                                <InputAdornment classes={{ root: "img-container" }} position="end">
                                    <img className="img" src={SearchIcon} alt={"Search"} />
                                </InputAdornment>
                            }
                        />
                        : <Fragment />
                    }
                    {searchList()}
                </div>
            }
            trigger="click"
            placement="bottom"
            showArrow={false}
        >
            <img onClick={props.setSelectedFilter} className="icon" src={props.searchable ? SearchIcon : DropdownIcon} alt={"Search"} />
        </Popover>
    )
}


export default DropDown