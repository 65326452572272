import { Alert, Snackbar } from "@mui/material";

type MuiAlertProps = {
    error: boolean | "warning";
    message: string;
    open: boolean;
    onClose: CallableFunction
}

export default function MuiAlert(props: MuiAlertProps) {
    return (<Snackbar key={"top" + "center"} open={props.open} autoHideDuration={2000} onClose={() => { props.onClose() }}
        anchorOrigin={{ "vertical": "top", "horizontal": "center" }}>
        <Alert severity={props.error === "warning" ? "warning" : props.error ? "error" : "success"} sx={{ width: '100%' }}>
            {props.message}
        </Alert>
    </Snackbar>)
}