import DocumentButton from '../DocumentButton/DocumentButton'
import ReOpen from "../../assets/images/ReOpenDocument.svg";

import "../../components/DeletePrompt/DeletePrompt.scss"

type ReOpenDocumentViewProps = {
    readonly onReOpen: CallableFunction,
    readonly loading: boolean
}

function ReOpenDocument({ loading, onReOpen }: ReOpenDocumentViewProps) {
    return (
        <div className="delete-report delete-doc-container">
            <img className='trash-icon' src={ReOpen} alt="TrashRedBack" />
            <div className="content">
                <label className='header'>Re-Open Document</label>
                <label className='body'>This document will be sent back for transcription. Are you sure you want to continue</label>
            </div>
            <div className="footer">
                <DocumentButton backgroundColor='#dd3a3a' text={'Re-Open'} onClick={onReOpen} loading={loading} />
            </div>
        </div>
    )
}

export default ReOpenDocument