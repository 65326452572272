import { Fragment } from "react";
import StepperDotsIcon from "../../assets/images/StepperDots.svg";

import "./Stepper.scss";

export default function Stepper(props: { steps: object[] }) {
  return (
    <div className="stepper-container">
      {props.steps.map((step: any, index: number) => {
        return (
          <div key={index} className="step-wrapper">
            <div className="step">
              <span className={`int ${step.circleStyle}`}>{index + 1}</span>
              <div className="text">
                <span className="step">STEP</span>
                <span className={`description  ${step.descriptionStyle}`}>
                  {step.description}
                </span>
              </div>
            </div>
            {index + 1 !== props.steps.length ? (
              <img className="img" src={StepperDotsIcon} alt={"Dots"} />
            ) : (
              <Fragment />
            )}
          </div>
        );
      })}
    </div>
  );
}
