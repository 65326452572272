import { Fragment, useEffect, useState } from "react";
import ContractIcon from "../../assets/images/Contract.svg";
import ExpandIcon from "../../assets/images/Expand.svg";
import ScribingIcon from "../../assets/images/Scribing.svg";
import { convertToMinutes } from "../../helpers/HelperFunctions";
import { ISummaryBox } from "../../helpers/Interfaces";
import { StageFilterTypes } from "../../helpers/Types";

import "./SummaryBox.scss";

type SummaryBoxProps = {
    data: ISummaryBox;
    filter: (stage: StageFilterTypes) => void;
    tabValue: number;
    isAdmin: boolean;
    isScribe: boolean;
    cancelFilter: boolean;
    setCancelFilter: CallableFunction
}

export default function SummaryBox(props: SummaryBoxProps) {

    const [expand, setExpand] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("");

    function canFocus() {
        if (props.tabValue === 1 || props.tabValue === 4 || props.tabValue === 5) {
            return false;
        }
        return true;
    }

    const menuSelect = (filter: any) => {
        setSelectedFilter(filter);
        props.setCancelFilter(false);
        props.filter(filter);
    };

    const getClassName = (filter: any) => {
        if (props.cancelFilter) {
            return `text ${"focus"} ${""}`;
        }
        return `text ${canFocus() ? "focus" : ""} ${selectedFilter === filter ? "selected" : ""}`;
    };


    return (<div className="summary-box-container">
        {expand
            ? (<div className="blue-box maximize">
                <div className="maximized-box-cell">
                    <div className="heading">
                        <div className="scribe-image-container">
                            <img className="icon" src={ScribingIcon} alt={"Scribing"} />
                        </div>
                        <span onClick={() => { menuSelect("Scribe") }} className={getClassName("Scribe")}>Scribing</span>
                    </div>
                    <div className="row">
                        <div className="cell margin-right">
                            <span className="text">TOTAL MINS</span>
                            <span className="value">{convertToMinutes(props.data.scribeTotalMins) + " min"}</span>
                        </div>
                        <div className="cell">
                            <span className="text">OUT OF TAT</span>
                            <span className="value">{convertToMinutes(props.data.scribeOutOfTAT) + " min"}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cell margin-right">
                            <span className="text">PENDING</span>
                            <span className="value">{convertToMinutes(props.data.scribePendingMins) + " min"}</span>
                        </div>
                        <div className="cell">
                            <span className="text">IN-PROGRESS</span>
                            <span className="value">{convertToMinutes(props.data.scribeInProgressMins) + " min"}</span>
                        </div>
                    </div>
                </div>
                {!props.isScribe
                    ? (<>
                        <div className="maximized-box-cell show-border">
                            <div className="heading">
                                <div className="scribe-image-container">
                                    <img className="icon" src={ScribingIcon} alt={"Proofing"} />
                                </div>
                                <span onClick={() => menuSelect("Proof")} className={getClassName("Proof")}>
                                    Proofing
                                </span>
                            </div>
                            <div className="row">
                                <div className="cell margin-right">
                                    <span className="text">TOTAL MINS</span>
                                    <span className="value">{convertToMinutes(props.data.proofTotalMins) + " min"}</span>
                                </div>
                                <div className="cell">
                                    <span className="text">OUT OF TAT</span>
                                    <span className="value">{convertToMinutes(props.data.proofOutOfTAT) + " min"}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell margin-right">
                                    <span className="text">PENDING</span>
                                    <span className="value">{convertToMinutes(props.data.proofPendingMins) + " min"}</span>
                                </div>
                                <div className="cell">
                                    <span className="text">IN-PROGRESS</span>
                                    <span className="value">{convertToMinutes(props.data.proofInProgressMins) + " min"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="maximized-box-cell">
                            <div className="heading">
                                <div className="scribe-image-container">
                                    <img className="icon" src={ScribingIcon} alt={"Reviewing"} />
                                </div>
                                <span onClick={() => menuSelect("Review")} className={getClassName("Review")}>
                                    Reviewing
                                </span>
                            </div>
                            <div className="row">
                                <div className="cell margin-right">
                                    <span className="text">TOTAL MINS</span>
                                    <span className="value">{convertToMinutes(props.data.reviewTotalMins) + " min"}</span>
                                </div>
                                <div className="cell">
                                    <span className="text">OUT OF TAT</span>
                                    <span className="value">{convertToMinutes(props.data.reviewOutOfTAT) + " min"}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell margin-right">
                                    <span className="text">PENDING</span>
                                    <span className="value">{convertToMinutes(props.data.reviewPendingMins) + " min"}</span>
                                </div>
                                <div className="cell">
                                    <span className="text">IN-PROGRESS</span>
                                    <span className="value">{convertToMinutes(props.data.reviewInProgressMins) + " min"}</span>
                                </div>
                            </div>
                        </div>
                    </>)
                    : (<Fragment />)
                }
                <div className="arrow" onClick={() => { setExpand(!expand); }}>
                    <img className="icon" src={ContractIcon} alt={"Contract"} />
                </div>
            </div>)
            : (<div className="blue-box minimize">
                <div className="minimized-box-cell">
                    <div className="image-box">
                        <div className="scribe-image-container">
                            <img className="icon" src={ScribingIcon} alt={"Scribing"} />
                        </div>
                        <span onClick={() => { menuSelect("Scribe") }} className={getClassName("Scribe")}>Scribing</span>
                    </div>
                    {props.isAdmin
                        ? (<div className="info-container">
                            <span className="pending">PENDING</span>
                            <span className="time">{convertToMinutes(props.data.scribePendingMins) + " min"}</span>
                        </div>)
                        : (<div className="info-container">
                            <span className="pending">IN PROGRESS</span>
                            <span className="time">{convertToMinutes(props.data.scribeInProgressMins) + " min"}</span>
                        </div>)
                    }
                </div>
                {!props.isScribe
                    ? (<>
                        <div className="minimized-box-cell show-border">
                            <div className="image-box">
                                <div className="scribe-image-container">
                                    <img className="icon" src={ScribingIcon} alt={"Proofing"} />
                                </div>
                                <span onClick={() => menuSelect("Proof")} className={getClassName("Proof")}>
                                    Proofing
                                </span>
                            </div>
                            {props.isAdmin
                                ? (<div className="info-container">
                                    <span className="pending">PENDING</span>
                                    <span className="time">{convertToMinutes(props.data.proofPendingMins) + " min"}</span>
                                </div>)
                                : (<div className="info-container">
                                    <span className="pending">IN PROGRESS</span>
                                    <span className="time">{convertToMinutes(props.data.proofInProgressMins) + " min"}</span>
                                </div>)
                            }
                        </div>
                        <div className="minimized-box-cell">
                            <div className="image-box">
                                <div className="scribe-image-container">
                                    <img className="icon" src={ScribingIcon} alt={"Reviewing"} />
                                </div>
                                <span onClick={() => menuSelect("Review")} className={getClassName("Review")}>
                                    Reviewing
                                </span>
                            </div>
                            {props.isAdmin
                                ? (<div className="info-container">
                                    <span className="pending">PENDING</span>
                                    <span className="time">{convertToMinutes(props.data.reviewPendingMins) + " min"}</span>
                                </div>)
                                : (<div className="info-container">
                                    <span className="pending">IN PROGRESS</span>
                                    <span className="time">{convertToMinutes(props.data.reviewInProgressMins) + " min"}</span>
                                </div>)
                            }
                        </div>
                    </>)
                    : (<Fragment />)
                }
                <div className="arrow" onClick={() => { setExpand(!expand); }}>
                    <img className="icon" src={ExpandIcon} alt={"Expand"} />
                </div>
            </div>)
        }
    </div>)
}