import { useState } from 'react'
import { validStringField } from '../../helpers/HelperFunctions';
import { ISupportStaff } from '../../helpers/Interfaces';
import Modal from '../Modal/Modal';

import "./ViewStaff.scss"

function ViewStaff(props: { open: boolean, close: CallableFunction, selectedStaffMember: ISupportStaff, openEdit: CallableFunction }) {

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleClose = (shouldUpdate: boolean) => {
        props.close("Support Staff", "view", shouldUpdate);
    }

    const handleOpenEdit = () => {
        props.openEdit("Support Staff", "edit");
        handleClose(false);
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={true} open={props.open} close={handleClose}
            styles={"view-view-staff-card"} openEdit={handleOpenEdit}>
            <div className="view-staff-container">
                <div className='title'>View Staff Member</div>
                <div className="row">
                    <div className="data">
                        <span className="header">{"First Name"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.firstName)}</span>
                    </div>
                    <div className="data">
                        <span className="header">{"Last Name"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.lastName)}</span>
                    </div>
                    <div className="data">
                        <span className="header">{"Email Address"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.email)}</span>
                    </div>
                    <div className="data">
                        <span className="header">{"Telephone Number"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.phoneNumber)}</span>
                    </div>
                    <div className="data">
                        <span className="header">{"Doctor"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.assignedDoctor)}</span>
                    </div>
                    <div className="data">
                        <span className="header">{"Clinic"}</span>
                        <span className="body">{validStringField(props.selectedStaffMember.assignedClinic)}</span>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewStaff