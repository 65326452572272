import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import DocumentButton from "../../components/DocumentButton/DocumentButton";
import InputDropDown from "../../components/InputDropDown/InputDropDown";
import Layout from "../../components/Layout/Layout";
import DownloadOrange from "../../assets/images/DownloadOrange.svg";
import AnalyticsTime from "../../assets/images/AnalyticsTime.svg";
import AnalyticsDoc from "../../assets/images/AnalyticsDoc.svg";
import AnalyticsClock from "../../assets/images/AnalyticsClock.svg";
import GreyDot from "../../assets/images/GreyDot.svg";
import GreenDot from "../../assets/images/GreenDot.svg";
import OrangeDot from "../../assets/images/OrangeDot.svg";
import { Fragment, useCallback, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import moment from "moment";
import { IAnalyticsData, IAnalyticsFormattedReport, IClinic, IDoctor, IGetUserAnalytics, ITextInput, IUser } from "../../helpers/Interfaces";
import { convertDuration, progress, progressStatus, stageStatus } from "../../helpers/HelperFunctions";
import MuiAlert from "../../components/Alert/Alert";

import "./Analytics.scss";

export default function Analytics() {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        role: null, fromDate: moment.utc().local().add(-1, "days"), toDate: moment.utc().local(), userId: null, doctorId: null, clinicId: null
    });
    const [formDataErrors, setFormDataErrors] = useState({
        roleError: { showError: false, message: "" }, fromDateError: { showError: false, message: "" },
        toDateError: { showError: false, message: "" }
    });
    const [analytics, setAnalytics] = useState({} as IGetUserAnalytics);
    const [usersList, setUsersList] = useState([] as IUser[]);
    const [doctors, setDoctors] = useState([] as IDoctor[]);
    const [clinics, setClinics] = useState([] as IClinic[]);
    const [showAlert, setShowAlert] = useState<{ isError: boolean | "warning", message: string, open: boolean }>({ isError: false, message: "", open: false });

    const closeAlert = useCallback(() => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }, [])

    useEffect(() => {
        getUsers();
        getDoctors();
        getClinics();
    }, [])

    function downloadReport() {
        if (analytics.reports.length > 0) {

            let csvRows = [];

            const mainHeaders = ["Stage", "User", "Doctor", "Clinic", "Total Reports", "Duration"];

            csvRows.push(mainHeaders.join(','));
            csvRows.push('\n')

            let formattedHeader = { stage: "", user: "", doctor: "", clinic: "", totalReports: 0, duration: 0 };

            formattedHeader.stage = analytics.stage;
            formattedHeader.user = analytics.userName;
            formattedHeader.doctor = analytics.doctorName;
            formattedHeader.clinic = analytics.clinicName;
            formattedHeader.totalReports = analytics.totalReports;
            formattedHeader.duration = analytics.totalDuration;

            let values = Object.values(formattedHeader);
            csvRows.push(values.join(','))
            csvRows.push('\n')
            csvRows.push('\n')

            const headers = ["Doctor", "Patient Name", "Clinic", "Report Type", "Created On", "Completed On", "Duration", "Stage", "Progress", "Assigned On", "Submitted On", "Time Worked"];

            csvRows.push(headers.join(','));
            csvRows.push('\n')

            analytics.reports.forEach((report) => {

                let formattedReport: IAnalyticsFormattedReport = { doctorName: "", patientName: "", clinicName: "", reportType: "", startedDate: "-", completedDate: "-", duration: 0, stage: "", progress: "", allocatedTime: "-", finishedTime: "-", timeWorked: 0 };

                formattedReport.doctorName = report.doctorName;
                formattedReport.patientName = report.patientName;
                formattedReport.clinicName = report.clinicName;
                formattedReport.reportType = report.reportType;
                formattedReport.duration = report.totalDuration;
                formattedReport.stage = stageStatus(report.stage);
                formattedReport.progress = progress(report.progressStatus);
                formattedReport.timeWorked = report.timeTaken;


                if (report.createdDate) {
                    formattedReport.startedDate = (moment.utc(report.createdDate).local().format("DD-MM-YYYY"))
                }

                if (report.endDate) {
                    formattedReport.completedDate = (moment.utc(report.endDate).local().format("DD-MM-YYYY"))
                }
                if (report.allocationTime) {
                    formattedReport.allocatedTime = (moment.utc(report.allocationTime).local().format("DD-MM-YYYY HH:mm"))
                }

                if (report.completedTime) {
                    formattedReport.finishedTime = (moment.utc(report.completedTime).local().format("DD-MM-YYYY HH:mm"))
                }

                let values = Object.values(formattedReport);
                csvRows.push(values.join(','))
                csvRows.push('\n')
            })

            const blob = new Blob(csvRows, { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('href', url)
            a.setAttribute('Report', 'download.csv');
            a.click()
        }
    }

    function getUsers() {
        setLoading(true);
        apiService.getUsers().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }
            setUsersList(data?.users);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function getDoctors() {

        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setDoctors(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        // let data = {
        //     doctorId: formData.doctorId
        // }

        apiService.getClinics().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setClinics(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }


    function getAnalytics() {
        setAnalytics({} as IGetUserAnalytics);
        setLoading(true);
        // if (formData.role?.trim() === "") {
        //     let newFormDataErrors = formDataErrors;
        //     newFormDataErrors.roleError.showError = true;
        //     newFormDataErrors.roleError.message = "SELECT A STAGE";
        //     setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        //     setLoading(false);
        //     return;
        // } else {
        //     let newFormDataErrors = formDataErrors;
        //     newFormDataErrors.roleError.showError = false;
        //     setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        // }

        if (!(formData.fromDate instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.fromDateError.showError = true;
            newFormDataErrors.fromDateError.message = "SELECT A DATE";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.fromDateError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!(formData.toDate instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.toDateError.showError = true;
            newFormDataErrors.toDateError.message = "SELECT A DATE";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.toDateError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        apiService.getUserAnalytics(formData).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = "warning";
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }
            setAnalytics(data?.scriberAnalytics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })

    }

    return (<Layout selectedIndex={5}>
        <div className="analytics-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open} onClose={closeAlert} />
            <div className="top-bar">
                <span className="text">Analytics</span>
            </div>
            <div className="top-header">
                <div className="content">
                    <div className="forms">
                        <div className="input-drp">
                            <InputDropDown type="dropdown" title={"USER"}
                                values={[
                                    { label: "All Users", value: null },
                                    ...usersList.map((user) => ({
                                        label: `${user.firstName} ${user.lastName}`,
                                        value: user.id,
                                    }))
                                ]}
                                width={175}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        formData.userId = value[0]?.value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                                    }
                                }} />
                        </div>
                        <div className="input-drp">
                            <InputDropDown type="dropdown" title={"STAGE"}
                                values={[{ label: "All", value: null }, { label: "Scribe", value: "Scribe" }, { label: "Proof", value: "Proof" },
                                { label: "Review", value: "Review" }]}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        formData.role = value[0]?.value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                                    }
                                }} />
                        </div>
                        <div className="input-drp">
                            <InputDropDown type="dropdown" title={"DOCTOR"}
                                width={200}
                                values={[
                                    { label: "All Doctors", value: null },
                                    ...doctors.map((doctor) => ({
                                        label: `${doctor.firstName} ${doctor.lastName}`,
                                        value: doctor.id,
                                    }))
                                ]}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        formData.doctorId = value[0]?.value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                                    }
                                }} />
                        </div>
                        <div className="input-drp">
                            <InputDropDown type="dropdown" title={"CLINIC"}
                                width={175}
                                values={[
                                    { label: "All Clinics", value: null },
                                    ...clinics.map((clinic) => ({
                                        label: `${clinic.clinicName}`,
                                        value: clinic.id,
                                    }))
                                ]}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        formData.clinicId = value[0]?.value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                                    }
                                }} />
                        </div>
                        <div className="date-input">
                            <DateRangePicker startPlaceHolder="FROM*" endPlaceHolder="TO*"
                                onChange={(startDate, endDate) => {
                                    let newStartDate = startDate!.setHours(0, 0, 0);
                                    let newEndDate = endDate!.setHours(23, 59, 59);
                                    let newFormData = formData;
                                    formData.fromDate = moment.utc(newStartDate);
                                    formData.toDate = moment.utc(newEndDate);
                                    setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                                }} />
                        </div>
                    </div>
                    <div className="button-content">
                        <DocumentButton loading={loading} onClick={() => getAnalytics()} text={"Search"} />
                    </div>
                </div>
                <div className="download-content" onClick={() => { downloadReport() }}>
                    <img src={DownloadOrange} alt="Download" />
                    <div className="data">
                        <label htmlFor="download" className="download">Download</label>
                        <label className="report">Full Report</label>
                    </div>
                </div>
            </div>
            {analytics && Object.keys(analytics).length === 0 && Object.getPrototypeOf(analytics) === Object.prototype
                ? (<Fragment />)
                : (<>
                    <div className="user-analytics">
                        <div className="legend-header">
                            <div className="title-user">Analytics of<span className="name">{analytics.userName ? analytics.userName : "All Users"}{analytics.stage ? " - " + analytics.stage : " - " + "All Stages"}{analytics.doctorName ? " - " + analytics.doctorName : ""}{analytics.clinicName ? " - " + analytics.clinicName : " - All Clinics"}</span></div>
                            <div className="indicator-box">
                                <div className="indicator">
                                    <span className="indicator-text margin-right">
                                        <img className="dot" src={GreenDot} alt={"Dot"} />
                                        COMPLETED
                                    </span>
                                    <span className="indicator-text margin-right">
                                        <img className="dot" src={OrangeDot} alt={"Dot"} />
                                        INPROGRESS
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-data">
                            <div className="card-content">
                                <img src={AnalyticsDoc} className="card-img" alt="AnalyticsDoc" />
                                <div className="details">
                                    <span className="header">Total Reports</span>
                                    <span className="body">
                                        {analytics?.totalReports
                                            ? (analytics.totalReports)
                                            : (0)
                                        }</span>
                                </div>
                            </div>
                            <div className="card-content">
                                <img src={AnalyticsTime} className="card-img" alt="AnalyticsDoc" />
                                <div className="details">
                                    <span className="header">Total Duration</span>
                                    <span className="body">
                                        {analytics?.totalDuration
                                            ? (convertDuration(analytics.totalDuration) + " Mins")
                                            : (0 + " Mins")
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="card-content">
                                <img src={AnalyticsClock} className="card-img" alt="AnalyticsDoc" />
                                <div className="details">
                                    <span className="header">Average minutes / Day*</span>
                                    <span className="body">
                                        {analytics?.durationPerDay
                                            ? (convertDuration(analytics.durationPerDay) + " Mins")
                                            : (0 + " Mins")
                                        }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table home-analytics-table">
                        <div className="header">
                            <div className="header-row medium">
                                <span className="header-text">Doctor Name</span>
                            </div>
                            <div className="header-row medium">
                                <span className="header-text">Patient Name</span>
                            </div>
                            {/* <div className="header-row medium">
                                <span className="header-text">Clinic</span>
                            </div> */}
                            <div className="header-row medium">
                                <span className="header-text">Report Type</span>
                            </div>
                            <div className="header-row small">
                                <span className="header-text">Duration</span>
                            </div>
                            <div className="header-row small">
                                <span className="header-text">Created On</span>
                            </div>
                            <div className="header-row small">
                                <span className="header-text">Completed On</span>
                            </div>
                            <div className="header-row small">
                                <span className="header-text">Stage</span>
                            </div>
                            <div className="header-row medium">
                                <span className="header-text">Assigned On</span>
                            </div>
                            <div className="header-row medium">
                                <span className="header-text">Submitted On</span>
                            </div>
                            <div className="header-row small">
                                <span className="header-text">Time Worked</span>
                            </div>
                        </div>
                        <div className="data">
                            {analytics?.reports?.map((report, index: number) => {
                                return (<div key={index} className="data-row-analysis">
                                    <span className="cell text medium">{report.doctorName}</span>
                                    <span className="cell text medium">{report.patientName}</span>
                                    {/* <span className="cell text medium">{report.clinicName}</span> */}
                                    <span className="cell text medium">{report.reportType}</span>
                                    <span className="cell text small">{convertDuration(report.totalDuration)}</span>
                                    <span className="cell text small">
                                        {report.createdDate
                                            ? (moment.utc(report.createdDate).local().format("DD-MM-YYYY"))
                                            : (" - ")
                                        }
                                    </span>
                                    <span className="cell text small">
                                        {report.endDate
                                            ? (moment.utc(report.endDate).local().format("DD-MM-YYYY"))
                                            : (" - ")
                                        }
                                    </span>
                                    <span className="cell text small">{progressStatus(report.progressStatus)}{stageStatus(report.stage)}</span>
                                    <span className="cell text medium">{report.allocationTime
                                        ? (moment.utc(report.allocationTime).local().format("DD-MM-YYYY HH:mm"))
                                        : (" - ")
                                    }</span>
                                    <span className="cell text medium">{report.completedTime
                                        ? (moment.utc(report.completedTime).local().format("DD-MM-YYYY HH:mm"))
                                        : (" - ")
                                    }</span>
                                    <span className="cell text small">{report?.timeTaken
                                        ? (convertDuration(report.timeTaken))
                                        : (0)
                                    }</span>
                                </div>)
                            })}
                        </div>
                    </div>
                </>)
            }
        </div>
    </Layout>)
}