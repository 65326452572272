import React, { useState, useEffect, Fragment } from 'react'
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import { IAssignee, IClinic, IDoctor, ITextInput } from '../../helpers/Interfaces';
import BlueHomeDot from "../../assets/images/BlueHomeDot.svg";
import BlueHomeDotDisable from "../../assets/images/BlueHomeDotDisable.svg";
import InputDropDown from '../InputDropDown/InputDropDown';
import { Checkbox } from '@mui/material';
import { Colors } from '../../helpers/Colors';
import ApiService from '../../services/ApiService';

import "./EditDoctor.scss"

function EditDoctor(props: {
    open: boolean; close: CallableFunction; selectedDoctor: IDoctor;
    clinics: IClinic[], showAlert: CallableFunction
}) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState({
        firstName: "", lastName: "", email: "", address: "", phoneNumber: "", abn: "", clinics: [] as string[],
        scribers: [] as string[]
    });
    const [formDataErrors, setFormDataErrors] = useState({
        firstNameError: { showError: false, message: "" }, lastNameError: { showError: false, message: "" },
        emailError: { showError: false, message: "" }, selectedClinicError: { showError: false, message: "" }
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([] as ITextInput[]);
    const [scribers, setScribers] = useState([] as IAssignee[]);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if (props.clinics.length > 0) {
            getClinicTextInput();
        }
    }, [props.clinics])

    useEffect(() => {
        getScribers();
    }, [])

    useEffect(() => {
        loadData();

        if (props.selectedDoctor.id.trim() !== "") {
            getClinics();
            getSelectedScribers();
        }
    }, [props.selectedDoctor])

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: props.selectedDoctor.id
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newFormData = formData;

            data?.clinics.forEach((clinic: IClinic) => {
                if (!formData.clinics.includes(clinic.id)) {
                    newFormData.clinics.push(clinic.id);
                }
            });

            setFormData((prevData: any) => ({ ...prevData, ...newFormData }));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getScribers() {

        setLoading(true);

        apiService.getAssignees().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setScribers(data?.users.filter((u: any) => u.role === "Scribe"));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getSelectedScribers() {

        setLoading(true);

        let data = {
            doctorId: props.selectedDoctor.id
        }

        apiService.getScribersByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newFormData = formData;

            data?.scribers.forEach((element: IAssignee) => {
                if (!formData.scribers.includes(element.email)) {
                    newFormData.scribers.push(element.email);
                }
            });

            setFormData((prevData: any) => ({ ...prevData, ...newFormData }));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function loadData() {
        let newFormData = formData;
        newFormData.firstName = props.selectedDoctor.firstName;
        newFormData.lastName = props.selectedDoctor.lastName;
        newFormData.email = props.selectedDoctor.email;
        newFormData.address = props.selectedDoctor.address;
        newFormData.phoneNumber = props.selectedDoctor.phoneNumber;
        newFormData.abn = props.selectedDoctor.abn;
        newFormData.clinics = [];
        newFormData.scribers = [];
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData, }));
    }

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Doctor", "edit", shouldUpdate);
    }

    function getClinicTextInput() {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        props.clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function clearData() {
        let newFormData = formData;
        newFormData.firstName = "";
        newFormData.lastName = "";
        newFormData.email = "";
        newFormData.address = "";
        newFormData.phoneNumber = "";
        newFormData.abn = "";
        newFormData.clinics = [];
        newFormData.scribers = [];
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.firstNameError.showError = false;
        newFormDataErrors.lastNameError.showError = false;
        newFormDataErrors.emailError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function update() {

        setLoading(true);

        if (formData.firstName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = true;
            newFormDataErrors.firstNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.lastName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = true;
            newFormDataErrors.lastNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.email?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = true;
            newFormDataErrors.emailError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.clinics.length === 0) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "SELECT CLINIC/S";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            setLoading(false);
            return;
        } else {
            let newShowAlert = showAlert;
            newShowAlert.open = false;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }

        let data = Object.assign(formData, { id: props.selectedDoctor.id });

        apiService.updateDoctor(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.firstName + " " + formData.lastName + " Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-edit-doctor-card"} >
            <div className="view-edit-doctor-container">
                <div className='title'>Edit Doctor</div>
                <div className="content">

                    <div className="row">
                        <div className="input-field">
                            <Input label='FIRST NAME*' value={formData.firstName} type='text' showError={formDataErrors.firstNameError.showError}
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.firstName = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='LAST NAME*' value={formData.lastName} type='text' showError={formDataErrors.lastNameError.showError}
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.lastName = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="input-field">

                            <Input label='EMAIL ADDRESS*' value={formData.email} type='text' showError={formDataErrors.emailError.showError}
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.email = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='ADDRESS' value={formData.address} type='text'
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.address = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='TELEPHONE NUMBER' value={formData.phoneNumber} type='text'
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.phoneNumber = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='ABN' value={formData.abn} type='text'
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.abn = value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                    </div>
                    <div className="view-data">
                        <div className="data-header">
                            <span onClick={() => setSelectedTab(0)} className={`header ${selectedTab === 0 ? "selected-header" : ""}`}>
                                Clinics
                            </span>
                            <span onClick={() => setSelectedTab(1)} className={`header ${selectedTab === 1 ? "selected-header" : ""}`}>
                                Scribers
                            </span>
                        </div>
                        {selectedTab === 0
                            ? (<div className="details">
                                {clinics.map((clinic) => {
                                    return (<div key={clinic.value} className="row-clinic">
                                        <span className="name">{clinic.label}</span>
                                        <Checkbox checked={formData.clinics.includes(clinic.value) ? true : false}
                                            icon={<img alt='Check' src={BlueHomeDotDisable} />}
                                            checkedIcon={<img alt='' src={BlueHomeDot} />}
                                            value="cryon" style={{ color: Colors.white_10 }}
                                            onChange={(data: any) => {
                                                if (data.target.checked) {
                                                    let newFormData = formData;
                                                    newFormData.clinics.push(clinic.value)
                                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                                }
                                                else {
                                                    let newFormData = formData;
                                                    newFormData.clinics = newFormData.clinics.filter((item: any) => {
                                                        return item !== clinic.value
                                                    });
                                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                                }
                                            }} />
                                    </div>);
                                })}
                            </div>)
                            : (<Fragment />)
                        }

                        {selectedTab === 1
                            ? (<div className="details">
                                {scribers.map((scriber) => {
                                    return (<div key={scriber.email} className="row-clinic">
                                        <span className="name">{scriber.name}</span>
                                        <Checkbox checked={formData.scribers.includes(scriber.email) ? true : false}
                                            icon={<img alt='Check' src={BlueHomeDotDisable} />}
                                            checkedIcon={<img alt='' src={BlueHomeDot} />}
                                            value="cryon" style={{ color: Colors.white_10 }}
                                            onChange={(data: any) => {
                                                if (data.target.checked) {
                                                    let newFormData = formData;
                                                    newFormData.scribers.push(scriber.email)
                                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                                }
                                                else {
                                                    let newFormData = formData;
                                                    newFormData.scribers = newFormData.scribers.filter((item: any) => {
                                                        return item !== scriber.email
                                                    });
                                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                                }
                                            }} />
                                    </div>);
                                })}
                            </div>)
                            : (<Fragment />)
                        }
                    </div>
                </div>

                <div className="footer">
                    <span onClick={() => { handleClose(false); }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={() => { update(); }} text={"Update"} />
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default EditDoctor