import { Fragment } from 'react'
import { IFile } from '../../helpers/Interfaces';
import { convertDuration } from '../../helpers/HelperFunctions';
import { downloadMedia } from '../../helpers/FirebaseFunctions';
import Download from "../../assets/images/Download.svg";
import File from "../../assets/images/File.svg";

type UploadedFileViewProps = {
    files: IFile[]
}

function UploadedFileView(props: UploadedFileViewProps) {

    async function downloadDocumentMedia(fileName: string, fileId: string) {
        await downloadMedia(fileName, fileId);
    }

    return (<div className="uploaded-file-view">
        <div className="uploaded-files-area">
            <div className="file-doc">
                {props?.files?.map((file: IFile, index: number) => {
                    return file?.fileExtension.includes("image")
                        ? (<></>)
                        : (<div key={index} className="file-content">
                            <div className="file-data">
                                <img src={File} alt="File" />
                                <label className="file-name">{file.fileName}</label>
                            </div>
                            <div className="file-properties">
                                {file.duration != 0
                                    ? <span className="duration">{convertDuration(file.duration)}</span>
                                    : <Fragment />
                                }
                                <span className="size">{file.fileSize + " MB"}</span>
                                <div onClick={() => { downloadDocumentMedia(file.fileName, file.fileId) }}>
                                    <img src={Download} alt="Download" />
                                </div>
                            </div>
                        </div>)
                })}
            </div>
            <div className="file-img">
                {props?.files?.map((file: IFile, index: number) => {
                    return file.fileExtension.includes("image")
                        ? (<div key={index} className="img-uploaded">
                            <img src={file.fileUrl} alt="File" />
                            <span className="extra-tag download-content">
                                <div onClick={() => { downloadDocumentMedia(file.fileName, file.fileId) }}>
                                    <img src={Download} alt="Download" />
                                </div>
                            </span>
                        </div>)
                        : (<></>)
                })}
            </div>
        </div>
    </div>);
}

export default UploadedFileView