import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { IAlert, IClinic, IDoctor, IEditReportType, IReportType, ITextInput } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import InputDropDown from '../InputDropDown/InputDropDown';
import Modal from '../Modal/Modal';

import "./EditReportType.scss"

type EditReportTypeProps = {
    open: boolean;
    handleModals: (open: boolean, modal: "add" | "edit" | "delete", shouldUpdate: boolean) => void;
    showAlert: Dispatch<SetStateAction<IAlert>>;
    selectedReportType: IReportType;
}

function EditReportType(props: EditReportTypeProps) {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ reportName: "", doctorId: "", clinicId: "" });
    const [formDataErrors, setFormDataErrors] = useState({
        reportNameError: { showError: false, message: "" }, doctorIdError: { showError: false, message: "" },
        clinicIdError: { showError: false, message: "" }
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);

    useEffect(() => {
        getDoctors();
    }, [])

    useEffect(() => {
        if (props.selectedReportType.id.trim() !== "") {
            getReportTypeById();
        }
    }, [props.selectedReportType])

    useEffect(() => {
        if (formData.doctorId.trim() !== "") {
            getClinics();
        }
    }, [formData.doctorId])

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.handleModals(false, "edit", shouldUpdate);
    }

    function getReportTypeById() {

        setLoading(true);

        let data = {
            reportTypeId: props.selectedReportType.id
        }

        apiService.getReportTypeById(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            loadData(data?.reportType);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function loadData(reportType: IEditReportType) {
        let newFormData = formData;
        newFormData.reportName = reportType.reportName;
        newFormData.doctorId = reportType.doctorId;
        newFormData.clinicId = reportType.clinicId;
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));
    }

    function clearData() {
        let newFormData = formData;
        newFormData.reportName = "";
        newFormData.doctorId = "";
        newFormData.clinicId = "";
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.reportNameError.showError = false;
        newFormDataErrors.doctorIdError.showError = false;
        newFormDataErrors.clinicIdError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getDoctors() {

        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getDoctorTextInput(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinicTextInput(clinics: IClinic[]) {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function getDoctorTextInput(doctors: IDoctor[]) {

        setLoading(true);

        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

        setLoading(false);
    }

    function update() {

        setLoading(true);

        if (formData.reportName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportNameError.showError = true;
            newFormDataErrors.reportNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.doctorId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = true;
            newFormDataErrors.doctorIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.clinicId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicIdError.showError = true;
            newFormDataErrors.clinicIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        let data = {
            id: props.selectedReportType.id,
            reportName: formData.reportName,
            doctorId: formData.doctorId,
            clinicId: formData.clinicId
        }

        apiService.updateReportType(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.reportName + " Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-add-report-type-card"} >
            <div className="edit-report-type-container">
                <div className='title'>Edit Report Type</div>
                <div className="input-row">
                    <Input label='REPORT NAME*' value={formData.reportName} type='text'
                        showError={formDataErrors.reportNameError.showError}
                        onChange={(value: any) => {
                            let newFormData = formData;
                            newFormData.reportName = value;
                            setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                        }} />
                </div>
                <div className="input-row">
                    <InputDropDown title='DOCTOR*' values={doctors} type='search' defaultValue={formData.doctorId}
                        showError={formDataErrors.doctorIdError.showError}
                        onSelect={(value: any) => {
                            if (value?.length > 0) {
                                let newFormData = formData;
                                newFormData.doctorId = value[0].value;;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }
                        }} />
                </div>
                <div className="input-row">
                    <InputDropDown title='CLINIC*' values={clinics} type='search' defaultValue={formData.clinicId}
                        showError={formDataErrors.clinicIdError.showError}
                        onSelect={(value: any) => {
                            if (value?.length > 0) {
                                let newFormData = formData;
                                newFormData.clinicId = value[0].value;;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }
                        }} />
                </div>

                <div className="footer">
                    <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={update} text={"Update"} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditReportType