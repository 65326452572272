import { useState, useEffect, Fragment, Dispatch, SetStateAction } from 'react'
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import BlueHomeDot from "../../assets/images/BlueHomeDot.svg";
import BlueHomeDotDisable from "../../assets/images/BlueHomeDotDisable.svg";
import { Colors } from '../../helpers/Colors';
import { Checkbox } from '@mui/material';
import { IAlert, IAssignee, IClinic, ITextInput } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import { isStringEmptyOrNull } from '../../helpers/HelperFunctions';
import { IStringArrayFieldWithError, IStringField, IStringFieldWithError, IStringArrayField } from '../../helpers/InputInterfaces';

import "./AddDoctor.scss";

type AddDoctorProps = {
    open: boolean;
    close: CallableFunction;
    clinics: IClinic[];
    showAlert: Dispatch<SetStateAction<IAlert>>;
}

type RequiredFormDataType = "firstName" | "lastName" | "email" | "clinics";
type OptionalFormDataType = "address" | "phoneNumber" | "abn" | "scribers";

interface IFormData {
    firstName: IStringFieldWithError, lastName: IStringFieldWithError, email: IStringFieldWithError, address: IStringField, phoneNumber: IStringField, abn: IStringField,
    clinics: IStringArrayFieldWithError, scribers: IStringArrayField
}

function AddDoctor(props: AddDoctorProps) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState<IFormData>({
        firstName: { value: "", showError: false }, lastName: { value: "", showError: false }, email: { value: "", showError: false }, address: { value: "" },
        phoneNumber: { value: "" }, abn: { value: "" }, clinics: { value: [], showError: false }, scribers: { value: [] }
    });
    const [showAlert, setShowAlert] = useState<IAlert>({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([] as ITextInput[]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [scribers, setScribers] = useState([] as IAssignee[]);

    useEffect(() => {
        if (props.clinics.length > 0) {
            getClinicTextInput();
        }
    }, [props.clinics])

    useEffect(() => {
        getScribers();
    }, [])

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Doctor", "add", shouldUpdate);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getClinicTextInput() {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        props.clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function getScribers() {

        setLoading(true);

        apiService.getAssignees().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setScribers(data?.users.filter((user: any) => user.role === "Scribe"));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function clearData() {
        let newFormData = formData;
        newFormData.firstName.value = "";
        newFormData.lastName.value = "";
        newFormData.email.value = "";
        newFormData.address.value = "";
        newFormData.phoneNumber.value = "";
        newFormData.abn.value = "";
        newFormData.clinics.value = [];
        newFormData.scribers.value = [];

        newFormData.firstName.showError = false;
        newFormData.lastName.showError = false;
        newFormData.email.showError = false;
        newFormData.clinics.showError = false;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));

        closeAlert();
        setLoading(false);
    }

    function validForm() {

        let isValidForm = true;

        if (isStringEmptyOrNull(formData.firstName.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("firstName");
        }

        if (isStringEmptyOrNull(formData.lastName.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("lastName");
        }

        if (isStringEmptyOrNull(formData.email.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("email");
        }

        if (formData.clinics.value.length === 0) {
            isValidForm = false;

            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "SELECT CLINIC/S";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }

        return isValidForm;
    }

    function handleRequiredFormDataErrors(field: RequiredFormDataType) {
        let newFormData = formData;
        newFormData[field].showError = true;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function handleRequiredFormData(field: RequiredFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;

        if (isStringEmptyOrNull(value)) {
            newFormData[field].showError = true;
        } else {
            newFormData[field].showError = false;
        }

        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function handleOptionalFormData(field: OptionalFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function handleRequiredFormArrayData(field: RequiredFormDataType, value: string[]) {
        let newFormData = formData;
        newFormData[field].value = value;

        if (value.length === 0) {
            newFormData[field].showError = true;
        } else {
            newFormData[field].showError = false;
        }

        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function handleOptionalFormArrayData(field: OptionalFormDataType, value: string[]) {
        let newFormData = formData;
        newFormData[field].value = value;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function saveDoctor() {

        setLoading(true);

        if (!validForm()) {
            setLoading(false);
            return;
        }

        let data = {
            firstName: formData.firstName.value,
            lastName: formData.lastName.value,
            email: formData.email.value,
            address: formData.address.value,
            phoneNumber: formData.phoneNumber.value,
            abn: formData.abn.value,
            clinics: formData.clinics.value,
            scribers: formData.scribers.value
        }

        apiService.addDoctor(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.firstName.value + " " + formData.lastName.value + " Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-add-doctor-card"} >
            <div className="add-doctor-container">
                <div className='title'>Add Doctor</div>
                <div className="content">

                    <div className="row">
                        <div className="input-field">
                            <Input label='FIRST NAME*' value={formData.firstName.value} type='text' showError={formData.firstName.showError}
                                onChange={(value: string) => { handleRequiredFormData("firstName", value) }} />
                        </div>
                        <div className="input-field">
                            <Input label='LAST NAME*' value={formData.lastName.value} type='text' showError={formData.lastName.showError}
                                onChange={(value: string) => { handleRequiredFormData("lastName", value) }} />
                        </div>
                        <div className="input-field">
                            <Input label='EMAIL ADDRESS*' value={formData.email.value} type='text' showError={formData.email.showError}
                                onChange={(value: string) => { handleRequiredFormData("email", value) }} />
                        </div>
                        <div className="input-field">
                            <Input label='ADDRESS' value={formData.address.value} type='text'
                                onChange={(value: string) => { handleOptionalFormData("address", value) }} />
                        </div>
                        <div className="input-field">
                            <Input label='TELEPHONE NUMBER' value={formData.phoneNumber.value} type='text'
                                onChange={(value: string) => { handleOptionalFormData("phoneNumber", value) }} />
                        </div>
                        <div className="input-field">
                            <Input label='ABN' value={formData.abn.value} type='text'
                                onChange={(value: string) => { handleOptionalFormData("abn", value) }} />
                        </div>
                    </div>

                    <div className="clinic-name">
                        <div className="row-view">
                            <span onClick={() => setSelectedTab(0)} className={`header ${selectedTab === 0 ? "selected-header" : ""}`}>
                                Clinics
                            </span>
                            <span onClick={() => setSelectedTab(1)} className={`header ${selectedTab === 1 ? "selected-header" : ""}`}>
                                Scribers
                            </span>
                        </div>
                        {selectedTab === 0
                            ? (<div className="row-data">
                                {clinics.map((clinic) => {
                                    return (<div key={clinic.value} className="row-clinic">
                                        <span className="name">{clinic.label}</span>
                                        <Checkbox icon={<img alt='check box' src={BlueHomeDotDisable} />}
                                            checkedIcon={<img alt='check' src={BlueHomeDot} />}
                                            value="cryon" style={{ color: Colors.white_10 }}
                                            checked={formData.clinics.value.includes(clinic.value)}
                                            onChange={(data) => {
                                                if (data.target.checked) {
                                                    let newFormData = formData;
                                                    newFormData.clinics.value.push(clinic.value)
                                                    handleRequiredFormArrayData("clinics", newFormData.clinics.value)
                                                }
                                                else {
                                                    let newFormData = formData;

                                                    newFormData.clinics.value = newFormData.clinics.value.filter((item) => {
                                                        return item !== clinic.value
                                                    });

                                                    handleRequiredFormArrayData("clinics", newFormData.clinics.value)
                                                }
                                            }} />
                                    </div>);
                                })}
                            </div>)
                            : (<Fragment />)
                        }
                        {selectedTab === 1
                            ? (<div className="row-data">
                                {scribers.map((user) => {
                                    return (<div key={user.name} className="row-clinic">
                                        <span className="name">{user.name}</span>
                                        <Checkbox icon={<img alt='check box' src={BlueHomeDotDisable} />}
                                            checkedIcon={<img alt='check' src={BlueHomeDot} />}
                                            value="cryon" style={{ color: Colors.white_10 }}
                                            checked={formData.scribers.value.includes(user.email)}
                                            onChange={(data) => {
                                                if (data.target.checked) {
                                                    let newFormData = formData;
                                                    newFormData.scribers.value.push(user.email)
                                                    handleOptionalFormArrayData("scribers", newFormData.scribers.value)
                                                }
                                                else {
                                                    let newFormData = formData;

                                                    newFormData.scribers.value = newFormData.scribers.value.filter((item) => {
                                                        return item !== user.email
                                                    });

                                                    handleOptionalFormArrayData("scribers", newFormData.scribers.value)
                                                }
                                            }} />
                                    </div>);
                                })}
                            </div>)
                            : (<Fragment />)
                        }
                    </div>
                </div>
                <div className="footer">
                    <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={() => { saveDoctor(); }} text={"Add Doctor"} />
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default AddDoctor