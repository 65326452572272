import { Dispatch, SetStateAction, useState } from 'react'
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import DocumentButton from '../DocumentButton/DocumentButton';
import ApiService from '../../services/ApiService';
import { IStringFieldWithError } from '../../helpers/InputInterfaces';
import { IAlert } from '../../helpers/Interfaces';
import { isStringEmptyOrNull } from '../../helpers/HelperFunctions';

import "./AddReferrer.scss";

type AddReferrerProps = {
    open: boolean;
    close: CallableFunction;
    showAlert: Dispatch<SetStateAction<IAlert>>;
};

type RequiredFormDataType = "name";

interface IFormData { name: IStringFieldWithError; }

function AddReferrer(props: AddReferrerProps) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState<IFormData>({ name: { value: "", showError: false } });
    const [showAlert, setShowAlert] = useState<IAlert>({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Referrer", "add", shouldUpdate);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function validForm() {

        let isValidForm = true;

        if (isStringEmptyOrNull(formData.name.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("name");
        }

        return isValidForm;
    }

    function handleRequiredFormData(field: RequiredFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;

        if (isStringEmptyOrNull(value)) {
            newFormData[field].showError = true;
        } else {
            newFormData[field].showError = false;
        }

        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function handleRequiredFormDataErrors(field: RequiredFormDataType) {
        let newFormData = formData;
        newFormData[field].showError = true;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function AddReferrer() {

        setLoading(true);

        if (!validForm()) {
            setLoading(false);
            return;
        }

        let data = {
            name: formData.name.value
        }

        apiService.addReferrer(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.name.value + " Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function clearData() {
        let newFormData = formData;
        newFormData.name.value = "";
        newFormData.name.showError = false;
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        closeAlert();
        setLoading(false);
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"add-referrer-card"} >
            <div className="add-referee-container">
                <div className="header-section">
                    <span className="header">Add Referrer</span>
                </div>
                <div className="body">
                    <Input label='NAME*' value={formData.name.value} type='text'
                        showError={formData.name.showError}
                        onChange={(value: string) => { handleRequiredFormData("name", value) }} />
                    <div className="footer">
                        <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                        <div className="button">
                            <DocumentButton loading={loading} onClick={() => { AddReferrer() }} text={"Add Referrer"} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddReferrer