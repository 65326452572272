import BlueDot from "../assets/images/BlueDot.svg";
import RedDot from "../assets/images/RedDot.svg";
import GreenDot from "../assets/images/GreenDot.svg";
import OrangeDot from "../assets/images/OrangeDot.svg";
import GreyDot from "../assets/images/GreyDot.svg";
import ArrowUpRedIcon from "../assets/images/ArrowUpRed.svg";
import ArrowUpWhiteIcon from "../assets/images/ArrowUpWhite.svg";
import moment from "moment";
import { INotification } from "./Interfaces";
import { downloadDocument, getDocument } from "./FirebaseFunctions";

let CryptoJS = require("crypto-js");
let encryptKey = "laka@cant$drive!car3213";

export function getEnvironment() {
  return process.env.REACT_APP_ENV;
}

export function getBaseURL() {

  let env = getEnvironment();

  if (env === "development") {
    return "https://dev.admin.api.infragist.com";
  } else if (env === "staging") {
    return "https://scribe-it-uat-api.t1o6822kgg5sm.ap-southeast-2.cs.amazonlightsail.com";
  } else if (env === "production") {
    return "https://scribe-it-prod-api.t1o6822kgg5sm.ap-southeast-2.cs.amazonlightsail.com"
  } else {
    return "https://localhost:7282";
  }
}

export function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

export function validStringField(value: string) {
  if (value) {
    return value;
  } else {
    return " - "
  }
}

export function sortRequestedChanges(requestedChanges: any) {

  let dateSorted = requestedChanges?.sort(function (a: any, b: any) {
    return +new Date(b.createdDate) - +new Date(a.createdDate);
  });

  let completed = dateSorted?.filter((t: any) => t.status === "Completed");
  let pending = dateSorted?.filter((t: any) => t.status === "Pending");

  return pending?.concat(completed);
}

export function getTimeDifference(date: Date) {

  let duration = moment.duration(moment.utc().local().diff(date));
  let minutes = duration.asMinutes();
  let hours = duration.asHours();

  if (minutes < 3600) {
    return Math.round(minutes) + ' min ago'
  } else if (hours <= 24) {
    return Math.round(hours) === 1 ? Math.round(hours) + ' hr ago' : Math.round(hours) + ' hrs ago'
  } else if (hours > 24) {
    return validDateField(date);
  }
}

export function validDateField(value: Date | undefined) {
  if (value) {
    return moment.utc(value).local().format("DD-MM-YYYY");
  } else {
    return " - "
  }
}

export function validDateTimeField(value: Date) {
  if (value) {
    return moment.utc(value).local().format("DD-MM-YYYY hh:mm A");
  } else {
    return " - "
  }
}

export function convertTo2DecimalPlaces(floatNumber: number) {
  return Number(floatNumber.toString().match(/^\d+(?:\.\d{0,2})?/));
}

export function divideRoles(roles: string[]) {
  if (roles.length > 1) {
    return roles.map((e: any, i: number, arry: any) => {
      let return_ = arry.length - 1 === i ? "" : "/";
      return e.charAt(0) + return_;
    });
  } else {
    return roles[0];
  }
}

export function divideCompletedStages(stages: string[]) {
  if (stages.length > 1) {

    let text = ""

    for (let index = 0; index < stages.length; index++) {
      if (index + 1 === stages.length) {
        text = text + stages[index];
      } else {
        text = text + stages[index] + "/";
      }
    }

    return text;
  } else if (stages.length === 1) {
    return stages[0];
  } else {
    return "-";
  }
}

export function showEditDocument(documentStatus: string) {
  if (documentStatus !== "Submitted" && documentStatus !== "Completed") {
    return true;
  }
  return false;
}

export function userEncrypt(user: any) {
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), encryptKey).toString();
  sessionStorage.setItem("user", JSON.stringify(ciphertext))
}

export function userDecrypt() {
  let ciphertext = JSON.parse(sessionStorage.getItem("user") || "{}");
  let bytes = CryptoJS.AES.decrypt(ciphertext, encryptKey);
  let user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return (user)
}

export function divideToTime(duration: string) {
  let split = duration.split(":");

  if (split.length === 2) {
    if (parseInt(split[0]) === 0) {
      return duration + " secs";
    }
    return duration + " mins";
  }

  if (split.length === 3) {
    if (parseInt(split[0]) === 0) {
      return duration + " mins";
    }
    return duration + " hours";
  }

  return duration;
}

export function separateRoles(roles: string[]) {
  if (roles?.length > 1) {

    let text = "";

    for (let index = 0; index < roles.length; index++) {
      if (index + 1 === roles.length) {
        text = text + roles[index];
      } else {
        text = text + roles[index] + "/";
      }
    }

    return text;
  } else {
    return roles?.[0];
  }
}

export function getDot(status: string, requestedChanges: number) {
  if (requestedChanges == 0) {
    return <img className="dot" src={GreenDot} alt={"Dot"} />;
  }
  else {
    if (status === "Draft") {
      return <img className="dot" src={OrangeDot} alt={"Dot"} />;
    }

    if (status === "Pending") {
      return <img className="dot" src={BlueDot} alt={"Dot"} />;
    }

    if (status === "Assigned") {
      return <img className="dot" src={RedDot} alt={"Dot"} />;
    }

    if (status === "Submitted") {
      return <img className="dot" src={GreenDot} alt={"Dot"} />;
    }

    if (status === "Completed") {
      return <img className="dot" src={GreyDot} alt={"Dot"} />;
    }
  }
}

export function convertDuration(duration: number) {
  if (!isNaN(duration)) {
    return duration >= 3600
      ? new Date(duration * 1000).toISOString().substring(11, 19)
      : new Date(duration * 1000).toISOString().substring(14, 19);
  } else {
    return "00:00";
  }
}

export function progressStatus(status: number) {
  if (status == 0) {
    return <img className="dot" src={GreyDot} alt={"Dot"} />;
  }
  else if (status == 1)
    return <img className="dot" src={OrangeDot} alt={"Dot"} />;
  else if (status == 2)
    return <img className="dot" src={GreenDot} alt={"Dot"} />;
}

export function progress(status: number) {
  if (status == 0) {
    return "Not Started";
  }
  else if (status == 1)
    return "InProgress";
  else if (status == 2)
    return "Completed";
}

export function stageStatus(status: number) {
  if (status == 1) {
    return "Scribing"
  }
  else if (status == 2)
    return "Proofing"
  else if (status == 3)
    return "Reviewing"
}

export function convertToMinutes(duration: number) {

  if (isNaN(duration)) {
    return "0.0";
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;

  return minutes + "." + seconds.toString().charAt(0);
}

export function obscureEmail(email: string) {

  let obsure = "";

  if (email.trim() !== "") {
    const [name, domain] = email.split("@");
    obsure = `${name[0]}${name[1]}${name[2]}${new Array(name.length - 2).join("*")}@${domain}`;
  }

  return obsure;
};

export function isInRole(roles: string[], role: string) {
  if (roles?.length > 0) {
    return roles?.includes(role);
  }
  return false;
}

export function getPriority(priority: string) {
  if (priority === "S. Urgent") {
    return (<div className="urgency-box dark-red">
      <img className="urgency-icon margin-right" src={ArrowUpWhiteIcon} alt={"Up"} />
      {priority}
    </div>);
  }

  if (priority === "Urgent") {
    return (<div className="urgency-box red">
      <img className="urgency-icon margin-right" src={ArrowUpRedIcon} alt={"Up"} />
      {priority}
    </div>);
  }

  if (priority === "24 Hours") {
    return <div className="urgency-box yellow">{priority}</div>;
  }

  if (priority === "48 Hours") {
    return <div className="urgency-box light-blue">{priority}</div>;
  }

  if (priority === "72 Hours") {
    return <div className="urgency-box dark-blue">{priority}</div>;
  }
}

export function getFileSize(size: string) {
  return (parseFloat(size) / (1024 * 1024)).toFixed(2);
}

export function saveTokens(token: any, refresh: any) {
  sessionStorage.setItem("jwt", token);
  sessionStorage.setItem("refresh", refresh);
}

export function getTokens() {
  let jwt = sessionStorage.getItem("jwt");
  let refresh = sessionStorage.getItem("refresh");

  return { jwt: jwt, refresh: refresh };
}

export function removeTokens() {
  sessionStorage.removeItem("jwt");
  sessionStorage.removeItem("refresh");
  sessionStorage.removeItem("user");
  return true;
}

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export async function getDuration(url: any) {
  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    const source = document.createElement("source");
    source.src = url;
    audio.muted = true;
    audio.preload = "metadata";
    audio.appendChild(source);
    audio.onloadedmetadata = function () {
      resolve(audio.duration);
    };
  });
}

export function getUrlParams() {

  let urlString = window.location.href;

  let url = new URL(urlString);
  let args = new URLSearchParams(url.search);

  let token = args.get("token") as string;
  let fixedToken = token.replace(/\s/g, "+");

  return { token: fixedToken }
}

export function emailValidation(email: string) {

  let isValid = false;

  if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(`${email}`)) {
    isValid = true;
  }

  return isValid;
}

export function passwordValidation(password: string) {

  let isValid = false;

  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)) {
    isValid = true;
  }

  return isValid;
}

export function splitWord(word = "") {
  let wordArr = word.split(/(?=[A-Z])/);
  let spiltWord = "";

  for (let i = 0; i < wordArr.length; i++) {
    spiltWord += " " + wordArr[i]
  }

  return spiltWord.trim()
}

export function calculateDueDate(numberOfDaysAdded: number) {

  if (numberOfDaysAdded === 0) {
    return moment().utc().local();
  }

  return moment().utc().local().add(numberOfDaysAdded, "days");
}

export function sortNotificationsByDate(notifications: INotification[]) {
  if (notifications) {
    return notifications.sort((a, b) => { return b?.createdDate?.seconds - a?.createdDate?.seconds })
  }

  return [];
}

export function isStringEmptyOrNull(value: string | undefined | null) {
  if (!value) {
    return true;
  }

  return false;
}


export async function downloadWordDocument(props: { setLoading: CallableFunction, documentId: string, setReport: CallableFunction, reportName: string, report: any }) {
  props.setLoading(true);
  await getDocument(props.documentId, props.setReport);
  await downloadDocument({ fileName: props.report?.word?.fileName, downloadingName: `${props.reportName}.docx` });
  props.setLoading(false);
}