import { TextField, } from "@mui/material";
import ToolTip from "../ToolTip/ToolTip";
import ErrorShield from "../../assets/images/ErrorShield.svg";

import "./Input.scss";

type InputProps = {
  label: string,
  value: string,
  onChange: CallableFunction,
  type: "text" | "password" | "textarea" | "search",
  rows?: number,
  height?: number,
  showError?: boolean,
  disable?: boolean
}

export default function Input(props: InputProps) {
  return (
    <div className="input-container">
      <TextField
        autoComplete="off"
        disabled={props.disable}
        type={props.type}
        rows={props.rows}
        multiline={props.rows ? true : false}
        label={props.label}
        id="standard-size-normal"
        value={props.value}
        defaultValue={props.value}
        variant="standard"
        sx={{
          width: '100%',
          height: props.height,
        }}
        classes={{
          root: `text-field-base ${props.showError ? 'text-field-error' : 'am-text-field-base'}`
        }}
        onChange={(data) => props.onChange(data.target.value)}
      />
    </div>
  );
}
