import Input from '../../components/Input/Input';
import DocumentButton from '../../components/DocumentButton/DocumentButton';

type ForgetPasswordtProps = {
    readonly passwordResetFormErrors: {
        emailError: { showError: boolean, message: string },
    }
    readonly resetForm: { email: string },
    readonly setResetForm: CallableFunction,
    readonly loading: boolean,
    readonly reset: CallableFunction,
    readonly backToLogin: CallableFunction
}
function ForgetPasswordForm({ loading, resetForm, passwordResetFormErrors, setResetForm, reset, backToLogin }: ForgetPasswordtProps) {
    return (
        <>
            <div className="text-content">
                <span className='text-content-header'>Forget Your Password?</span>
                <span className='text-content-text'>No worries! Just enter your email address, and</span>
                <span className='text-content-text'>we'll help you reset your password.</span>
            </div>
            <div className="inputs">
                <Input label='EMAIL ADDRESS' value={resetForm.email} type='text'
                    showError={passwordResetFormErrors.emailError.showError}
                    onChange={(value: string) => {
                        let newResetForm = resetForm;
                        newResetForm.email = value;
                        setResetForm((prevDetails: any) => ({ ...prevDetails, ...newResetForm }));
                    }} />
            </div>
            {/* <div className="footer">
            </div> */}
            <div className="button-content">
                <DocumentButton text='Reset Password' loading={loading} onClick={reset} />
            </div>
            <p className='back-to-login-text'>Have your password after all? <span className="back-to-login" onClick={() => { backToLogin() }}>Back To Login</span></p >
            <div className='forget-password-footer'></div>

        </>
    );
}

export default ForgetPasswordForm