import { Fragment, useEffect, useRef, useState } from 'react'
import FileUpload from '../FileUpload/FileUpload';
import { IFile, ITemporaryFile } from '../../helpers/Interfaces';
import { convertDuration, convertTo2DecimalPlaces, getFileSize } from '../../helpers/HelperFunctions';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../services/FirebaseService';
import Trash from "../../assets/images/Trash-2.svg";
import File from "../../assets/images/File.svg";
import Close from "../../assets/images/Close.svg";
import Download from "../../assets/images/Download.svg";
import { downloadMedia } from '../../helpers/FirebaseFunctions';
import Show from '../Show/Show';

import "./FileUploadUI.scss"

type FileUploadUIProps = {
    setLoading: CallableFunction,
    loading?: boolean,
    setMedia: CallableFunction,
    media: IFile[],
    setShowAlert: CallableFunction,
    removeFile: CallableFunction,
    setAddedFiles: CallableFunction,
    addedFiles: IFile[],
    storageBucket?: string,
    deleteAccess?: boolean,
    fileUploadAccess?: boolean
}

function FileUploadUI(props: FileUploadUIProps) {

    const [temporaryFiles, setTemporaryFiles] = useState([] as ITemporaryFile[]);
    const [progress, setProgress] = useState(0);

    const uploadRef = useRef({} as any);

    useEffect(() => {
        if (temporaryFiles.length > 0) {
            uploadTemporaryFile(0)
        }
    }, [temporaryFiles, temporaryFiles.length]);

    function uploadTemporaryFile(index: number) {

        let bucket = props.storageBucket ?? 'media';

        const storageRef = ref(storage, `/${bucket}/${temporaryFiles[index].fileId}`);

        let _temporaryFiles = temporaryFiles;
        _temporaryFiles[index].state = "Uploading";
        setTemporaryFiles(_temporaryFiles);

        uploadRef.current = uploadBytesResumable(storageRef, temporaryFiles[index]?.file);

        uploadRef.current.on('state_changed',
            (snapshot: any) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress)
            },
            (error: any) => {
                switch (error.code) {
                    case 'storage/canceled':
                        let _temporaryFiles = temporaryFiles;
                        _temporaryFiles[index].state = "Cancelled";
                        setTemporaryFiles(_temporaryFiles);
                        if ((index + 1) < temporaryFiles.length) {
                            uploadTemporaryFile(index + 1)
                        } else {
                            setTemporaryFiles([] as ITemporaryFile[]);
                        }
                        props.setLoading(false);
                }
            },
            () => {
                getDownloadURL(uploadRef?.current?.snapshot.ref).then((downloadURL) => {

                    let _temporaryFiles = temporaryFiles;

                    _temporaryFiles[index].link = downloadURL;
                    _temporaryFiles[index].state = "Uploaded";

                    setTemporaryFiles(_temporaryFiles);

                    let mediaFile = {
                        fileUrl: downloadURL,
                        fileId: _temporaryFiles[index].fileId,
                        fileName: _temporaryFiles[index].fileName,
                        fileExtension: _temporaryFiles[index]?.file?.type,
                        fileSize: getFileSize(_temporaryFiles[index]?.file.size.toString()),
                        duration: convertTo2DecimalPlaces(_temporaryFiles[index]?.duration)
                    };

                    let _addedFiles = props.addedFiles;
                    _addedFiles.push(mediaFile);
                    props.setAddedFiles(_addedFiles);

                    let newMedia = props.media ?? [];
                    newMedia.push(mediaFile);
                    props.setMedia(newMedia);

                    if ((index + 1) < temporaryFiles.length) {
                        uploadTemporaryFile(index + 1)
                    } else {
                        setTemporaryFiles([] as ITemporaryFile[]);
                    }

                    props.setLoading(false);
                });
            }
        );
    }

    function isFileExist(value: { url: string; base64Url: any; file: any; duration: number; }) {
        return props.media?.some((file: any) => {
            return file?.fileName === (value?.file?.name);
        });
    }

    function getHeader() {
        let temporaryPendingFiles = temporaryFiles.filter((file) => file.state === 'Pending').length;
        let uploadedFiles = props.media?.length;
        let uploadingFiles = temporaryFiles.filter((file) => file.state === 'Uploading').length;

        let message = 'Please upload your files here..';

        if (uploadedFiles > 0) {
            message = uploadedFiles === 1 ? '1 item uploaded' : uploadedFiles + ' items uploaded';
        }

        if (uploadedFiles > 0 && uploadingFiles > 0) {
            message = message + ', ' + (uploadingFiles === 1 ? '1 item uploading' : uploadingFiles + ' items uploading')
        } else if (uploadingFiles > 0) {
            message = uploadingFiles === 1 ? '1 item uploading' : uploadingFiles + ' items uploading'
        }

        if (uploadedFiles > 0 && uploadingFiles > 0 && temporaryPendingFiles > 0) {
            message = message + ', ' + (temporaryPendingFiles === 1 ? '1 item pending' : temporaryPendingFiles + ' items pending')
        } else if (temporaryPendingFiles > 0) {
            message = temporaryPendingFiles === 1 ? '1 item pending' : temporaryPendingFiles + ' items pending'
        }

        return message;
    }

    return (
        <div className='file-upload-ui'>
            <div className="file-uploader">
                <Show hasAccess={props.fileUploadAccess ?? true}>
                    <FileUpload heading={getHeader()} label={"Import File"} showUploadedImage={false} multipleFiles
                        subHeading={"You can “drag & drop files” here or click to below link"}
                        acceptedFileTypes={[".mp3", ".m4a", ".wav", ".ds2", ".amr", ".doc", ".docx", ".pdf", ".png", ".jpeg", ".jpg", ".txt"]}
                        onChange={(values: { url: string; base64Url: any; file: any; duration: number; }[]) => {
                            if (temporaryFiles.length > 0) {
                                let newShowAlert = { isError: true, message: 'Please wait. Upload is in progress', open: true };
                                props.setShowAlert(newShowAlert);
                                return;
                            }
                            props.setLoading(true);

                            values?.forEach((value) => {
                                (async () => {
                                    let fileAlreadyExists = isFileExist(value)

                                    let dateTime = new Date().getTime().toString();
                                    let fileName = dateTime + "_" + value.file?.name

                                    if (fileAlreadyExists) {
                                        let newShowAlert = { isError: true, message: "File already exists", open: true };
                                        props.setShowAlert(newShowAlert);
                                        return props.setLoading(false);
                                    } else {
                                        let file: ITemporaryFile = {
                                            file: value?.file,
                                            duration: value.duration,
                                            link: "",
                                            fileName: value.file?.name,
                                            fileId: fileName,
                                            state: "Pending"
                                        };

                                        let temFile = temporaryFiles;
                                        temFile.push(file);
                                        setTemporaryFiles(temFile);
                                    }
                                })()
                            });
                        }}
                    />
                </Show>
            </div>
            <div className="uploaded-files-area">
                <div className="file-doc">
                    {temporaryFiles?.map((_file: ITemporaryFile) => {
                        if (_file.state === "Pending") {
                            return (
                                <div key={_file.fileId} className="file-content-view ">
                                    <div className="file-data">
                                        <img src={File} alt="" />
                                        <label className="file-name">{_file.fileName}</label>
                                    </div>
                                    <div className="file-properties">
                                        <span className="size pending">Pending</span>
                                        <span className="size">{getFileSize(_file.file?.size.toString()) + " MB"}</span>
                                        <div className='img-content'>
                                        </div>
                                        <div className='img-content'>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    {temporaryFiles?.map((_file: ITemporaryFile) => {
                        if (_file.state === "Uploading") {
                            return (
                                <div key={_file.fileId} className="file-content-view ">
                                    <div className="file-data">
                                        <img src={File} alt="" />
                                        <label className="file-name">{_file.fileName}</label>
                                    </div>
                                    <div className="file-properties">
                                        <span className="size uploading">{progress?.toFixed(0)} %</span>
                                        <span className="size">{getFileSize(_file.file?.size.toString()) + " MB"}</span>
                                        <div className='img-content'>
                                        </div>
                                        <div className='img-content'>
                                            {progress < 100
                                                ? (<img onClick={() => { uploadRef.current.cancel(); props.setLoading(false); }}
                                                    src={Close} alt="Trash" />)
                                                : (<Fragment />)
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}

                    {props.media?.map((file: IFile) => {
                        return file.fileExtension.includes("image")
                            ? (<></>)
                            : (<div key={file.fileId} className="file-content-view">
                                <div className="file-data">
                                    <img src={File} alt="File" />
                                    <label className="file-name">{file?.fileName}</label>
                                </div>
                                <div className="file-properties">
                                    {file?.duration !== 0
                                        ? <span className="duration">{convertDuration(file?.duration)}</span>
                                        : <Fragment />
                                    }
                                    <span className="size">{file?.fileSize + " MB"}</span>
                                    <div className='img-content'>
                                        {!props.addedFiles.includes(file)
                                            ? (<img onClick={() => downloadMedia(file.fileName, file.fileId, props.storageBucket)} src={Download} alt="Download" />)
                                            : (<Fragment />)}
                                    </div>
                                    <Show hasAccess={props.deleteAccess ?? true}>
                                        <div className='img-content'>
                                            <img onClick={
                                                () => {
                                                    if (temporaryFiles.length > 0) {
                                                        let newShowAlert = { isError: true, message: 'Please wait. Upload is in progress', open: true };
                                                        props.setShowAlert(newShowAlert);
                                                        return;
                                                    }
                                                    props.removeFile(file)
                                                }} src={Trash} alt="Trash" />
                                        </div>
                                    </Show>
                                </div>
                            </div>)
                    })}
                </div>
                <div className="file-img">
                    {props.media?.map((file: IFile) => {
                        return file.fileExtension.includes("image")
                            ? (<div key={file.fileId} className="img-uploaded">
                                <img className='image-source' src={file?.fileUrl} alt="File" />
                                <Show hasAccess={props.deleteAccess ?? true}>
                                    <span className="delete-content">
                                        <img onClick={() => {
                                            if (temporaryFiles.length > 0) {
                                                let newShowAlert = { isError: true, message: 'Please wait. Upload is in progress', open: true };
                                                props.setShowAlert(newShowAlert);
                                                return;
                                            }
                                            props.removeFile(file)
                                        }} src={Close} alt="Close" />
                                    </span>
                                </Show>
                                {!props.addedFiles.includes(file)
                                    ? (<span className="delete-content">
                                        <img onClick={() => downloadMedia(file.fileName, file.fileId, props.storageBucket)} src={Download} alt="Close" />
                                    </span>)
                                    : (<Fragment />)}
                            </div>)
                            : (<></>)
                    })}
                </div>
            </div>
        </div>
    )


}

export default FileUploadUI