import "./ProfileImageLoader.scss";

export default function ProfileImageLoader(props: { name: string, imageUrl: string, style: string }) {

    function getInitials(name: string) {
        let names = name.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }

        return initials;
    }

    return (<>
        {props.imageUrl
            ? (<img className={`user-img ${props.style}`} src={props.imageUrl} alt="profile image" />)
            : (<div className={`file-progress-container ${props.style}`}>{getInitials(props.name)}</div>)
        }
    </>)
}