import { IAudit } from '../../helpers/Interfaces';
import moment from 'moment';
import { validDateTimeField, validStringField } from '../../helpers/HelperFunctions';

type AuditViewProps = {
    audits: IAudit[]
}

function AuditView(props: AuditViewProps) {

    function sortAudit() {
        return props.audits.sort((a, b) => moment.utc(a.createdDate).diff(moment.utc(b.createdDate)));
    }

    return (<div className='audit-container'>
        {sortAudit().map((report, key: number) => {
            return (<div key={key} className='audit-row'>
                <label className='audit-data'>{validStringField(report.audit)}</label>
                <label>{validDateTimeField(report.createdDate)}</label>
            </div>)
        })}
    </div>);
}

export default AuditView