import ReportBlue from "../../assets/images/ReportBlue.svg";
import DownloadIcon from "../../assets/images/Download.svg";
import { IReportData, ITaggedReport } from "../../helpers/Interfaces";
import { downloadWordDocument } from "../../helpers/HelperFunctions";
import { useState } from "react";

type TagReportProps = {
    reports: ITaggedReport[];
    setLoading: CallableFunction
}

function TagReports(props: TagReportProps) {
    const [report, setReport] = useState({} as IReportData);
    return (
        <div className="tag-report-view">
            {props.reports.map((report) => {
                return (
                    <div className="report-data-content">
                        <div className="report-name">
                            <img src={ReportBlue} alt="ReportBlue" />
                            <span>{report.reportName}</span>
                        </div>
                        <img onClick={() => downloadWordDocument({ documentId: report.documentId, reportName: report.reportName, setLoading: props.setLoading, setReport: setReport, report: report })} className='download-icon' src={DownloadIcon} alt="ReportBlue" />
                    </div>
                )
            })}
        </div>
    )
}

export default TagReports