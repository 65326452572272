import Tooltip from '@mui/material/Tooltip';
import { ReactElement } from 'react';

import "./ToolTip.scss";

export default function ToolTip(props: {
    title: string | ReactElement, showArrow: boolean, children: ReactElement, showAlways?: boolean,
    toolTipRootCSS: "tool-tip-one" | "tool-tip-two" | "tool-tip-three",
    position: "top-start" | "top" | "top-end" | "left-start" | "left" | "left-end" |
    "right-start" | "right" | "right-end" | "bottom-start" | "bottom" | "bottom-end"
}) {
    return (props.showAlways
        ? (<Tooltip title={props.title} arrow={props.showArrow} placement={props.position} open={props.showAlways}
            classes={{ popper: props.toolTipRootCSS, tooltip: "label", arrow: "arrow" }}>
            {props.children}
        </Tooltip>)
        : props.children
    )
}