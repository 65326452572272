import Camera from "../../assets/images/Camera.svg";
import DocumentButton from '../../components/DocumentButton/DocumentButton'
import Input from '../../components/Input/Input'
import { useContext, useEffect, useState } from "react";
import { removeTokens, separateRoles, toBase64 } from '../../helpers/HelperFunctions';
import moment from "moment";
import MuiAlert from "../../components/Alert/Alert";
import ApiService from "../../services/ApiService";
import { User } from "../../store/Store";
import ProfileImageLoader from "../../components/ProfileImageLoader/ProfileImageLoader";
import { firebaseSignOut } from "../../helpers/FirebaseFunctions";
import { useNavigate } from "react-router-dom";

function AccountSettings() {

    const apiService = new ApiService();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        profileImageUrl: "", firstName: "", lastName: "", email: "",
        currentPassword: "", newPassword: "", confirmPassword: ""
    });
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    const [user] = useContext(User);

    useEffect(() => {
        if (user) {
            loadData();
        }
    }, [user])

    function loadData() {
        let newFormData = formData;
        newFormData.profileImageUrl = user?.profileImageUrl;
        newFormData.firstName = user?.firstName;
        newFormData.lastName = user?.lastName;
        newFormData.email = user?.email;
        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
    }

    function handleLogout() {
        apiService.logout().finally(async () => {
            removeTokens();
            await firebaseSignOut();
            navigate("/login");
            setLoading(false);
        });
    }

    function updateDetails() {

        setLoading(true);

        let data = {
            profileImageUrl: formData.profileImageUrl,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: user?.email,
            newEmail: formData.email,
            roles: user?.roles,
            status: true,
        }

        apiService.updateUserDetails(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Updated";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            handleLogout();
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='account-container'>
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert, }));
                }} />
            <div className="header">
                <span className="title">Account Settings</span>
                <span className="header-text">Update your account settings</span>
            </div>
            <div className="body-content">
                <div className="image-content">
                    <ProfileImageLoader name={user?.firstName + " " + user?.lastName} imageUrl={user?.profileImageUrl}
                        style={"user-img"} />
                    <div className="camera-background">
                        <input id="file" type="file" hidden name="fileUpload" accept={"images/*"}
                            onChange={async (e: any) => {
                                await toBase64(e.target.files[0]).then((base64Url: any) => {
                                    // let url = URL.createObjectURL(e.target.files[0]);
                                    // let newFormData = formData;
                                    // newFormData.profileImageUrl = url;
                                    // setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                });
                            }} />
                        <label htmlFor="file">
                            <img className="camera-img" src={Camera} alt="Camera" />
                        </label>
                    </div>

                    <div className="user-data">
                        <label className="header">{separateRoles(user?.roles)}</label>
                        <div className="detail">Member since -  {moment.utc(user?.joinedDate).local().format("DD-MM-YYYY")}</div>
                    </div>
                </div>
                <div className="personal-data">
                    <div className="forms">
                        <span className="header">Personal Details</span>
                        <div className="row">
                            <div className="input-field">
                                <Input label='FIRST NAME*' type='text' value={formData.firstName}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.firstName = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label='LAST NAME*' type='text' value={formData.lastName}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.lastName = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label='EMAIL ADDRESS*' type='text' value={formData.email}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.email = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="forms">
                        <span className="header">Password</span>
                        <div className="row">
                            <div className="input-field">
                                <Input label='CURRENT PASSWORD*' type='text' value={formData.currentPassword}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.currentPassword = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label='NEW PASSWORD*' type='password' value={formData.newPassword}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.newPassword = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label='CONFIRM PASSWORD*' type='password' value={formData.confirmPassword}
                                    onChange={(value: any) => {
                                        let newFormData = formData;
                                        newFormData.confirmPassword = value;
                                        setFormData((preDetails) => ({ ...preDetails, ...newFormData }));
                                    }} />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="footer">
                    <div className="button">
                        <DocumentButton loading={loading} text='Update' onClick={() => { updateDetails(); }} />
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AccountSettings