import { useState, Fragment, useEffect } from 'react'
import DateInput from '../DateInput/DateInput';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import InputDropDown from '../InputDropDown/InputDropDown';
import Modal from '../Modal/Modal';
import Stepper from '../Stepper/Stepper';
import moment from 'moment';
import BlueHomeDot from "../../assets/images/BlueHomeDot.svg";
import BlueHomeDotDisable from "../../assets/images/BlueHomeDotDisable.svg";
import ReportBlue from "../../assets/images/ReportBlue.svg";
import DownloadIcon from "../../assets/images/Download.svg";
import ApiService from '../../services/ApiService';
import { IAssignee, IClinic, IDoctor, IEditReport, IFile, IPatient, IPriority, IReferrer, IReportData, IReportType, ITaggedReport, ITextInput } from '../../helpers/Interfaces';
import { createMediaDocument, getMediaDocument, removeMedia } from '../../helpers/FirebaseFunctions';
import CheckBox from '../CheckBox/CheckBox';
import FileUploadUI from '../FileUploadUI/FileUploadUI';

import "./EditReport.scss"
import { downloadWordDocument } from '../../helpers/HelperFunctions';

export default function EditReport(props: {
    open: boolean, close: CallableFunction; selectedReportId: string,
    showReport: CallableFunction
}) {

    const apiService = new ApiService();

    const [view, setView] = useState(0);
    const [formData, setFormData] = useState({
        patientName: "", patientNameId: "", dueDate: moment().utc().local(), priorityId: null, doctorId: "", clinicId: null, reportTypeId: "",
        providerNumber: "", placeOfAssessment: "", dateOfAssessment: moment().utc().local(), referrerId: null, reportName: "",
        notes: "", mediaId: "", totalDuration: 0, scribeAssignedTo: null, proofAssignedTo: null, reviewAssignedTo: null, adHoc: false
    });
    const [media, setMedia] = useState([] as IFile[])
    const [addedFiles, setAddedFiles] = useState([] as IFile[]);
    const [removedMedia, setRemovedMedia] = useState([] as IFile[]);
    const [stageStatus, setStageStatus] = useState({ scribe: "NotStarted", proof: "NotStarted", review: "NotStarted" });
    const [formDataErrors, setFormDataErrors] = useState({
        patientNameError: { showError: false, message: "" }, dueDateError: { showError: false, message: "" },
        priorityIdError: { showError: false, message: "" }, doctorIdError: { showError: false, message: "" },
        reportTypeIdError: { showError: false, message: "" }, dateOfAssessmentError: { showError: false, message: "" }
    });
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);
    const [reportTypes, setReportTypes] = useState([] as ITextInput[]);
    const [referrers, setReferrers] = useState([] as ITextInput[]);
    const [priorities, setPriorities] = useState([] as ITextInput[]);
    const [patients, setPatients] = useState([] as IPatient[]);
    const [filteredPatients, setFilteredPatients] = useState([] as ITextInput[]);
    const [assignees, setAssignees] = useState({
        scribers: [] as ITextInput[], proofers: [] as ITextInput[], reviewers: [] as ITextInput[]
    });
    const [taggedReports, setTaggedReports] = useState([] as ITaggedReport[]);
    const [selectedTaggedReports, setSelectedTaggedReports] = useState([] as ITaggedReport[]);
    const [selectedDownloadReport, setSelectedDownloadReport] = useState({} as IReportData);


    useEffect(() => {
        getDoctors();
        getAssignees();
        getReferrers();
        getPriorities();
        getPatient();
    }, [])

    useEffect(() => {
        if (formData.patientName !== "") {
            getTaggedReports();
        }
        setSelectedTaggedReports([]);

    }, [formData.patientNameId])

    useEffect(() => {
        if (props.selectedReportId) {
            getReportDataById();
        }


    }, [props.selectedReportId])

    useEffect(() => {
        if (formData.doctorId.trim() !== "") {
            getClinics();
        }

        if (formData.doctorId.trim() !== "" && formData.clinicId !== "") {
            getReportTypes();
        }

        let _patients: IPatient[] = patients.filter((patient) => patient.doctor.id === formData.doctorId);
        getPatientTextInput(_patients);

    }, [formData.doctorId, formData.clinicId])

    useEffect(() => {

        let duration = 0;

        media?.forEach((file) => {
            duration = duration + file.duration
        });

        let form = formData;
        form.totalDuration = duration;
        if (duration > 0) {
            form.adHoc = false;
        }
        setFormData((previousData) => ({ ...previousData, ...form }));
    }, [media, media?.length]);

    useEffect(() => {
        if (clinics.length > 0) {
            if (!clinics.some((clinic) => clinic.value === formData.clinicId)) {
                let newFormData = formData;
                newFormData.clinicId = null
                setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
            }
        }

        if (reportTypes.length > 0) {
            if (!reportTypes.some((clinic) => clinic.value === formData.reportTypeId)) {
                let newFormData = formData;
                newFormData.reportTypeId = ""
                setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
            }
        }
    }, [clinics, reportTypes])

    function getTaggedReports() {
        setLoading(true);

        apiService.getTaggedReports(formData.patientNameId).then((result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let filteredReports = data.reports.filter((report: any) => report.documentId !== props.selectedReportId);
            setTaggedReports(filteredReports)

        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPatient() {

        setLoading(true);

        apiService.getPatient().then((result) => {
            let data = result.data;
            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            setPatients(data.patients);
            getPatientTextInput(data.patients);

        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPatientTextInput(patients: IPatient[]) {

        setLoading(true);

        let patientArr = [] as ITextInput[];

        patients.forEach(patient => {
            let data = {
                label: patient.name,
                value: patient.id
            }

            patientArr.push(data);
        }); 

        setFilteredPatients(patientArr);
        setLoading(false);
    }


    function getReportDataById() {

        setLoading(true);

        let data = {
            documentId: props.selectedReportId
        }

        apiService.getDocumentById(data).then(async (result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            await loadData(data?.document);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getReferrers() {

        setLoading(true);

        apiService.getReferrers().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getReferrersTextInput(data?.referrers);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getReferrersTextInput(referrers: IReferrer[]) {

        setLoading(true);

        let referrersArr = [] as ITextInput[];

        referrers.forEach(referrer => {
            let data = {
                label: referrer.name,
                value: referrer.id
            }

            referrersArr.push(data);
        });

        setReferrers(referrersArr);

        setLoading(false);
    }

    function getPriorities() {

        setLoading(true);

        apiService.getPriorities().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getPrioritiesTextInput(data?.priorities);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPrioritiesTextInput(priorities: IPriority[]) {


        let prioritiesArr = [] as ITextInput[];

        priorities.forEach(priority => {
            let data = {
                label: priority.name,
                value: priority.id.toString()
            }

            prioritiesArr.push(data);
        });

        setPriorities(prioritiesArr);

    }

    async function getDocumentMedia(mediaId: string) {
        await getMediaDocument(mediaId, setMedia)
    }

    async function loadData(data: IEditReport) {
        let newFormData = formData;
        newFormData.patientName = data.patientName;
        newFormData.patientNameId = data.patientId;
        newFormData.dueDate = data.dueDate ? moment.utc(data.dueDate).local() : moment().utc().local();
        newFormData.priorityId = data.priorityId as any;
        newFormData.doctorId = data.doctorId;
        newFormData.clinicId = data.clinicId as any;
        newFormData.reportTypeId = data.reportTypeId;
        newFormData.providerNumber = data.providerNumber as any;
        newFormData.placeOfAssessment = data.placeOfAssessment as any;
        newFormData.dateOfAssessment = data.dateOfAssessment ? moment.utc(data.dateOfAssessment).local() : moment().utc().local();
        newFormData.referrerId = data.referrerId as any;
        newFormData.reportName = data.reportName as any;
        newFormData.notes = data.notes as any;
        newFormData.mediaId = data.mediaId;
        newFormData.adHoc = data.adHoc;
        newFormData.totalDuration = data.totalDuration;
        newFormData.scribeAssignedTo = data.scribeAssignedTo as any;
        newFormData.proofAssignedTo = data.proofAssignedTo as any;
        newFormData.reviewAssignedTo = data.reviewAssignedTo as any;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));

        let taggedReports = [] as ITaggedReport[];

        data.taggedReports.forEach((report) => {
            let _report = {
                documentId: report,
                reportName: ""
            }
            taggedReports.push(_report);
        });

        setSelectedTaggedReports(taggedReports);

        let newStageStatus = stageStatus;
        newStageStatus.scribe = data.scribeStatus;
        newStageStatus.proof = data.proofStatus;
        newStageStatus.review = data.reviewStatus;
        setStageStatus((prevDetails) => ({ ...prevDetails, ...newStageStatus }));

        await getDocumentMedia(data.mediaId);
    }

    function getAssigneesTextInput(allAssignees: IAssignee[]) {

        setLoading(true);

        let filteredScribeAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Scribe"
        });

        let filteredProofAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Proof"
        });

        let filteredReviewAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Review"
        });

        let scribersArr = [] as ITextInput[];
        let proofersArr = [] as ITextInput[];
        let reviewersArr = [] as ITextInput[];

        filteredScribeAssignees.forEach(scriber => {
            let data = {
                label: scriber.name,
                value: scriber.email
            }

            scribersArr.push(data);
        });

        filteredProofAssignees.forEach(proofer => {
            let data = {
                label: proofer.name,
                value: proofer.email
            }

            proofersArr.push(data);
        });

        filteredReviewAssignees.forEach(reviewer => {
            let data = {
                label: reviewer.name,
                value: reviewer.email
            }

            reviewersArr.push(data);
        });

        let newAssignees = assignees;
        newAssignees.scribers = scribersArr;
        newAssignees.proofers = proofersArr;
        newAssignees.reviewers = reviewersArr;
        setAssignees((prevDetails) => ({ ...prevDetails, ...newAssignees }));

        setLoading(false);
    }

    function getAssignees() {

        setLoading(true);

        apiService.getAssignees().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getAssigneesTextInput(data?.users);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleNext = () => {
        setView(view + 1);
    }

    const handlePrevious = () => {
        setView(view - 1);
    }

    function getReportTypes() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId,
            clinicId: formData.clinicId
        }

        apiService.getReportTypesByDoctorIdOrClinicId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getReportTypeTextInput(data?.reportTypes);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const clearData = () => {
        let newFormData = formData;
        newFormData.patientName = "";
        newFormData.dueDate = moment().utc().local();
        newFormData.priorityId = null;
        newFormData.doctorId = "";
        newFormData.clinicId = null;
        newFormData.reportTypeId = "";
        newFormData.providerNumber = "" as string;
        newFormData.placeOfAssessment = "" as string;
        newFormData.dateOfAssessment = moment().utc().local();
        newFormData.referrerId = null;
        newFormData.reportName = "" as string;
        newFormData.notes = "" as string;
        newFormData.mediaId = "";
        newFormData.adHoc = false;
        newFormData.totalDuration = 0;
        newFormData.scribeAssignedTo = null;
        newFormData.proofAssignedTo = null;
        newFormData.reviewAssignedTo = null;
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newStageStatus = stageStatus;
        newStageStatus.scribe = "NotStarted";
        newStageStatus.proof = "NotStarted";
        newStageStatus.review = "NotStarted";
        setStageStatus((prevDetails) => ({ ...prevDetails, ...newStageStatus }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.patientNameError.showError = false;
        newFormDataErrors.dueDateError.showError = false;
        newFormDataErrors.priorityIdError.showError = false;
        newFormDataErrors.doctorIdError.showError = false;
        newFormDataErrors.reportTypeIdError.showError = false;
        newFormDataErrors.dateOfAssessmentError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setMedia([]);
        setLoading(false);
        setView(0);
    }

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("edit", shouldUpdate);
    }

    const closeModal = (shouldUpdate: boolean) => {
        setLoading(true);

        addedFiles.forEach(async (file) => {
            await removeMedia(file.fileId);
            let _formData = formData;
            _formData.totalDuration = formData.totalDuration - file.duration;
            setFormData(_formData);
        });

        handleClose(shouldUpdate);
    }

    const updateFormData = (newFormData: any) => {
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function toMedia() {

        setLoading(true);

        if (formData.patientName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.patientNameError.showError = true;
            newFormDataErrors.patientNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.patientNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!(formData.dueDate instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dueDateError.showError = true;
            newFormDataErrors.dueDateError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dueDateError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.priorityId === null) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.priorityIdError.showError = true;
            newFormDataErrors.priorityIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.priorityIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.doctorId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = true;
            newFormDataErrors.doctorIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.reportTypeId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = true;
            newFormDataErrors.reportTypeIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!(formData.dateOfAssessment instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dateOfAssessmentError.showError = true;
            newFormDataErrors.dateOfAssessmentError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dateOfAssessmentError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        setLoading(false);
        handleNext();
    }

    function getDoctors() {

        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getDoctorTextInput(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinicTextInput(clinics: IClinic[]) {


        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

    }

    function getDoctorTextInput(doctors: IDoctor[]) {


        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

    }

    function getReportTypeTextInput(reportTypes: IReportType[]) {


        let reportTypesArr = [] as ITextInput[];

        reportTypes.forEach(reportType => {
            let data = {
                label: `${reportType.reportName} - ${reportType.clinicName}`,
                value: reportType.id
            }

            reportTypesArr.push(data);
        });

        setReportTypes(reportTypesArr);

    }

    async function updateReport() {

        setLoading(true);
        for (let file of removedMedia) {
            await removeMedia(file.fileId);
        }


        let mediaAdded = await createMediaDocument(formData.mediaId, media);

        if (!mediaAdded) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Media File is Required";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            setLoading(false);
            return;
        }

        let ds2Files = media.filter(media => media.fileName.includes('.ds2'));

        if (ds2Files.length === media.length) {
            let newForm = formData;
            newForm.adHoc = true;
            setFormData((previous) => ({ ...previous, newForm }));
        }

        let _taggedReports = [] as string[];

        selectedTaggedReports.forEach(report => {
            _taggedReports.push(report.documentId)
        });

        const data = Object.assign(formData, {
            id: props.selectedReportId,
            addedDS2: (ds2Files.length > 0),
            taggedReports: _taggedReports
        })

        apiService.updateReport(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Report Updated";
            newShowAlert.open = true;
            props.showReport((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    async function removeFile(file: IFile) {
        setLoading(true);
        let _removedMedia = removedMedia;
        _removedMedia.push(file);
        setRemovedMedia(_removedMedia);

        let _media = media;
        let _filteredArray = _media.filter((_file) => _file !== file);
        setMedia(_filteredArray);
        setLoading(false);
    }

    function getTitle() {
        if (formData.patientName.trim() !== "") {
            return "Edit Report - " + formData.patientName;
        }

        return "Edit Report"
    }

    const handleSelectTaggedReport = (selected: boolean, report: ITaggedReport) => {
        if (selected) {
            setSelectedTaggedReports(previous => [...previous, report])
        } else {
            setSelectedTaggedReports(previous => previous.filter((value, _) => value.documentId !== report.documentId))
        }
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={closeModal}
            styles={"add-report-card"}>
            <div className="edit-report-container">
                <span className='title'>{getTitle()}</span>

                {view === 0
                    ? (<Form patients={filteredPatients} formData={formData} setFormData={updateFormData} formDataErrors={formDataErrors} toAddMedia={toMedia}
                        doctors={doctors} clinics={clinics} reportTypes={reportTypes} loading={loading} referrers={referrers}
                        priorities={priorities} />)
                    : (<Fragment />)
                }
                {view === 1
                    ? (<TagReport selectedDownloadReport={selectedDownloadReport} setSelectedDownloadReport={setSelectedDownloadReport} setLoading={setLoading} loading={loading} taggedReports={taggedReports} patients={filteredPatients} formData={formData} setFormData={updateFormData} formDataErrors={formDataErrors} addTags={handleNext}
                        back={handlePrevious} onSelectReport={handleSelectTaggedReport} selectedReports={selectedTaggedReports} />)
                    : (<Fragment />)
                }
                {view === 2
                    ? (<AddMedia addedFiles={addedFiles} setAddedFiles={setAddedFiles} removeFile={removeFile} next={handleNext} back={handlePrevious} formData={formData} media={media} setShowAlert={setShowAlert}
                        setMedia={setMedia} setFormData={updateFormData} loading={loading} setLoading={setLoading} />)
                    : (<Fragment />)
                }
                {view === 3
                    ? (<AssignTo update={updateReport} back={handlePrevious} assignees={assignees} formData={formData}
                        setFormData={updateFormData} loading={loading} stageStatus={stageStatus} />)
                    : (<Fragment />)
                }
            </div>
        </Modal>
    )
}

function TagReport(props: {
    back: CallableFunction, addTags: CallableFunction, formData: any; setFormData: CallableFunction; patients: ITextInput[]; formDataErrors: any; taggedReports: ITaggedReport[]
    onSelectReport: CallableFunction, selectedReports: ITaggedReport[], loading: boolean, setSelectedDownloadReport: CallableFunction, selectedDownloadReport: IReportData, setLoading: CallableFunction
}) {

    return (
        <div className='tag-report-content'>
            <div className="stepper">
                <Stepper steps={[
                    { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form" },
                    { circleStyle: "active", descriptionStyle: "description-active", description: "Tag report" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to" }
                ]} />
            </div>
            <div className="report-content">
                <div className="input-field">
                    <InputDropDown title='PATIENT NAME*' type='search' typeable
                        defaultValue={props.formData.patientName} values={props.patients}
                        showError={props.formDataErrors?.priorityIdError?.showError}
                        onSelect={(val: any, asValue: any) => {
                            let newFormData = props.formData;
                            if (asValue) {
                                newFormData.patientName = val;
                                newFormData.patientNameId = "";
                            } else {
                                newFormData.patientNameId = val[0].value;
                                newFormData.patientName = val[0].label.toString().split(' - ')[0];
                            }
                            props.setFormData(newFormData);
                        }} />
                </div>
                <div className="report-view">
                    {props.taggedReports.length > 0
                        ? props.taggedReports.map((report) => (
                            <div key={report.documentId} className="report-data">
                                <CheckBox onChange={(value: boolean) => props.onSelectReport(value, report)} icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot}
                                    checked={props.selectedReports.filter(_report => report.documentId === _report.documentId).length > 0 ?? false} />
                                <div className="report-data-content">
                                    <div className="report-name">
                                        <img src={ReportBlue} alt="ReportBlue" />
                                        <span>{report.reportName}</span>
                                    </div>
                                    <img onClick={() => downloadWordDocument({ documentId: report.documentId, reportName: report.reportName, setLoading: props.setLoading, setReport: props.setSelectedDownloadReport, report: props.selectedDownloadReport })} className='download-icon' src={DownloadIcon} alt="ReportBlue" />
                                </div>
                            </div>
                        ))
                        : <div className="report-empty">No Reports</div>}
                </div>
            </div>
            <div className="footer-btn">
                <span onClick={() => props.back()} className="back">Back</span>
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => props.addTags()} text={"Next"} />
                </div>
            </div>
        </div>
    )
}

function Form(props: {
    formData: any; setFormData: CallableFunction; formDataErrors: any; doctors: ITextInput[]; patients: ITextInput[]; clinics: ITextInput[];
    reportTypes: ITextInput[]; toAddMedia: CallableFunction; loading: boolean; referrers: ITextInput[]; priorities: ITextInput[];
}) {
    return (
        <div className="fill-form">
            <div className="header">
                <div className="stepper">
                    <Stepper
                        steps={[
                            { circleStyle: "active", descriptionStyle: "description-active", description: "Fill the form" },
                            { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag report" },
                            { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                            { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to" }
                        ]} />
                </div>
                <div className="form">
                    <div className="row">
                        <div className="input-field">
                            <InputDropDown title='PATIENT NAME*' type='search' typeable
                                defaultValue={props.formData.patientName} values={props.patients}
                                showError={props.formDataErrors?.priorityIdError?.showError}
                                onSelect={(val: any, asValue: any) => {
                                    let newFormData = props.formData;
                                    if (asValue) {
                                        newFormData.patientName = val;
                                        newFormData.patientNameId = "";
                                    } else {
                                        newFormData.patientNameId = val[0].value;
                                        newFormData.patientName = val[0].label.toString().split(' - ')[0];
                                    }
                                    props.setFormData(newFormData);

                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='PRIORITY*' type='dropdown' defaultValue={props.formData.priorityId}
                                showError={props.formDataErrors?.priorityIdError?.showError} values={props.priorities}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.priorityId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <DateInput label="DUE DATE*" value={props.formData?.dueDate}
                                showError={props.formDataErrors?.dueDateError?.showError}
                                onChange={(value: any) => {
                                    if (value) {
                                        let newFormData = props.formData;
                                        newFormData.dueDate = value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='DOCTOR*' values={props.doctors} type='search'
                                showError={props.formDataErrors?.doctorIdError?.showError}
                                defaultValue={props.formData.doctorId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.doctorId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='CLINIC' values={props.clinics} type='search'
                                defaultValue={props.formData.clinicId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.clinicId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='REPORT TYPE*' values={props.reportTypes} type='search'
                                showError={props.formDataErrors?.reportTypeIdError?.showError}
                                defaultValue={props.formData.reportTypeId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.reportTypeId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='PROVIDER NUMBER' value={props.formData.providerNumber} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.providerNumber = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='PLACE OF ASSESSMENT' value={props.formData.placeOfAssessment} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.placeOfAssessment = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field">
                            <DateInput label="DATE OF ASSESSMENT*" value={props.formData.dateOfAssessment}
                                showError={props.formDataErrors?.dateOfAssessmentError?.showError}
                                onChange={(value: any) => {
                                    if (value) {
                                        let newFormData = props.formData;
                                        newFormData.dateOfAssessment = value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='REFERRER' values={props.referrers} type='search'
                                defaultValue={props.formData.referrerId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.referrerId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='REPORT NAME' value={props.formData.reportName} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.reportName = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field"> </div>
                    </div>
                    <div className="row">
                        <Input label='COMMENTS' value={props.formData.notes} type='textarea' rows={5}
                            onChange={(value: any) => {
                                let newFormData = props.formData;
                                newFormData.notes = value;
                                props.setFormData(newFormData);
                            }} />
                    </div>
                </div>
            </div>

            <div className="footer-btn">
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => { props.toAddMedia() }} text={"Next"} />
                </div>
            </div>
        </div >
    )
}

function AddMedia(props: {
    next: CallableFunction; back: CallableFunction; formData: any; setFormData: CallableFunction; loading: boolean;
    setLoading: CallableFunction; media: IFile[]; setMedia: CallableFunction; setShowAlert: CallableFunction;
    removeFile: CallableFunction; addedFiles: IFile[], setAddedFiles: CallableFunction
}) {
    return (
        <div className="add-media">
            <div className="stepper">
                <Stepper
                    steps={[
                        { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form", },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag report" },
                        { circleStyle: "active", descriptionStyle: "description-inactive", description: "Add media files", },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to", },
                    ]} />
            </div>
            <FileUploadUI addedFiles={props.addedFiles} setAddedFiles={props.setAddedFiles} removeFile={props.removeFile} setLoading={props.setLoading} setMedia={props.setMedia}
                media={props.media} setShowAlert={props.setShowAlert} />

            <div className="footer-btn">
                {props.formData.totalDuration === 0
                    ? (<span className="skip">
                        <CheckBox icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot} checked={props.formData.adHoc}
                            onChange={() => {
                                let newFormData = props.formData;
                                newFormData.adHoc = !props.formData.adHoc;
                                props.setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }));
                            }} />
                        Add Documentation
                    </span>) :
                    (<Fragment />)
                }
                <div className="next-button-content">
                    <span onClick={() => { props.back() }} className="back">Back</span>
                    <div className="button">
                        <DocumentButton loading={props.loading} onClick={() => { props.next() }} text={"Next"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function AssignTo(props: {
    update: CallableFunction; back: CallableFunction; assignees: any; formData: any;
    setFormData: CallableFunction; loading: boolean; stageStatus: any;
}) {
    return (
        <div className="assign-to">
            <div className="stepper">
                <Stepper
                    steps={[
                        { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form" },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag report" },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                        { circleStyle: "active", descriptionStyle: "description-inactive", description: "Assign to" }
                    ]} />
            </div>
            <div className="form">
                <div className="row">
                    <div className="item">
                        <InputDropDown title='SCRIBER' values={props.assignees.scribers} type='search'
                            defaultValue={props.formData.scribeAssignedTo}
                            onSelect={(value: any) => {
                                if (value?.length > 0) {
                                    let newFormData = props.formData;
                                    newFormData.scribeAssignedTo = value[0].value;
                                    props.setFormData(newFormData);
                                }
                            }} />
                    </div>
                    <div className="item">
                        {props.stageStatus?.scribe === "Completed"
                            ? (<InputDropDown title='PROOFER' values={props.assignees.proofers} type='search'
                                defaultValue={props.formData.proofAssignedTo}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.proofAssignedTo = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />)
                            : (<Fragment />)
                        }
                    </div>
                    <div className="item">
                        {props.stageStatus?.proof === "Completed"
                            ? (<InputDropDown title='REVIEWER' values={props.assignees.reviewers} type='search'
                                defaultValue={props.formData.reviewAssignedTo}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.reviewAssignedTo = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />)
                            : (<Fragment />)
                        }
                    </div>
                </div>
            </div>
            <div className="footer-btn">
                <span onClick={() => props.back()} className="back">Back</span>
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => { props.update() }} text={"Update"} />
                </div>
            </div>
        </div>
    )
}