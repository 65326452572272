import Logo from "../../assets/images/LightBlueLogo.svg"

import "./PrivacyPolicy.scss"

function PrivacyPolicy() {
    return (
        <div className='privacy-policy-content'>
            <div className="header">
                <img className='logo' src={Logo} alt="Logo" />
                <div className="header-data">
                    <div className="section">
                        <label className='color-blue a-1 bold-6'>Scribe on Time Pty Ltd</label>
                        <label className='color-blue bold-4 a-1'>ABN: 68 608 076 802</label>
                    </div>
                    <div className="section margin-top-1">
                        <label className='color-blue bold-4 a-1'>117, Marigold Crescent,</label>
                        <label className='color-blue bold-4 a-1'>Gowanbrae VIC 3043.</label>
                    </div>
                    <div className="section margin-top-1">
                        <label className='color-blue bold-4 a-2'>Email: hello@scribeontime.com</label>
                        <label className='color-blue bold-4 a-2'>Web: www.scribeontime.com</label>
                    </div>
                </div>
            </div>

            <div className="body margin-top-4">
                <label htmlFor="" className="title-1">Privacy Policy for "Scribe IT" Office Add-in, Web and Mobile Applications</label>

                <label htmlFor="" className="title-2 margin-top-3">Our Commitment to Privacy </label>

                <p className="a-1 margin-top-2">This Privacy Policy governs the way Scribe IT ("we," "us," or "our")
                    collects, uses, maintains, and discloses information collected from users ("you") of the "Scribe IT" office add-in,
                    web and mobile application ("Application"). This policy applies to the Application and all products and services
                    offered by Scribe IT.</p>

                <label htmlFor="" className="title-2 margin-top-3">Information We Collect </label>

                <label htmlFor="" className="a-2 margin-top-3">1.1 Personal Information</label>

                <p className="a-1 margin-top-2">We may collect personal information from you in various ways when you use the Application,
                    including but not limited to:</p>

                <ul className='margin-top-1'>
                    <li className='a-1 margin-top-1'>Name: We may collect your full name to personalise your experience within the Application.</li>
                    <li className='a-1 margin-top-1'>Email Address: Your email address may be collected to communicate with you regarding your account,
                        send important notifications, and provide support.</li>
                    <li className='a-1 margin-top-1'>Contact Information: We may collect contact information, such as your phone number or address, to
                        facilitate communication or enable specific features of the Application.</li>
                    <li className='a-1 margin-top-1'>Usage Data: We may collect information about how you interact with the Application,
                        including pages visited, features used, and other usage patterns, to analyse and improve the Application's performance
                        and functionality.</li>
                </ul>

                <label htmlFor="" className="title-2 margin-top-3">Information Use and Disclosure</label>

                <p className="a-1 margin-top-2">We may use the personal information collected from you for the following purposes:</p>

                <ul className='margin-top-1'>
                    <li className='a-1 margin-top-1'>Provide and maintain the Application: Your information enables us to deliver and improve
                        the functionality, features, and services offered through the Application.</li>
                    <li className='a-1 margin-top-1'>Customise and improve user experience: We may use your information to personalise your
                        interactions with the Application, tailor content, and enhance your overall experience.</li>
                    <li className='a-1 margin-top-1'>Respond to inquiries and support requests: If you reach out to us with questions or
                        support requests, we may use your information to address and resolve your inquiries.</li>
                    <li className='a-1 margin-top-1'>Communication: We may use your email address or contact information to send you
                        important notifications, updates, and administrative messages related to the Application.</li>
                    <li className='a-1 margin-top-1'>Market research and analysis: We may use your information to conduct research, surveys,
                        or analysis to understand user trends, preferences, and patterns, and improve our offerings accordingly.</li>
                    <li className='a-1 margin-top-1'>Legal obligations: We may use or disclose your information to comply with applicable laws,
                        regulations, legal processes, or enforceable governmental requests, as required by law.</li>
                </ul>

                <p className="a-1 margin-top-2">We may disclose the personal information collected from you for the following purposes:</p>

                <label htmlFor='' className="a-1 bold-6 margin-top-2">Service Providers</label>

                <p className="a-1 margin-top-2">We may engage trusted third-party service providers to assist us in operating, maintaining,
                    and improving the Application. These service providers may have access to your personal information solely for performing
                    tasks on our behalf and are obligated to protect your information in accordance with this Privacy Policy.</p>

                <label htmlFor='' className="a-1 bold-6 margin-top-2">Legal Compliance</label>

                <p className="a-1 margin-top-2">We may disclose your personal information if required by law or in response to valid requests
                    by public authorities, such as government agencies or law enforcement, to meet legal obligations or enforceable governmental
                    requests.</p>

                <label htmlFor="" className="title-2 margin-top-3">Our Commitment to Data Security</label>

                <p className="a-1 margin-top-2">We prioritise the security of your personal information and employ reasonable measures to protect it
                    against unauthorised access, alteration, disclosure, or destruction. However, please be aware that no method of transmission or storage
                    over the internet is entirely secure, and we cannot guarantee the absolute security of your data. To prevent unauthorized access,
                    maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic,
                    and managerial procedures to safeguard and secure the information we collect online.</p>

                <p className="a-1 margin-top-2">Application supports secure communication over HTTPS using SSL/TLS protocols.
                    This ensures that data transmitted between the web server and client is encrypted, preventing eavesdropping and tampering.
                    The application also provides validation controls and features to validate user input and protect against common web
                    vulnerabilities such as cross-site scripting (XSS) and SQL injection attacks. It includes server-side validation,
                    client-side validation, and input sanitisation techniques.</p>

                <label htmlFor="" className="title-2 margin-top-3">Policy Changes </label>

                <p className="a-1 margin-top-2">This Privacy Policy may be revised from time to time by updating this page.
                    We suggest you review our Privacy Policy regularly.</p>

                <label htmlFor="" className="title-2 margin-top-3">How to Contact Us </label>

                <p className="a-1 margin-top-2">If you have specific questions about our Privacy Policy, please contact us at: hello@scribeontime.com </p>

                <label htmlFor="" className="a-1 margin-top-3">Updated 30 June 2023</label>
            </div>
        </div>
    )
}

export default PrivacyPolicy