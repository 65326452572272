import DocumentButton from '../../components/DocumentButton/DocumentButton';
import { obscureEmail } from '../../helpers/HelperFunctions';
import OtpInput from 'react-otp-input';

type OtpContentProps = {
    readonly email: string,
    readonly sendOtp: CallableFunction,
    readonly login: CallableFunction,
    readonly loading: boolean,
    readonly otp: string,
    readonly setOtp: (otp: string) => void,

}

function OtpForm({ email, loading, sendOtp, login, otp, setOtp }: OtpContentProps) {
    return (
        <div className='otp-content'>
            <label className="title">OTP Verification</label>
            <label className="message">Enter the verification code sent to your email address</label>
            <label className="email">{obscureEmail(email)}</label>

            <div className="otp-form">
                <div className="otp-form">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={8}
                        renderInput={(props) => <input  {...props} />}
                    />
                </div>
            </div>
            <label className="send-otp" onClick={() => login()}>Resend OTP</label>
            <div className="button-content">
                <DocumentButton text={"Verify"} onClick={sendOtp} loading={loading} />
            </div>
        </div>
    );
}

export default OtpForm