import { IReportTemplate } from "../../helpers/Interfaces";
import EditIcon from "../../assets/images/Edit.svg";
import Trash from "../../assets/images/Trash.svg";
import Show from "../../components/Show/Show";
import { validStringField } from "../../helpers/HelperFunctions";
import { TemplateFilterTypes } from "../../helpers/Types";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import SearchableDown from "../../components/DropDown/DropDown";

type ReportTemplatesUIProps = {
    canEdit: boolean;
    canDelete: boolean
    reportTemplates: IReportTemplate[];
    setSelectedReportTemplate: Dispatch<SetStateAction<IReportTemplate>>;
    handleModals: (open: boolean, modal: "edit" | "delete", shouldUpdate?: boolean) => void;
    filter: (filterBy: TemplateFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: () => void;
}

export default function ReportTemplatesUI(props: ReportTemplatesUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<TemplateFilterTypes>("reportName");

    const handleClick = useCallback((selectedReportTemplate: IReportTemplate) => {
        props.setSelectedReportTemplate(selectedReportTemplate);
        props.handleModals(true, "edit");
    }, []);

    const handleSearchOpen = (filterBy: TemplateFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleDelete = useCallback((report: IReportTemplate) => {
        props.setSelectedReportTemplate(report);
        props.handleModals(true, "delete");
    }, []);

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.reportTemplates.forEach(report => {
            if (report[selectedFilter] != null) {
                valueArr.push(report[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table report-template">
        <div className="header">
            <div className="header-row">
                <span className="text">Doctor</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("doctorName") }} />
            </div>
            <div className="header-row">
                <span className="text">Clinic</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("clinic") }} />
            </div>
            <div className="header-row">
                <span className="text">Report Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("reportName") }} />
            </div>
            <div className="header-row">
                <span className="text">Short Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("shortName") }} />
                <div className="size-box" />
                <div className="filter-content">
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                        : <div className="filter-icon" />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.reportTemplates.map((reportTemplate) => {
                return (<div key={reportTemplate.id} className="data-row">
                    <div className="cell text">{validStringField(reportTemplate.doctorName)}</div>
                    <div className="cell text">{validStringField(reportTemplate.clinic)}</div>
                    <div className="cell text">{validStringField(reportTemplate.reportName)}</div>
                    <div className="cell text">
                        {validStringField(reportTemplate.shortName)}
                        <div className="icon-container">
                            <Show hasAccess={props.canEdit}>
                                <img className="icon" src={EditIcon} alt={"Edit"} onClick={() => { handleClick(reportTemplate) }} />
                            </Show>
                            <Show hasAccess={props.canDelete} >
                                <img className="icon space" src={Trash} alt={"Delete"}
                                    onClick={() => { handleDelete(reportTemplate) }} />
                            </Show>
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}