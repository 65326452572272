import DocumentButton from '../DocumentButton/DocumentButton'
import TrashRedBack from "../../assets/images/TrashRedBack.svg";

import "../../components/DeletePrompt/DeletePrompt.scss"

type DeleteViewProps = {
    onDelete: CallableFunction,
    loading: boolean,
    reportType: string
}

function DeleteView(props: DeleteViewProps) {
    return (
        <div className="delete-report delete-doc-container">
            <img className='trash-icon' src={TrashRedBack} alt="TrashRedBack" />
            <div className="content">
                <label className='header'>{props.reportType}</label>
                <label className='body'>Please note that this action is irreversible. Are you certain you wish to proceed
                    with this operation?
                </label>
            </div>
            <div className="footer">
                <DocumentButton backgroundColor='#dd3a3a' text={'Delete'} onClick={props.onDelete} loading={props.loading} />
            </div>
        </div>
    )
}

export default DeleteView