import Layout from "../../components/Layout/Layout";
import Tabs from "../../components/Tabs/Tabs";
import { Fragment, useContext, useState } from "react";
import AccountSettings from "./AccountSettings";
import { EditAccess } from "./EditAccess";
import { isInRole } from "../../helpers/HelperFunctions";
import { User } from "../../store/Store";
import PdfView from "../../assets/images/PdfView.svg"
import Pedal from "../../assets/images/Pedal.svg"
import Download from "../../assets/images/Download2.svg"

import "./Settings.scss";

export default function Settings() {

    const [showEdit, setShowEdit] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const [user] = useContext(User);

    function downloadPedalInstaller() {
        return "https://firebasestorage.googleapis.com/v0/b/scribeit-2dfc9.appspot.com/o/pedal%2FPedalControllerSetup.msi?alt=media&token=234d0dc9-6801-45ce-be5d-18dc6717a074";
    }

    function downloadManual() {
        let url = "https://firebasestorage.googleapis.com/v0/b/scribeit-2dfc9.appspot.com/o/pedal%2FScribeIT%20Foot%20Pedal%20Controller.pdf?alt=media&token=5612bbfb-85b0-483e-9a93-bab671f2ffea";;
        window.open(url, '_blank');
    }

    return (<Layout selectedIndex={7}>
        <div className="settings-container">
            <div className="top-bar">
                <span className="text">Settings</span>
            </div>
            {showEdit
                ? <EditAccess />
                : <>
                    <div className="tag-area">
                        <Tabs titles={["Account"]} selectedIndex={selectedTab} onClick={setSelectedTab} />
                        {isInRole(user?.roles, "Admin")
                            ? (<span onClick={() => { setShowEdit(true); }} className="edit-access">Edit Access</span>)
                            : (<Fragment />)
                        }
                    </div>
                    <div className="settings-contetnt-area">
                        <AccountSettings />
                        <div className="user-manual">
                            <label className="title">Pedal Controller</label>
                            <div className="card-content">
                                <div className="card">
                                    <img src={Pedal} alt="Controller" />
                                    <a className="button" href={downloadPedalInstaller()}>
                                        <span className="header">Pedal Controller</span>
                                        <img src={Download} alt="Download" />
                                    </a>
                                </div>
                                <div className="card">
                                    <img src={PdfView} alt="PDF" />
                                    <button className="button" onClick={(e) => {  downloadManual() }}>
                                        <span className="header">User Guide</span>
                                        <img src={Download} alt="Download" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    </Layout>)
}