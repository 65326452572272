import { Fragment, useState, useEffect, useCallback, useContext } from "react";
import Layout from "../../components/Layout/Layout";
import NewButton from "../../components/NewButton/NewButton";
import Search from "../../components/Search/Search";
import Tabs from "../../components/Tabs/Tabs";
import AddReport from "../../components/AddReport/AddReport";
import ViewReport from "../../components/ViewReport/ViewReport";
import EditReport from "../../components/EditReport/EditReport";
import MuiAlert from "../../components/Alert/Alert";
import ApiService from "../../services/ApiService";
import { IAlert, IReport, ISummaryBox } from "../../helpers/Interfaces";
import AllReportsUI from "./AllReportsUI";
import PendingReportsUI from "./PendingReportsUI";
import AssignedReportsUI from "./AssignedReportsUI";
import SubmittedReportsUI from "./SubmittedReportsUI";
import DraftReportsUI from "./DraftReportsUI";
import SummaryBox from "../../components/SummaryBox/SummaryBox";
import { useParams } from "react-router-dom";
import CompletedReportsUI from "./CompletedReportsUI";
import Show from "../../components/Show/Show";
import { ReportFilterTypes, StageFilterTypes } from "../../helpers/Types";
import { isInRole } from "../../helpers/HelperFunctions";
import { FirebaseUser, User } from "../../store/Store";
import ReportLegend from "../../components/ReportLegend/ReportLegend";
import { updateFirebaseUserRefresh } from "../../helpers/FirebaseFunctions";

import "./Reports.scss";

export default function Reports() {

    const apiService = new ApiService();

    const [selectedTab, setSelectedTab] = useState(0);
    const [reportModals, setReportModals] = useState({ add: false, edit: false, view: false });
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([] as IReport[]);
    const [filteredReports, setFilteredReports] = useState([] as IReport[]);
    const [selectedReport, setSelectedReport] = useState({} as IReport);
    const [showAlert, setShowAlert] = useState({} as IAlert);
    const [summary, setSummary] = useState({} as ISummaryBox);
    const [permission, setPermission] = useState([] as string[]);
    const [showCancelFilter, setShowCancelFilter] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);

    const [user] = useContext(User);
    const [firebaseUser] = useContext(FirebaseUser);

    let { id } = useParams() as any;

    useEffect(() => {
        getReports();
        getSummaryDetails();
        getPermissions();
    }, [])

    useEffect(() => {
        setShowCancelFilter(() => {
            if (reports.length !== filteredReports.length) {
                return true;
            }
            return false;
        });
    }, [filteredReports.length, reports.length])

    useEffect(() => {
        if (id) {
            showSelectedReport(id);
        }
    }, [filteredReports])

    useEffect(() => {
        if (firebaseUser?.refresh) {
            refresh();
        }
    }, [firebaseUser])

    useEffect(() => {
        if (reports.length !== filteredReports.length) {
            resetDefault();
        }
    }, [selectedTab]);

    function resetDefault() {
        setFilteredReports(reports);
        setResetFilter(true);
    }

    function search(searchedWord: string) {

        if (searchedWord.trim() === "") {
            resetDefault();
            return;
        }

        let searchedArr = [] as IReport[];

        filteredReports.forEach((report) => {
            if (report.patientName?.includes(searchedWord)) {
                searchedArr.push(report)
            }

            if (report.reportType?.includes(searchedWord)) {
                searchedArr.push(report)
            }
        })

        setFilteredReports(searchedArr);
    }

    async function refresh() {
        await updateFirebaseUserRefresh(user?.notificationId, false);
        getReports();
    }

    function filterByStage(filterBy: StageFilterTypes) {

        if (selectedTab === 1 || selectedTab === 4 || selectedTab === 5) {
            return;
        }

        let filteredArray = [] as IReport[]

        if (filterBy === "Scribe") {
            filteredArray = reports.filter((report) => !report.completedStages.length);
        }
        if (filterBy === "Proof") {
            filteredArray = reports.filter((report) => report.completedStages.includes("Scribe")
                && report.completedStages.length === 1);
        }
        if (filterBy === "Review") {
            filteredArray = reports.filter((report) => ["Scribe", "Proof"].every((stage) => report.completedStages.includes(stage))
                && report.completedStages.length === 2);
        }

        setFilteredReports(filteredArray);
    }

    function filter(filterBy: ReportFilterTypes, query: string) {
        let filteredList = filteredReports.filter((report) => {
            if (filterBy === "completedStages") {
                const lastStage = report.completedStages.length > 0
                    ? report.completedStages[report.completedStages.length - 1]
                    : null;

                if (query === 'No Value') {
                    return lastStage == null;
                } else {
                    return lastStage === query;
                }
            } else {
                if (query === 'No Value') {
                    return report[filterBy] == null;
                } else {
                    return report[filterBy] === query;
                }
            }
        })

        setFilteredReports(filteredList);
    }

    function getPermissions() {

        setLoading(true);

        let data = {
            page: "Reports",
        };

        apiService.getPermission(data).then((result: any) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setPermission(data?.actions);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
        }).finally(() => {
            setLoading(false);
        });
    }

    function showSelectedReport(documentId: string) {
        let selectedReport = filteredReports.filter((report) => {
            return report.id === documentId;
        })

        if (selectedReport.length > 0) {
            setSelectedReport(selectedReport?.[0]);
            handleOpen("view");
        }
    }

    const handleOpen = useCallback((modal: "add" | "edit" | "view") => {
        let newReportModals = reportModals;
        newReportModals[modal] = true;
        setReportModals((prevDetails) => ({ ...prevDetails, ...newReportModals }));
    }, [])

    const handleClose = useCallback((modal: "add" | "edit" | "view", shouldUpdate = false) => {
        let newReportModals = reportModals;
        newReportModals[modal] = false;
        setReportModals((prevDetails) => ({ ...prevDetails, ...newReportModals }));

        if (shouldUpdate) {
            getReports();
            getSummaryDetails();
        }
    }, [])

    function getSummaryDetails() {

        setLoading(true);

        apiService.getSummaryDetails().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newSummary = summary;
            newSummary.scribeTotalMins = data?.scribeTotalMins;
            newSummary.scribeOutOfTAT = data?.scribeOutOfTAT;
            newSummary.scribePendingMins = data?.scribePendingMins;
            newSummary.scribeInProgressMins = data?.scribeInProgressMins;
            newSummary.proofTotalMins = data?.proofTotalMins;
            newSummary.proofOutOfTAT = data?.proofOutOfTAT;
            newSummary.proofPendingMins = data?.proofPendingMins;
            newSummary.proofInProgressMins = data?.proofInProgressMins;
            newSummary.reviewTotalMins = data?.reviewTotalMins;
            newSummary.reviewOutOfTAT = data?.reviewOutOfTAT;
            newSummary.reviewPendingMins = data?.reviewPendingMins;
            newSummary.reviewInProgressMins = data?.reviewInProgressMins;
            setSummary((prevDetails) => ({ ...prevDetails, ...newSummary }));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function filterReports(status: string) {
        return filteredReports.filter((report) => { return report.documentStatus === status });
    }

    function getReports() {

        setLoading(true);

        apiService.getReports().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setReports(data?.documents);
            setFilteredReports(data?.documents);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAllReports(reports: IReport[]) {
        const assign = reports.filter((report: IReport) => report.documentStatus === "Assigned");
        const pending = reports.filter((report: IReport) => report.documentStatus === "Pending");
        const draft = reports.filter((report: IReport) => report.documentStatus === "Draft");
        const sortedAssign = sortReports(assign);
        const sortedPending = sortReports(pending);
        const sortedDraft = sortReports(draft);
        return sortedAssign.concat(sortedPending, sortedDraft);
    }

    const sortReports = (reports: IReport[]): IReport[] => {
        return reports.sort((a, b) => {
            if (!a.priority && b.priority) {
                return 1;
            }
            if (a.priority && !b.priority) {
                return -1;
            }

            const priorityOrder: { [key: string]: number } = {
                "S. Urgent": 1,
                "Urgent": 2
            };

            const priorityA = priorityOrder[a.priority] ?? Infinity;
            const priorityB = priorityOrder[b.priority] ?? Infinity;

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            }

            if (!a.dueDate && b.dueDate) {
                return 1;
            }
            if (a.dueDate && !b.dueDate) {
                return -1;
            }

            const dueDateA = a.dueDate ? new Date(a.dueDate) : new Date(8640000000000000);
            const dueDateB = b.dueDate ? new Date(b.dueDate) : new Date(8640000000000000);

            return dueDateA.getTime() - dueDateB.getTime();
        });
    };


    return (<Layout selectedIndex={1}>
        <div className="reports-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert, }));
                }} />
            <div className="top-bar">
                <div className="row">
                    <span className="text">Reports</span>
                    <ReportLegend showLegend={selectedTab === 0} showDraft={!isInRole(user?.roles, "Scribe")} />
                    {/* <Search setOnChange={search} /> */}
                </div>
                <SummaryBox data={summary} filter={filterByStage} tabValue={selectedTab} isAdmin={isInRole(user?.roles, "Admin")}
                    isScribe={isInRole(user?.roles, "Scribe")} cancelFilter={resetFilter} setCancelFilter={setResetFilter} />

            </div>
            <div className="table-container">
                <div className="row tab-selector">
                    {isInRole(user?.roles, "Scribe")
                        ? (<Tabs titles={["All Reports"]} selectedIndex={selectedTab} onClick={setSelectedTab} />)
                        : (<Tabs titles={["All Reports", "Draft", "Pending", "Assigned", "Submitted", "Completed"]}
                            selectedIndex={selectedTab} onClick={setSelectedTab} />)
                    }
                    <Show hasAccess={permission?.includes("Create")}>
                        <NewButton text="Add Report" onClick={() => { handleOpen("add"); }} />
                    </Show>
                </div>
                <Show hasAccess={permission?.includes("Read")}>
                    <>
                        {selectedTab === 0
                            ? (<AllReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={getAllReports(filteredReports)} setSelectedReport={setSelectedReport} openView={handleOpen}
                                filter={filter} setShowFilter={setShowCancelFilter} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 1
                            ? (<DraftReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={filterReports("Draft")} setSelectedReport={setSelectedReport}
                                openView={handleOpen} filter={filter} setShowFilter={setShowCancelFilter} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 2
                            ? (<PendingReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={filterReports("Pending")} setSelectedReport={setSelectedReport}
                                openView={handleOpen} filter={filter} setShowFilter={setShowCancelFilter} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 3
                            ? (<AssignedReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={filterReports("Assigned")} setSelectedReport={setSelectedReport}
                                openView={handleOpen} filter={filter} setShowFilter={setShowCancelFilter} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 4
                            ? (<SubmittedReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={filterReports("Submitted")} setSelectedReport={setSelectedReport}
                                openView={handleOpen} filter={filter} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 5
                            ? (<CompletedReportsUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reports={filterReports("Completed")} setSelectedReport={setSelectedReport}
                                openView={handleOpen} filter={filter} setShowFilter={setShowCancelFilter} />)
                            : (<Fragment />)
                        }
                    </>
                </Show>
            </div>

            {reportModals.add
                ? (<AddReport open={reportModals.add} close={handleClose} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {reportModals.edit
                ? (<EditReport open={reportModals.edit} close={handleClose} selectedReportId={selectedReport.id}
                    showReport={setShowAlert} />)
                : (<Fragment />)
            }
            {reportModals.view
                ? (<ViewReport canDelete={permission?.includes("Delete")} open={reportModals.view} closeModal={handleClose} selectedReport={selectedReport}
                    openModal={handleOpen} canEdit={permission?.includes("Update")} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
        </div>
    </Layout>)
}