import { useState, useEffect, SetStateAction, Dispatch } from 'react'
import { IAlert, IClinic, IDoctor, ITextInput } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import InputDropDown from '../InputDropDown/InputDropDown';
import Modal from '../Modal/Modal';
import { IStringFieldWithError } from '../../helpers/InputInterfaces';
import { isStringEmptyOrNull } from '../../helpers/HelperFunctions';

import "./AddReportType.scss"

type AddReportTypeProps = {
    open: boolean;
    handleModals: (open: boolean, modal: "add" | "edit" | "delete", shouldUpdate: boolean) => void;
    showAlert: Dispatch<SetStateAction<IAlert>>;
};

type RequiredFormDataType = "reportName" | "doctorId" | "clinicId";

interface IFormData { reportName: IStringFieldWithError, doctorId: IStringFieldWithError, clinicId: IStringFieldWithError }

function AddReportType(props: AddReportTypeProps) {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<IFormData>({
        reportName: { value: "", showError: false }, doctorId: { value: "", showError: false },
        clinicId: { value: "", showError: false }
    });
    const [showAlert, setShowAlert] = useState<IAlert>({ isError: false, message: "", open: false });
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);

    useEffect(() => {
        getDoctors();
    }, [])

    useEffect(() => {
        if (formData.doctorId.value !== "") {
            getClinics();
        }
    }, [formData.doctorId.value])

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.handleModals(false, "add", shouldUpdate);
    }

    function clearData() {
        let newFormData = formData;
        newFormData.reportName.value = "";
        newFormData.doctorId.value = "";
        newFormData.clinicId.value = "";

        newFormData.reportName.showError = false;
        newFormData.doctorId.showError = false;
        newFormData.clinicId.showError = false;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));

        closeAlert();
        setLoading(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getDoctors() {

        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getDoctorTextInput(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId.value
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinicTextInput(clinics: IClinic[]) {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function getDoctorTextInput(doctors: IDoctor[]) {

        setLoading(true);

        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

        setLoading(false);
    }

    function handleRequiredFormDataErrors(field: RequiredFormDataType) {
        let newFormData = formData;
        newFormData[field].showError = true;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function handleRequiredFormData(field: RequiredFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;

        if (isStringEmptyOrNull(value)) {
            newFormData[field].showError = true;
        } else {
            newFormData[field].showError = false;
        }

        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function validForm() {

        let isValidForm = true;

        if (isStringEmptyOrNull(formData.reportName.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("reportName");
        }

        if (isStringEmptyOrNull(formData.doctorId.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("doctorId");
        }

        if (isStringEmptyOrNull(formData.clinicId.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("clinicId");
        }

        return isValidForm;
    }

    function saveReportType() {

        setLoading(true);

        if (!validForm()) {
            setLoading(false);
            return;
        }

        let data = {
            reportName: formData.reportName.value,
            doctorId: formData.doctorId.value,
            clinicId: formData.clinicId.value
        }

        apiService.addReportType(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.reportName.value + " Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-add-report-type-card"} >
            <div className="add-report-type-container">
                <div className='title'>Add Report Type</div>
                <div className="input-row">
                    <Input label='REPORT NAME*' value={formData.reportName.value} type='text'
                        showError={formData.reportName.showError}
                        onChange={(value: string) => { handleRequiredFormData("reportName", value) }} />
                </div>
                <div className="input-row">
                    <InputDropDown title='DOCTOR*' values={doctors} type='search'
                        showError={formData.doctorId.showError}
                        onSelect={(value: any) => {
                            if (value?.length > 0) {
                                let newFormData = formData;
                                newFormData.doctorId.value = value[0].value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }
                        }} />
                </div>
                <div className="input-row">
                    <InputDropDown title='CLINIC*' values={clinics} type='search'
                        showError={formData.clinicId.showError}
                        onSelect={(value: any) => {
                            if (value?.length > 0) {
                                let newFormData = formData;
                                newFormData.clinicId.value = value[0].value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }
                        }} />
                </div>
                <div className="footer">
                    <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={saveReportType} text={"Add Report Type"} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddReportType