import Modal from '../Modal/Modal';
import { useCallback, useState } from 'react';
import { IUser } from '../../helpers/Interfaces';
import { separateRoles, validStringField } from '../../helpers/HelperFunctions';
import ProfileImageLoader from '../ProfileImageLoader/ProfileImageLoader';

import "./ViewUserModal.scss"

function ViewUserModal(props: { open: boolean, close: CallableFunction, selectedUser: IUser; openEdit: CallableFunction; }) {

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const openEdit = useCallback(() => {
        props.openEdit("edit");
    }, [])

    const handleClose = useCallback(() => {
        props.close("view");
    }, [])

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} open={props.open} close={handleClose} editable={true}
            styles={"card-view-user"} openEdit={openEdit}>
            <div className="view-user-card-container">
                <ProfileImageLoader name={props.selectedUser.firstName + " " + props.selectedUser.lastName}
                    imageUrl={props.selectedUser.profileImageUrl} style={"user-img"} />
                <span className="user-role">{separateRoles(props.selectedUser.roles)}</span>
                <span className="user-name">{validStringField(props.selectedUser.firstName + " " + props.selectedUser.lastName)}</span>
                <span className="user-email">{validStringField(props.selectedUser.email)}</span>
            </div>
        </Modal>
    )
}

export default ViewUserModal