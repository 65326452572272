import { Fragment, useCallback, useState, useEffect, useContext, Dispatch, SetStateAction } from 'react'
import Modal from '../Modal/Modal'
import Tabs from '../Tabs/Tabs';
import { IAlert, IAssignee, IAssigneeDetails, IAudit, IFile, IReport, IRequestedChange, ITaggedReport, ITextInput } from '../../helpers/Interfaces';
import { getMediaDocument } from '../../helpers/FirebaseFunctions';
import { User } from '../../store/Store';
import ApiService from '../../services/ApiService';
import DocumentDetailsView from './DocumentDetailsView';
import UploadedFileView from './UploadedFileView';
import AssignView from './AssignView';
import ReportView from './ReportView';
import AuditView from './AuditView';
import { isInRole, sortRequestedChanges } from '../../helpers/HelperFunctions';
import DeleteView from './DeleteView';

import "./ViewReport.scss"
import ReOpenDocument from './ReOpentDocument';
import TagReports from './TagReports';

type ViewReportProps = {
    canDelete: boolean,
    open: boolean;
    openModal: (modal: "add" | "edit" | "view") => void;
    closeModal: (modal: "add" | "edit" | "view", shouldUpdate: boolean) => void;
    selectedReport: IReport;
    canEdit: boolean;
    showAlert: Dispatch<SetStateAction<IAlert>>;
}

function ViewReport(props: ViewReportProps) {

    const apiService = new ApiService();

    const [view, setView] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [media, setMedia] = useState([] as IFile[]);
    const [assigneeDetails, setAssigneeDetails] = useState({} as IAssigneeDetails);
    const [formData, setFormData] = useState({ scribeAssignedTo: "", proofAssignedTo: "", reviewAssignedTo: "" });
    const [audits, setAudits] = useState([] as IAudit[]);
    const [taggedReports, setTaggedReports] = useState([] as ITaggedReport[]);
    const [assignees, setAssignees] = useState({
        scribers: [] as ITextInput[], proofers: [] as ITextInput[], reviewers: [] as ITextInput[]
    });
    const [requestedChanges, setRequestedChanges] = useState([] as IRequestedChange[]);

    const [user] = useContext(User);

    useEffect(() => {
        getDocumentMedia();
        getRequestedChangesById();
        getAudits();
        getAssigneeDetails();
        getAssignees();
        getTaggedReports();
    }, [props.selectedReport])

    const handleClose = useCallback((shouldUpdate: boolean) => {
        props.closeModal("view", shouldUpdate);
        clearData();
    }, [])

    const closeAlert = useCallback(() => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }, [])

    const openEdit = useCallback(() => {
        props.openModal("edit");
        handleClose(false);
    }, [])

    const clearData = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setMedia([]);
        setView(0);
    }

    function getTaggedReports() {

        setLoading(true);

        apiService.getTaggedReportsByDocumentId(props.selectedReport.id).then((result: any) => {

            const data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setTaggedReports(data?.reports);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAudits() {

        setLoading(true);

        let data = {
            documentId: props.selectedReport.id
        }

        apiService.getAudits(data).then((result: any) => {

            const data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setAudits(data?.audits);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    async function getDocumentMedia() {
        await getMediaDocument(props.selectedReport?.mediaId, setMedia)
    }

    function getAssignees() {

        setLoading(true);

        apiService.getAssignees().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getAssigneesTextInput(data?.users);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAssigneesTextInput(allAssignees: IAssignee[]) {

        setLoading(true);

        let filteredScribeAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Scribe"
        });

        let filteredProofAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Proof"
        });

        let filteredReviewAssignees = allAssignees.filter((assignee) => {
            return assignee.role === "Review"
        });

        let scribersArr = [] as ITextInput[];
        let proofersArr = [] as ITextInput[];
        let reviewersArr = [] as ITextInput[];

        filteredScribeAssignees.forEach(scriber => {
            let data = {
                label: scriber.name,
                value: scriber.email
            }

            scribersArr.push(data);
        });

        filteredProofAssignees.forEach(proofer => {
            let data = {
                label: proofer.name,
                value: proofer.email
            }

            proofersArr.push(data);
        });

        filteredReviewAssignees.forEach(reviewer => {
            let data = {
                label: reviewer.name,
                value: reviewer.email
            }

            reviewersArr.push(data);
        });

        let newAssignees = assignees;
        newAssignees.scribers = scribersArr;
        newAssignees.proofers = proofersArr;
        newAssignees.reviewers = reviewersArr;
        setAssignees((prevDetails) => ({ ...prevDetails, ...newAssignees }));

        setLoading(false);
    }

    function getAssigneeDetails() {

        setLoading(true);

        let data = {
            documentId: props.selectedReport.id
        }

        apiService.getAssigneeDetails(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setAssigneeDetails(data?.details);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleSetSelectedAssignees(newAssignees: any) {
        setFormData((prevDetails) => ({ ...prevDetails, ...newAssignees }));
        updateAssignees();
    }

    function updateAssignees() {

        setLoading(true);

        let data = Object.assign(formData, { documentId: props.selectedReport.id });

        apiService.assignUsers(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Report Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function releaseDocument() {

        setLoading(true);

        let data = { documentId: props.selectedReport.id };

        apiService.releaseDocument(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Document Released";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }


    function deleteReport() {
        setLoading(true);
        let data = {
            documentId: props.selectedReport.id
        }
        apiService.deleteReport(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = props.selectedReport.reportType + " Deleted";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleReopenDocument = () => {
        setLoading(true);
        let data = {
            documentId: props.selectedReport.id
        }
        apiService.reopenDocument(data).then((result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Document moved to Pending";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            setTimeout(() => {
                handleClose(true);
            }, 2000);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function getRequestedChangesById() {

        setLoading(true);

        let data = {
            documentId: props.selectedReport.id
        }

        apiService.getRequestedChangesById(data).then(async (result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let sortedRequestedChanges = sortRequestedChanges(data?.requestedChanges)

            setRequestedChanges(sortedRequestedChanges);
        }).catch((e) => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function requestChanges(data: any) {
        setLoading(true)
        apiService.requestChanges(data).then((result) => {
            let data = result.data;
            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Changes Requested";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
            setTimeout(() => {
                handleClose(true);
            }, 2000);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const getTitles = (): string[] => {
        const mediaLength = media === undefined || media.length < 1 ? 0 : media.length;
        let list = ["Document Details", `Uploaded Files (${mediaLength})`, "Assignees", "Report", "Audit", "Tag Report"] as string[];

        if (props.canDelete) {
            list.push("Delete");
        }

        if (props.selectedReport.documentStatus === 'Completed') {
            list.push("Re-Open Document");
        }

        return list;
    }

    return (<Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
        styles={"view-report-card"} >
        <div className="view-report-container">
            <span className='title'>View Report</span>

            <div className="tabs">
                <Tabs titles={getTitles()}
                    selectedIndex={view} onClick={setView} />

            </div>

            {view === 0
                ? (<DocumentDetailsView report={props.selectedReport} openEdit={openEdit} loading={loading} canEdit={props.canEdit}
                    handleReopenDocument={handleReopenDocument} />)
                : (<Fragment />)
            }
            {view === 1 ? (<UploadedFileView files={media} />) : (<Fragment />)}
            {view === 2
                ? (<AssignView assigneeDetails={assigneeDetails} scribers={assignees.scribers}
                    proofers={assignees.proofers} reviewers={assignees.reviewers} selectedAssignees={formData}
                    setSelectedAssignees={handleSetSelectedAssignees} loading={loading}
                    report={props.selectedReport} releaseDocument={releaseDocument} isAdmin={isInRole(user.roles, "Admin")}
                    isProofer={isInRole(user.roles, "Proof")} isReviewer={isInRole(user.roles, "Review")}
                    isScriber={isInRole(user.roles, "Scribe")} />)
                : (<Fragment />)
            }
            {view === 3
                ? (<ReportView setLoading={setLoading} setShowAlert={setShowAlert} showAlert={showAlert} report={props.selectedReport}
                    loading={loading} requestChanges={requestChanges} requestedChanges={requestedChanges} />)
                : (<Fragment />)
            }
            {view === 4 ? (<AuditView audits={audits} />) : (<Fragment />)}
            {view === 5 ? (<TagReports setLoading={setLoading} reports={taggedReports} />) : (<Fragment />)}
            {view === 6
                ? (<DeleteView onDelete={deleteReport} reportType={props.selectedReport.reportType} loading={loading} />)
                : (<Fragment />)
            }
            {(view === 7 && props.selectedReport.documentStatus === 'Completed')
                ? (<ReOpenDocument onReOpen={handleReopenDocument} loading={loading} />)
                : (<Fragment />)
            }
        </div>
    </Modal>)
}

export default ViewReport