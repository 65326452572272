import { Fragment } from 'react'
import { IReportStatistic } from '../../helpers/Interfaces'
import ToolTip from '../ToolTip/ToolTip'

import "./ChartBox.scss"

function ChartBox(props: { reportStatistics: IReportStatistic[], showData: any }) {

    function getLowerCase(text: string) {
        return text.charAt(0).toLowerCase() + text.slice(1);
    }

    return (
        <div className='vertical-chart-container' >
            {props.reportStatistics.map((statistic, index: number) => {
                return (
                    <div key={index} className='chart-box'>
                        <div className="chart-body">
                            {statistic.data.map((column, index: number) => {
                                return (
                                    <ToolTip key={index} title={column.name + " " + column.value}
                                        showArrow={false} position={"top"} toolTipRootCSS="tool-tip-one">
                                        {props.showData[getLowerCase(column.name)]
                                            ? <div style={{ height: column.value + "%" }}
                                                className={`${getLowerCase(column.name)} custom-width`} />
                                            : <Fragment />
                                        }
                                    </ToolTip>
                                )
                            })}
                        </div>
                        <span>{statistic.label}</span>
                    </div>
                )
            })}
            <div className="border"></div>
        </div >
    )
}


export default ChartBox