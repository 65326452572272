import { Dispatch, SetStateAction, useState } from 'react';
import ApiService from '../../services/ApiService';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import { isStringEmptyOrNull } from '../../helpers/HelperFunctions';
import { IAlert } from '../../helpers/Interfaces';
import { IStringField, IStringFieldWithError } from '../../helpers/InputInterfaces';

import "./AddClinic.scss";

type AddClinicProps = {
    open: boolean;
    close: CallableFunction;
    showAlert: Dispatch<SetStateAction<IAlert>>;
}

type RequiredFormDataType = "clinicName";
type OptionalFormDataType = "address" | "contactPerson";

interface IFormData { clinicName: IStringFieldWithError, address: IStringField, contactPerson: IStringField }

function AddClinic(props: AddClinicProps) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState<IFormData>({
        clinicName: { value: "", showError: false }, address: { value: "" }, contactPerson: { value: "" }
    });
    const [showAlert, setShowAlert] = useState<IAlert>({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Clinic", "add", shouldUpdate);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function clearData() {
        let newFormData = formData;
        newFormData.clinicName.value = "";
        newFormData.address.value = "";
        newFormData.contactPerson.value = "";

        newFormData.clinicName.showError = false;
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        closeAlert();

        setLoading(false);
    }

    function handleRequiredFormDataErrors(field: RequiredFormDataType) {
        let newFormData = formData;
        newFormData[field].showError = true;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function handleOptionalFormData(field: OptionalFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function handleRequiredFormData(field: RequiredFormDataType, value: string) {
        let newFormData = formData;
        newFormData[field].value = value;

        if (isStringEmptyOrNull(value)) {
            newFormData[field].showError = true;
        } else {
            newFormData[field].showError = false;
        }

        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
    }

    function validForm() {

        let isValidForm = true;

        if (isStringEmptyOrNull(formData.clinicName.value)) {
            isValidForm = false;
            handleRequiredFormDataErrors("clinicName")
        }

        return isValidForm;
    }

    function saveClinic() {

        setLoading(true);

        if (!validForm()) {
            setLoading(false);
            return;
        }

        let data = {
            clinicName: formData.clinicName.value.trim(),
            address: formData.address.value.trim(),
            contactPerson: formData.contactPerson.value.trim()
        }

        apiService.addClinic(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.clinicName.value + " Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-add-clinic-card"} >
            <div className="add-clinic-container">
                <div className='title'>Add Clinic</div>
                <div className="row">
                    <div className="input-field">
                        <Input label='CLINIC NAME*' value={formData.clinicName.value} type='text' showError={formData.clinicName.showError}
                            onChange={(value: string) => { handleRequiredFormData("clinicName", value) }} />
                    </div>
                    <div className="input-field">
                        <Input label='ADDRESS' value={formData.address.value} type='text'
                            onChange={(value: string) => { handleOptionalFormData("address", value) }} />
                    </div>
                    <div className="input-field">
                        <Input label='CONTACT PERSON' value={formData.contactPerson.value} type='text'
                            onChange={(value: string) => { handleOptionalFormData("contactPerson", value) }} />
                    </div>
                </div>
                <div className="footer">
                    <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={saveClinic} text={"Add Clinic"} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddClinic