import XIcons from "../../assets/images/X.svg";
import Modal from "../Modal/Modal";
import { Fragment, useEffect, useState } from "react";
import InputDropDown from "../InputDropDown/InputDropDown";
import DocumentButton from "../DocumentButton/DocumentButton";

import "./UserTypes.scss"

function UserTypes(props: {
    currentAccess: string[], onUpdate: CallableFunction, label?: boolean,
    showError: boolean, errorMessage: string
}) {

    const [openModal, setOpenModal] = useState(false);
    const [userAccess, setUserAccess] = useState([] as string[]);
    const [tempUserAccess, setTempUserAccess] = useState([] as string[]);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    const labels = [{ label: "Admin", value: "Admin" }, { label: "Scribe", value: "Scribe" },
    { label: "Proof", value: "Proof" }, { label: "Review", value: "Review" }];

    useEffect(() => {
        if (props?.currentAccess?.length > 0) {
            setUserAccess(props.currentAccess)
        }
    }, [props.currentAccess])

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    return (
        <div>
            {props.label ? "User Type*" : <Fragment />}
            <div className="tags-row-box">
                {userAccess?.map((data: any, index: number) => {
                    return <UserTag key={index} label={data} remove={() => {
                        let newUserAccessArr = userAccess?.filter((_access: string, accessIndex: number) => {
                            return index !== accessIndex
                        })
                        props.onUpdate(newUserAccessArr);
                        setUserAccess(newUserAccessArr);
                    }} />
                })}
                <div className="add-more" onClick={() => { setOpenModal(true); setTempUserAccess([...userAccess]); }}>
                    <span>+ More Access</span>
                </div>
            </div>

            <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={openModal} close={() => setOpenModal(false)}
                styles={"more-access-view"} >
                <div className="more-acces-container">
                    <div className="title">More Access</div>
                    <div className="content">
                        <InputDropDown type='dropdown' title={"STATUS"} values={labels}
                            onSelect={(value: any) => {
                                // if (tempUserAccess.length < 1) {
                                    let newUserAccess = tempUserAccess;
                                    if (value?.length > 0) {
                                        let selectedValue = value?.[0]?.value;
                                        if (!newUserAccess.includes(selectedValue) && selectedValue &&
                                            (labels.filter((e: any) => e.label === selectedValue).length > 0)) {
                                            newUserAccess.push(selectedValue);
                                            setTempUserAccess([...newUserAccess]);
                                        }
                                    }
                                // } 
                                // else {
                                //     setShowAlert({ isError: true, message: "Access is already defined.", open: true });
                                // }
                            }} showError={props.showError} />
                        <div className="tag-content">
                            {tempUserAccess?.map((data: any, index: number) => {
                                return <UserTag key={index} label={data} remove={() => {
                                    let newUserAccessArr = tempUserAccess?.filter((_access: string, accessIndex: number) => {
                                        return index !== accessIndex
                                    })
                                    setTempUserAccess([...newUserAccessArr]);
                                }} />
                            })}
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="button cancel-container" onClick={() => { setOpenModal(false) }}>Cancel</div>
                        <div className="button save-container"
                            onClick={() => {
                                setUserAccess([...tempUserAccess]); setOpenModal(false);
                                props.onUpdate([...tempUserAccess]);
                            }}>
                            <DocumentButton loading={false} onClick={() => {
                                setUserAccess([...tempUserAccess]); setOpenModal(false);
                                props.onUpdate([...tempUserAccess]);
                            }} text={"Give Access"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

function UserTag(props: { label: string; remove: CallableFunction }) {
    return (
        <div className="tag-body">
            {props.label}
            <img className="img" src={XIcons} alt={"Search"} onClick={() => props.remove()} />
        </div>
    );
}

export default UserTypes