import { useState, useEffect } from 'react'
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import { IClinic } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';

import "./EditClinic.scss"

function EditClinic(props: { open: boolean; close: CallableFunction; selectedClinic: IClinic; showAlert: CallableFunction }) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState({ clinicName: "", address: "", contactPerson: "" });
    const [formDataErrors, setFormDataErrors] = useState({ clinicNameError: { showError: false, message: "" } });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let newFormData = formData;
        newFormData.clinicName = props.selectedClinic.clinicName;
        newFormData.address = props.selectedClinic.address;
        newFormData.contactPerson = props.selectedClinic.contactPerson;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }, [props.selectedClinic]);

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Clinic", "edit", shouldUpdate);
    }

    function clearData() {
        let newFormData = formData;
        newFormData.clinicName = "";
        newFormData.address = "";
        newFormData.contactPerson = "";
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.clinicNameError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function update() {

        setLoading(true);

        if (formData.clinicName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicNameError.showError = true;
            newFormDataErrors.clinicNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        let data = Object.assign(formData, { id: props.selectedClinic.id });

        apiService.updateClinic(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.clinicName + " Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-edit-clinic-card"} >
            <div className="view-edit-clinic-container">
                <div className='title'>Edit Clinic</div>
                <div className="row">
                    <div className="input-field">
                        <Input label='CLINIC NAME*' value={formData.clinicName} type='text'
                            showError={formDataErrors.clinicNameError.showError}
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.clinicName = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">
                        <Input label='ADDRESS' value={formData.address} type='text'
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.address = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">

                        <Input label='CONTACT PERSON' value={formData.contactPerson} type='text'
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.contactPerson = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                </div>
                <div className="footer">
                    <span onClick={() => { handleClose(false); }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={() => { update(); }} text={"Update"} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditClinic