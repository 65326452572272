import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import AccordionIcon from "../../assets/images/AccordionOpenBlack.svg";
import MuiAlert from "../../components/Alert/Alert";
import DocumentButton from "../../components/DocumentButton/DocumentButton";
import { IEditAccess } from "../../helpers/Interfaces";
import ApiService from "../../services/ApiService";

export function EditAccessAccordion(props: {
    accordionStates: { Scribe: boolean; Proof: boolean; Review: boolean; Admin: boolean; Manager: boolean; User: boolean };
    setAccordionStates: CallableFunction;
    accordion: "Admin" | "Scribe" | "Review" | "Proof" | "Manager" | "User";
    label: string;
}) {

    const apiService = new ApiService();

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [serverPermissions, setServerPermissions] = useState([] as IEditAccess[]);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({
        reports: { create: false, read: false, update: false, delete: false },
        reportType: { create: false, read: false, update: false, delete: false },
        reportTemplate: { create: false, read: false, update: false, delete: false },
        appointment: { create: false, read: false, update: false, delete: false },
    });

    const onUpdate = () => {

        setLoading(true);

        let newServerPermissions = serverPermissions;

        newServerPermissions.forEach((serverPermission) => {

            if (serverPermission.page === "Reports") {
                serverPermission.operations.forEach((operation) => {
                    if (operation.operation === "Create") {
                        operation.permissionGranted = permissions.reports.create;
                    }
                    if (operation.operation === "Read") {
                        operation.permissionGranted = permissions.reports.read;
                    }
                    if (operation.operation === "Update") {
                        operation.permissionGranted = permissions.reports.update;
                    }
                    if (operation.operation === "Delete") {
                        operation.permissionGranted = permissions.reports.delete;
                    }
                });
            }

            if (serverPermission.page === "ReportTypes") {
                serverPermission.operations.forEach((operation) => {
                    if (operation.operation === "Create") {
                        operation.permissionGranted = permissions.reportType.create;
                    }
                    if (operation.operation === "Read") {
                        operation.permissionGranted = permissions.reportType.read;
                    }
                    if (operation.operation === "Update") {
                        operation.permissionGranted = permissions.reportType.update;
                    }
                    if (operation.operation === "Delete") {
                        operation.permissionGranted = permissions.reportType.delete;
                    }
                });
            }

            if (serverPermission.page === "ReportTemplates") {
                serverPermission.operations.forEach((operation) => {
                    if (operation.operation === "Create") {
                        operation.permissionGranted = permissions.reportTemplate.create;
                    }
                    if (operation.operation === "Read") {
                        operation.permissionGranted = permissions.reportTemplate.read;
                    }
                    if (operation.operation === "Update") {
                        operation.permissionGranted = permissions.reportTemplate.update;
                    }
                    if (operation.operation === "Delete") {
                        operation.permissionGranted = permissions.reportTemplate.delete;
                    }
                });
            }

            if (serverPermission.page === "Appointments") {
                serverPermission.operations.forEach((operation) => {
                    if (operation.operation === "Create") {
                        operation.permissionGranted = permissions.appointment.create;
                    }
                    if (operation.operation === "Read") {
                        operation.permissionGranted = permissions.appointment.read;
                    }
                    if (operation.operation === "Update") {
                        operation.permissionGranted = permissions.appointment.update;
                    }
                    if (operation.operation === "Delete") {
                        operation.permissionGranted = permissions.appointment.delete;
                    }
                });
            }
        })

        setServerPermissions(newServerPermissions);
        updateRolePermission();
    }

    const handleReportsUpdate = (data: { create: false, read: false, update: false, delete: false }) => {
        let newPermissions = permissions;
        newPermissions.reports = data;
        setPermissions(newPermissions);
    }

    const handleReportTypeUpdate = (data: { create: false, read: false, update: false, delete: false }) => {
        let newPermissions = permissions;
        newPermissions.reportType = data;
        setPermissions(newPermissions);
    }

    const handleAppointmentUpdate = (data: { create: false, read: false, update: false, delete: false }) => {
        let newPermissions = permissions;
        newPermissions.appointment = data;
        setPermissions(newPermissions);
    }

    const handleReportTemplateUpdate = (data: { create: false, read: false, update: false, delete: false }) => {
        let newPermissions = permissions;
        newPermissions.reportTemplate = data;
        setPermissions(newPermissions);
    }

    function updateRolePermission() {

        let data = {
            role: props.accordion,
            permissions: serverPermissions,
        };

        apiService.updateRolePermission(data).then((result: any) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                props.setAccordionStates({ scribe: false, proof: false, review: false, admin: false, manager: false, user: false });
                return;
            }

            props.setAccordionStates({ scribe: false, proof: false, review: false, admin: false, manager: false, user: false });

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = `${props.label} Permissions Updated`;
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            props.setAccordionStates({ scribe: false, proof: false, review: false, admin: false, manager: false, user: false });
        }).finally(() => {
            setLoading(false);
        });
    }

    function getRolePermission() {

        setLoading(true);

        let data = {
            role: props.accordion
        };

        apiService.getRolePermission(data).then((result: any) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                props.setAccordionStates({ scribe: false, proof: false, review: false, admin: false, manager: false, user: false });
                return;
            }

            setServerPermissions(data?.permissions)
            updatePermission(data?.permissions);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            props.setAccordionStates({ scribe: false, proof: false, review: false, admin: false, manager: false, user: false });
        }).finally(() => {
            setLoading(false);
        });
    }

    function updatePermission(permissionsArr: IEditAccess[]) {

        let nwePermissions = permissions;

        permissionsArr.forEach(permission => {
            if (permission.page === "Reports") {
                nwePermissions.reports = {
                    create: permission.operations[0].permissionGranted,
                    read: permission.operations[1].permissionGranted,
                    update: permission.operations[2].permissionGranted,
                    delete: permission.operations[3].permissionGranted,
                }
            }
            if (permission.page === "Appointments") {
                nwePermissions.appointment = {
                    create: permission.operations[0].permissionGranted,
                    read: permission.operations[1].permissionGranted,
                    update: permission.operations[2].permissionGranted,
                    delete: permission.operations[3].permissionGranted,
                }
            }
            if (permission.page === "ReportTemplates") {
                nwePermissions.reportTemplate = {
                    create: permission.operations[0].permissionGranted,
                    read: permission.operations[1].permissionGranted,
                    update: permission.operations[2].permissionGranted,
                    delete: permission.operations[3].permissionGranted,
                }
            }
            if (permission.page === "ReportTypes") {
                nwePermissions.reportType = {
                    create: permission.operations[0].permissionGranted,
                    read: permission.operations[1].permissionGranted,
                    update: permission.operations[2].permissionGranted,
                    delete: permission.operations[3].permissionGranted,
                }
            }
        });

        setPermissions((previousData: any) => ({ ...previousData, ...nwePermissions }));
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    return <Accordion expanded={props.accordionStates[`${props.accordion}`]} classes={{ root: "accordion-root" }}
        onChange={() => {
            getRolePermission();
            let newAccordionStates = props.accordionStates;
            newAccordionStates.Admin = false;
            newAccordionStates.Scribe = false;
            newAccordionStates.Review = false;
            newAccordionStates.Proof = false;
            newAccordionStates.Manager = false;
            newAccordionStates.User = false;
            newAccordionStates[`${props.accordion}`] = !props.accordionStates[`${props.accordion}`];
            props.setAccordionStates((prevDetails: any) => ({ ...prevDetails, ...newAccordionStates, }));
        }}>
        <AccordionSummary
            classes={{
                root: "accordion-summary-root",
                content: "accordion-summary-content",
                expandIconWrapper: "icon-transform",
            }}
            sx={{
                "MuiAccordionSummary-expandIconWrapper": {
                    transform: "rotate(0deg)",
                },
            }}
            expandIcon={<img className="img" src={AccordionIcon} alt={"Arrow"} />}>
            <span className="text">
                <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open} onClose={closeAlert} />
                {props.label}
            </span>
        </AccordionSummary>
        <AccordionDetails classes={{ root: "accordion-details-root" }}>
            <div className="header">
                <span>Reports</span>
                <div className="types-header">
                    <span>Yes</span>
                    <span>No</span>
                </div>
            </div>
            <div className="body">
                <Type label="Create" value={permissions.reports} onChange={(data: any) => handleReportsUpdate(data)} />
                <Type label="Read" value={permissions.reports} onChange={(data: any) => handleReportsUpdate(data)} />
                <Type label="Update" value={permissions.reports} onChange={(data: any) => handleReportsUpdate(data)} />
                <Type label="Delete" value={permissions.reports} onChange={(data: any) => handleReportsUpdate(data)} />
            </div>
            {props.accordion === "Manager" || props.accordion === "User"
                ? (<></>)
                : (<>
                    <div className="header">
                        <span>Report Type</span>
                    </div>
                    <div className="body">
                        <Type label="Create" value={permissions.reportType} onChange={(data: any) => handleReportTypeUpdate(data)} />
                        <Type label="Read" value={permissions.reportType} onChange={(data: any) => handleReportTypeUpdate(data)} />
                        <Type label="Update" value={permissions.reportType} onChange={(data: any) => handleReportTypeUpdate(data)} />
                        <Type label="Delete" value={permissions.reportType} onChange={(data: any) => handleReportTypeUpdate(data)} />
                    </div>
                    <div className="header">
                        <span>Report Template</span>
                    </div>
                    <div className="body">
                        <Type label="Create" value={permissions.reportTemplate}
                            onChange={(data: any) => handleReportTemplateUpdate(data)} />
                        <Type label="Read" value={permissions.reportTemplate}
                            onChange={(data: any) => handleReportTemplateUpdate(data)} />
                        <Type label="Update" value={permissions.reportTemplate}
                            onChange={(data: any) => handleReportTemplateUpdate(data)} />
                        <Type label="Delete" value={permissions.reportTemplate}
                            onChange={(data: any) => handleReportTemplateUpdate(data)} />
                    </div>
                </>)
            }
            {props.accordion === "Manager" || props.accordion === "User"
                ? (<>
                    <div className="header">
                        <span>Appointment</span>
                    </div>
                    <div className="body">
                        <Type label="Create" value={permissions.appointment} onChange={(data: any) => handleAppointmentUpdate(data)} />
                        <Type label="Read" value={permissions.appointment} onChange={(data: any) => handleAppointmentUpdate(data)} />
                        <Type label="Update" value={permissions.appointment} onChange={(data: any) => handleAppointmentUpdate(data)} />
                    </div>
                </>)
                : (<></>)
            }

            <div className="according-footer">
                <div className="cancel-button"
                    onClick={() => {
                        props.setAccordionStates({
                            scribe: false, proof: false, review: false, admin: false,
                            manager: false, user: false,
                        });
                    }}>
                    <label className="button-label">Cancel</label>
                </div>
                <div className="update-button">
                    <DocumentButton loading={loading} text={"Update"} onClick={() => onUpdate()} />
                </div>
            </div>
        </AccordionDetails>
    </Accordion>;
}

function Type(props: {
    label: string; onChange: CallableFunction; value: {
        create: boolean; read: boolean; update: boolean; delete: boolean
    };
}) {

    const [checked, setChecked] = useState({ yes: false, no: false });

    useEffect(() => {
        if (props.label === "Create") {
            setChecked({ yes: props.value.create, no: !props.value.create });
        } else if (props.label === "Read") {
            setChecked({ yes: props.value.read, no: !props.value.read });
        } else if (props.label === "Update") {
            setChecked({ yes: props.value.update, no: !props.value.update });
        } else if (props.label === "Delete") {
            setChecked({ yes: props.value.delete, no: !props.value.delete });
        }
    }, [props.value]);

    return (
        <div className="types">
            <span>{props.label}</span>
            <div className="boxes">
                <Checkbox checked={checked.yes}
                    sx={{
                        color: "#203FAD",
                        borderRadius: "6px",
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setChecked({ yes: event.target.checked, no: !event.target.checked });

                        let newValue = props.value;

                        if (props.label === "Create") {
                            newValue.create = event.target.checked;
                        } else if (props.label === "Read") {
                            newValue.read = event.target.checked;
                        } else if (props.label === "Update") {
                            newValue.update = event.target.checked;
                        } else if (props.label === "Delete") {
                            newValue.delete = event.target.checked;
                        }

                        props.onChange(newValue);
                    }} />
                <Checkbox checked={checked.no}
                    sx={{
                        color: "#203FAD",
                        borderRadius: "6px",
                        "&.Mui-checked": {
                            color: "#203FAD",
                            borderRadius: "6px",
                        },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setChecked({ yes: !event.target.checked, no: event.target.checked });

                        let newValue = props.value;

                        if (props.label === "Create") {
                            newValue.create = !event.target.checked;
                        } else if (props.label === "Read") {
                            newValue.read = !event.target.checked;
                        } else if (props.label === "Update") {
                            newValue.update = !event.target.checked;
                        } else if (props.label === "Delete") {
                            newValue.delete = !event.target.checked;
                        }

                        props.onChange(newValue);
                    }} />
            </div>
        </div>
    );
}