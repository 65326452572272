import { Fragment, useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import NewButton from "../../components/NewButton/NewButton";
import Tabs from "../../components/Tabs/Tabs";
import AddReportType from "../../components/AddReportType/AddReportType";
import ApiService from "../../services/ApiService";
import { IAlert, IReportTemplate, IReportType } from "../../helpers/Interfaces";
import ReportTypesUI from "./ReportTypesUI";
import ReportTemplatesUI from "./ReportTemplatesUI";
import EditReportType from "../../components/EditReportType/EditReportType";
import EditTemplateModal from "../../components/EditTemplateModal/EditTemplateModal";
import Show from "../../components/Show/Show";
import MuiAlert from "../../components/Alert/Alert";
import { ReportTemplateFilterTypes, TemplateFilterTypes } from "../../helpers/Types";
import DeletePrompt from "../../components/DeletePrompt/DeletePrompt";

import "./Templates.scss";

export default function Templates() {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [reportTypeModals, setReportTypeModals] = useState({ add: false, edit: false, delete: false });
    const [reportTemplateModals, setReportTemplateModals] = useState({ edit: false, delete: false });
    const [reportTypes, setReportTypes] = useState([] as IReportType[]);
    const [filteredReportTypes, setFilteredReportTypes] = useState([] as IReportType[]);
    const [selectedReportType, setSelectedReportType] = useState({} as IReportType);
    const [reportTemplates, setReportTemplates] = useState([] as IReportTemplate[]);
    const [filteredReportTemplates, setFilteredReportTemplates] = useState([] as IReportTemplate[]);
    const [selectedReportTemplate, setSelectedReportTemplate] = useState({} as IReportTemplate)
    const [showAlert, setShowAlert] = useState({} as IAlert);
    const [permission, setPermission] = useState([] as string[]);
    const [showCancelFilter, setShowCancelFilter] = useState(false);

    useEffect(() => {
        getReportTypes();
        getTemplates();
    }, [])

    useEffect(() => {
        if ((reportTypes.length !== filteredReportTypes.length) || (reportTemplates.length !== filteredReportTemplates.length)) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filteredReportTypes, filteredReportTemplates])

    useEffect(() => {
        getPermissions();
    }, [selectedTab])

    function resetDefault() {
        setFilteredReportTypes(reportTypes);
        setFilteredReportTemplates(reportTemplates);
    }

    const handleReportTypeModals = (open: boolean, modal: "add" | "edit" | "delete", shouldUpdate: boolean = false) => {
        if (open) {
            let newReportTypeModals = reportTypeModals;
            newReportTypeModals[modal] = true;
            setReportTypeModals((prevDetails) => ({ ...prevDetails, ...newReportTypeModals }));
        } else {
            let newReportTypeModals = reportTypeModals;
            newReportTypeModals[modal] = false;
            setReportTypeModals((prevDetails) => ({ ...prevDetails, ...newReportTypeModals }));

            if (shouldUpdate) {
                getReportTypes();
            }
        }
    }

    const handleReportTemplateModals = (open: boolean, modal: "edit" | "delete", shouldUpdate: boolean = false) => {
        if (open) {
            let newReportTemplateModals = reportTemplateModals;
            newReportTemplateModals[modal] = true;
            setReportTemplateModals(newReportTemplateModals);
        } else {
            let newReportTemplateModals = reportTemplateModals;
            newReportTemplateModals[modal] = false;
            setReportTemplateModals((prevDetails) => ({ ...prevDetails, ...newReportTemplateModals }));

            if (shouldUpdate) {
                getTemplates();
            }
        }
    }

    function filterReportTypes(filterBy: ReportTemplateFilterTypes, query: string) {
        let filteredList = filteredReportTypes.filter((report) => {
            return report[filterBy] === query
        })

        setFilteredReportTypes(filteredList);
    }

    function filterReportTemplates(filterBy: TemplateFilterTypes, query: string) {
        let filteredList = filteredReportTemplates.filter((report) => {
            return report[filterBy] === query
        })

        setFilteredReportTemplates(filteredList);
    }

    function getPermissions() {

        setLoading(true);

        let data = {
            page: selectedTab === 0 ? "ReportTypes" : "ReportTemplates",
        };

        apiService.getPermission(data).then((result: any) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setPermission(data?.actions);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
        }).finally(() => {
            setLoading(false);
        });
    }

    function getTemplates() {

        setLoading(true);

        apiService.getTemplates().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setReportTemplates(data?.templates);
            setFilteredReportTemplates(data?.templates);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getReportTypes() {

        setLoading(true);

        apiService.getReportTypes().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setReportTypes(data?.reportTypes);
            setFilteredReportTypes(data?.reportTypes);

        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function deleteReportTemplate() {

        setLoading(true);

        let data = {
            templateId: selectedReportTemplate.id
        }

        apiService.deleteTemplate(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedReportTemplate.reportName + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleReportTemplateModals(false, "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function deleteReportType() {

        setLoading(true);

        let data = {
            reportTypeId: selectedReportType.id
        }

        apiService.deleteReportType(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedReportType.reportName + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleReportTemplateModals(false, "delete", true);
            handleReportTypeModals(false, "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (<Layout selectedIndex={4}>
        <div className="templates-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert, }));
                }} />
            <div className="top-bar">
                <div className="row">
                    <span className="text">Templates</span>
                    {/* <Search /> */}
                </div>
            </div>
            <div className="template-table-container">
                
                <div className="row tab-selector">
                    <Tabs titles={["Report Type", "Report Templates"]} selectedIndex={selectedTab} onClick={setSelectedTab} />
                    <Show hasAccess={permission?.includes("Create")}>
                        <>
                            {selectedTab === 0
                                ? (<NewButton text="Add Report Type" onClick={() => { handleReportTypeModals(true, "add") }} />)
                                : (<Fragment />)
                            }
                        </>
                    </Show>
                </div>

                <Show hasAccess={permission?.includes("Read")}>
                    <>
                        {selectedTab === 0
                            ? (<ReportTypesUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reportTypes={filteredReportTypes} setSelectedReportType={setSelectedReportType}
                                handleModals={handleReportTypeModals} filter={filterReportTypes} canEdit={permission?.includes("Update")}
                                canDelete={permission?.includes("Delete")} />)
                            : (<Fragment />)
                        }
                        {selectedTab === 1
                            ? (<ReportTemplatesUI cancelFilter={showCancelFilter} resetDefault={resetDefault}
                                reportTemplates={filteredReportTemplates} setSelectedReportTemplate={setSelectedReportTemplate}
                                handleModals={handleReportTemplateModals} filter={filterReportTemplates}
                                canEdit={permission?.includes("Update")} canDelete={permission?.includes("Delete")} />)
                            : (<Fragment />)
                        }
                    </>
                </Show>

                {reportTypeModals.add
                    ? (<AddReportType open={reportTypeModals.add} handleModals={handleReportTypeModals} showAlert={setShowAlert} />)
                    : (<Fragment />)
                }

                {reportTypeModals.edit
                    ? (<EditReportType open={reportTypeModals.edit} handleModals={handleReportTypeModals}
                        selectedReportType={selectedReportType} showAlert={setShowAlert} />)
                    : (<Fragment />)
                }
                {reportTemplateModals.edit
                    ? (<EditTemplateModal open={reportTemplateModals.edit} handleModals={handleReportTemplateModals}
                        selectedTemplate={selectedReportTemplate} reportTypes={reportTypes} showAlert={setShowAlert} />)
                    : (<Fragment />)
                }

                {reportTypeModals.delete
                    ? (<DeletePrompt loading={loading} close={() => handleReportTypeModals(false, "delete", false)}
                        open={reportTypeModals.delete} header={"Delete " + selectedReportType.reportName + "?"} onDelete={deleteReportType}
                        body={"This will result in the removal of all associated report templates. Please note that this action " +
                            "is irreversible. Are you certain you wish to proceed with this operation?"} />)
                    : <Fragment />
                }
                {reportTemplateModals.delete
                    ? (<DeletePrompt loading={loading} close={() => handleReportTemplateModals(false, "delete", false)}
                        open={reportTemplateModals.delete} header={"Delete " + selectedReportTemplate.reportName + "?"}
                        body={"Please note that this action is irreversible. Are you certain you wish to proceed with this operation?"}
                        onDelete={deleteReportTemplate} />)
                    : <Fragment />
                }
            </div>
        </div>
    </Layout>)
}