import { useState, useEffect, Fragment } from 'react'
import { validStringField } from '../../helpers/HelperFunctions';
import { IClinic, IDoctor, IUser } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import Modal from '../Modal/Modal';

import "./ViewDoctor.scss"

function ViewDoctor(props: { open: boolean, close: CallableFunction, selectedDoctor: IDoctor, openEdit: CallableFunction }) {

    const apiService = new ApiService();

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([] as IClinic[]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [scribers, setScribers] = useState([] as IUser[]);

    useEffect(() => {
        if (props.selectedDoctor.id) {
            getClinics();
            getScriber();
        }
    }, [props.selectedDoctor.id])

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: props.selectedDoctor.id
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setClinics(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getScriber() {

        setLoading(true);

        let data = {
            doctorId: props.selectedDoctor.id
        }

        apiService.getScribersByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            setScribers(data?.scribers);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleClose = (shouldUpdate: boolean) => {
        props.close("Doctor", "view", shouldUpdate);
    }

    const handleOpenEdit = () => {
        props.openEdit("Doctor", "edit");
        handleClose(false);
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={true} openEdit={handleOpenEdit}
            open={props.open} close={handleClose} styles={"view-view-doctor-card"} >
            <div className="view-doctor-container">
                <div className='title'>View Doctor</div>
                <div className="content">

                    <div className="row">
                        <div className="data">
                            <span className="header">{"First Name"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.firstName)}</span>
                        </div>
                        <div className="data">
                            <span className="header">{"Last Name"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.lastName)}</span>
                        </div>

                        <div className="data">
                            <span className="header">{"Email Address"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.email)}</span>
                        </div>
                        <div className="data">
                            <span className="header">{"Address"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.address)}</span>
                        </div>

                        <div className="data">
                            <span className="header">{"Telephone Number"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.phoneNumber)}</span>
                        </div>
                        <div className="data">
                            <span className="header">{"ABN"}</span>
                            <span className="body">{validStringField(props.selectedDoctor.abn)}</span>
                        </div>
                    </div>
                    <div className="view-data">
                        <div className="row-view">
                            <span onClick={() => setSelectedTab(0)} className={`header ${selectedTab === 0 ? "selected-header" : ""}`}>
                                Clinics
                            </span>
                            <span onClick={() => setSelectedTab(1)} className={`header ${selectedTab === 1 ? "selected-header" : ""}`}>
                                Scribers
                            </span>
                        </div>
                        {selectedTab === 0
                            ? (<div className="details">
                                {clinics.map((clinic) => {
                                    return (
                                        <div key={clinic.id} className="data">
                                            <span className="name">{validStringField(clinic.clinicName)}</span>
                                        </div>
                                    );
                                })}
                            </div>)
                            : (<Fragment />)
                        }
                        {selectedTab === 1
                            ? (<div className="details">
                                {scribers.map((scriber) => {
                                    return (
                                        <div key={scriber.email} className="data">
                                            <span className="name">{validStringField(scriber.firstName)}</span>
                                            <span className="name">{validStringField(scriber.lastName)}</span>
                                        </div>
                                    );
                                })}
                            </div>)
                            : (<Fragment />)
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewDoctor