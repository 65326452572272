import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout'
import Tabs from '../../components/Tabs/Tabs'
import ApiService from '../../services/ApiService';
import { IDoctor, IFile } from '../../helpers/Interfaces';
import FileUploadUI from '../../components/FileUploadUI/FileUploadUI';
import { addDoctorDocuments, getDoctorDocuments, removeMedia } from '../../helpers/FirebaseFunctions';
import Loading from '../../components/Loading/Loading';
import MuiAlert from '../../components/Alert/Alert';
import './Domain.scss'
import { User } from '../../store/Store';
import { isInRole } from '../../helpers/HelperFunctions';


function Domain() {
    const apiService = new ApiService();
    const [user] = useContext(User);

    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingInternal, setLoadingInternal] = useState(false);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [doctorData, setDoctorData] = useState([{ name: 'General', id: '', media: [] }] as { name: string, id: string, media: IFile[] }[]);
    const [addedFiles, setAddedFiles] = useState([] as IFile[]);
    const [tabs, setTabs] = useState([] as string[]);

    useEffect(() => {
        getDoctors();
    }, [])

    function getDoctors() {
        setLoading(true);
        apiService.getDoctors().then(async (result) => {

            let data = result.data;
            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            await loadData(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const loadData = async (doctors: IDoctor[]) => {
        const media = await getDoctorData('qsadHZ6v4F0qMOdKM2xu');
        const tabs = ['General'];
        const doctorData = [{ name: 'General', id: 'qsadHZ6v4F0qMOdKM2xu', media: media }];
        for (let doctor of doctors) {
            const media = await getDoctorData(doctor.id);
            doctorData.push({ id: doctor.id, name: `${doctor.firstName} ${doctor.lastName}`, media: media });
            tabs.push(`${doctor.firstName} ${doctor.lastName}`);
        }
        setDoctorData(doctorData);
        setTabs(tabs);
    }

    const getDoctorData = async (id: string): Promise<IFile[]> => {
        const response = await getDoctorDocuments(id);
        if (response) {
            return response.media;
        }
        return [];
    }

    const handleSelectTab = (selectedTab: number) => setSelectedTab(selectedTab);

    const setDoctorMedia = async (media: IFile[]) => {
        let newDoctorData = doctorData;
        newDoctorData[selectedTab].media = media;
        await addDoctorDocuments(newDoctorData[selectedTab].id, media);
        setDoctorData((previousData) => ({ ...previousData, ...newDoctorData }));
    }

    const removeDoctorMedia = async (media: IFile) => {
        const selectedDoctor = doctorData[selectedTab];
        await removeMedia(media.fileId, 'doctors');
        let newDoctorData = doctorData;
        let filtered = newDoctorData[selectedTab].media.filter((_file) => (_file.fileId !== media.fileId));
        await addDoctorDocuments(selectedDoctor.id, filtered);
        newDoctorData[selectedTab].media = filtered;
        setDoctorData((previousData) => ({ ...previousData, ...newDoctorData }));
    }

    return (<Layout selectedIndex={6}>
        {loading
            ? (<div className='domain-container-loader'>
                <Loading color={'black'} />
            </div>)
            : (<div className='domain-container'>
                <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                    onClose={() => {
                        let newShowAlert = showAlert;
                        newShowAlert.open = false;
                        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                    }} />
                <Tabs titles={tabs} selectedIndex={selectedTab} onClick={handleSelectTab} />
                <FileUploadUI storageBucket='doctors' loading={loadingInternal} setLoading={setLoadingInternal} setMedia={setDoctorMedia}
                    media={doctorData[selectedTab].media ?? []} setShowAlert={setShowAlert} removeFile={removeDoctorMedia} setAddedFiles={setAddedFiles}
                    addedFiles={addedFiles} fileUploadAccess={isInRole(user?.roles, "Admin")} deleteAccess={isInRole(user?.roles, "Admin")} />
            </div>)}

    </Layout>)
}

export default Domain