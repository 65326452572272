import Input from '../../components/Input/Input';
import DocumentButton from '../../components/DocumentButton/DocumentButton';

type LoginContentProps = {
    readonly loginFormErrors: {
        emailError: { showError: boolean, message: string },
        passwordError: { showError: boolean, message: string },
    }
    readonly loginForm: { email: string, password: string },
    readonly setLoginForm: CallableFunction,
    readonly loading: boolean,
    readonly login: CallableFunction,
    readonly resetPassword: CallableFunction
}
function LoginForm({ loading, loginForm, loginFormErrors, setLoginForm, login, resetPassword }: LoginContentProps) {

    return (
        <>
            <div className="inputs">
                <Input label='EMAIL ADDRESS' value={loginForm.email} type='text'
                    showError={loginFormErrors.emailError.showError}
                    onChange={(value: string) => {
                        let newLoginForm = loginForm;
                        newLoginForm.email = value;
                        setLoginForm((prevDetails: any) => ({ ...prevDetails, ...newLoginForm }));
                    }} />
            </div>
            <div className="inputs">
                <Input label='PASSWORD' value={loginForm.password} type='password'
                    showError={loginFormErrors.passwordError.showError}
                    onChange={(value: string) => {
                        let newLoginForm = loginForm;
                        newLoginForm.password = value;
                        setLoginForm((prevDetails: any) => ({ ...prevDetails, ...newLoginForm }));
                    }} />
            </div >
            <div className='forget-password-container'>
                <label className='forget-password' onClick={() => { resetPassword(); }}>Forgot Password?</label>
            </div>
            {/* <div className="footer">
            </div> */}
            <div className="button-content">
                <DocumentButton text='Login' loading={loading} onClick={login} />
            </div>
        </>
    );
}

export default LoginForm