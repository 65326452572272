import { useState, useEffect } from 'react'
import { emailValidation } from '../../helpers/HelperFunctions';
import { IClinic, IDoctor, ITextInput } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import InputDropDown from '../InputDropDown/InputDropDown';
import Modal from '../Modal/Modal';

import "./AddStaffMember.scss"

function AddStaffMember(props: { open: boolean; close: CallableFunction; doctors: IDoctor[]; showAlert: CallableFunction }) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState({
        firstName: "", lastName: "", email: "", phoneNumber: "", doctorId: "", clinicId: ""
    });
    const [formDataErrors, setFormDataErrors] = useState({
        firstNameError: { showError: false, message: "" }, lastNameError: { showError: false, message: "" },
        emailError: { showError: false, message: "" }, phoneNumberError: { showError: false, message: "" },
        doctorIdError: { showError: false, message: "" }, clinicIdError: { showError: false, message: "" }
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);

    useEffect(() => {
        getDoctorTextInput(props.doctors);
    }, [props.doctors])

    useEffect(() => {
        if (formData.doctorId.trim() !== "") {
            getClinics();
        }
    }, [formData.doctorId])

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Support Staff", "add", shouldUpdate);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinicTextInput(clinics: IClinic[]) {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function getDoctorTextInput(doctors: IDoctor[]) {

        setLoading(true);

        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

        setLoading(false);
    }

    function clearData() {
        let newFormData = formData;
        newFormData.firstName = "";
        newFormData.lastName = "";
        newFormData.email = "";
        newFormData.phoneNumber = "";
        newFormData.doctorId = "";
        newFormData.clinicId = "";
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.firstNameError.showError = false;
        newFormDataErrors.lastNameError.showError = false;
        newFormDataErrors.emailError.showError = false;
        newFormDataErrors.phoneNumberError.showError = false;
        newFormDataErrors.doctorIdError.showError = false;
        newFormDataErrors.clinicIdError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    function saveStaffMember() {

        setLoading(true);

        if (formData.firstName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = true;
            newFormDataErrors.firstNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.lastName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = true;
            newFormDataErrors.lastNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.email?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = true;
            newFormDataErrors.emailError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        // if (formData.phoneNumber?.trim() === "") {
        //     let newFormDataErrors = formDataErrors;
        //     newFormDataErrors.phoneNumberError.showError = true;
        //     newFormDataErrors.phoneNumberError.message = "REQUIRED";
        //     setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        //     setLoading(false);
        //     return;
        // } else {
        //     let newFormDataErrors = formDataErrors;
        //     newFormDataErrors.phoneNumberError.showError = false;
        //     setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        // }

        if (formData.doctorId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = true;
            newFormDataErrors.doctorIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.clinicId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicIdError.showError = true;
            newFormDataErrors.clinicIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.clinicIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        apiService.addStaffMember(formData).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.firstName + " " + formData.lastName + " Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"view-add-staff-mem-card"} >
            <div className="add-staff-mem-container">
                <div className='title'>Add Staff Member</div>
                <div className="row">
                    <div className="input-field">
                        <Input label='FIRST NAME*' value={formData.firstName} type='text' showError={formDataErrors.firstNameError.showError}
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.firstName = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">
                        <Input label='LAST NAME*' value={formData.lastName} type='text' showError={formDataErrors.lastNameError.showError}
                            onChange={(value: any) => {
                                var newFormData = formData;
                                newFormData.lastName = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">
                        <Input label='EMAIL ADDRESS*' value={formData.email} type='text' showError={formDataErrors.emailError.showError}
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.email = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">
                        <Input label='TELEPHONE NUMBER' value={formData.phoneNumber} type='text'
                            showError={false}
                            onChange={(value: any) => {
                                let newFormData = formData;
                                newFormData.phoneNumber = value;
                                setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                            }} />
                    </div>
                    <div className="input-field">
                        <InputDropDown title='ASSIGN DOCTOR*' values={doctors} type='search'
                            showError={formDataErrors.doctorIdError.showError}
                            onSelect={(value: any) => {
                                if (value) {
                                    let newFormData = formData;
                                    newFormData.doctorId = value[0].value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }
                            }} />
                    </div>
                    <div className="input-field">
                        <InputDropDown title='ASSIGN CLINIC*' values={clinics} type='search'
                            showError={formDataErrors.clinicIdError.showError}
                            onSelect={(value: any) => {
                                if (value) {
                                    let newFormData = formData;
                                    newFormData.clinicId = value[0].value;
                                    setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                                }
                            }} />
                    </div>
                </div>
                <div className="footer">
                    <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                    <div className="button">
                        <DocumentButton loading={loading} onClick={saveStaffMember} text={"Add Staff"} />
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default AddStaffMember