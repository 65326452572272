import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from './pages/Home/Home';
import Reports from './pages/Reports/Reports';
import Doctors from './pages/Doctors/Doctors';
import Users from './pages/Users/Users';
import Templates from './pages/Templates/Templates';
import Analytics from './pages/Analytics/Analytics';
import Settings from './pages/Settings/Settings';
import Notifications from './pages/Notifications/Notifications';
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ErrorUI from "./pages/Error/Error";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import { getCurrentDimension } from "./helpers/HelperFunctions";
import Domain from "./pages/Domain/Domain";

import './App.css';
import './responsive.scss';

function App() {

  let screenWidth = getCurrentDimension().width

  const AppRoutes = () => {
    let routes = useRoutes([
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> },
      { path: "/forget-password", element: <ForgetPassword /> },
      { path: "/reset-password", element: <ResetPassword /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/dashboard/home", element: screenWidth > 425 ? <Home /> : <Reports /> },
      { path: "/dashboard/reports/:id?", element: <Reports /> },
      { path: "/dashboard/doctors", element: <Doctors /> },
      { path: "/dashboard/users", element: <Users /> },
      { path: "/dashboard/templates", element: <Templates /> },
      { path: "/dashboard/analytics", element: screenWidth > 768 ? <Analytics /> : <ErrorUI /> },
      { path: "/dashboard/settings", element: <Settings /> },
      { path: "/dashboard/notifications", element: <Notifications /> },
      { path: "/dashboard/domain", element: <Domain /> },
      { path: "*", element: <ErrorUI /> }
    ]);

    return routes;
  };

  return (
    <div className="App">
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;
