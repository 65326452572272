import { ISupportStaff } from "../../helpers/Interfaces";
import ArrowRightIcon from "../../assets/images/ArrowRight.svg";
import Trash from "../../assets/images/Trash.svg";
import { validStringField } from "../../helpers/HelperFunctions";
import { useCallback, useState } from "react";
import { StaffFilterTypes } from "../../helpers/Types";
import SearchableDown from "../../components/DropDown/DropDown";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";

type SupportStaffUIProps = {
    staff: ISupportStaff[];
    setSelectedSupportStaff: CallableFunction;
    openModal: (type: "Clinic" | "Doctor" | "Support Staff", modal: "add" | "edit" | "view" | "delete") => void;
    filter: (filterBy: StaffFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: CallableFunction;
}

export default function SupportStaffUI(props: SupportStaffUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<StaffFilterTypes>("firstName");

    const handleClick = useCallback((supportStaff: ISupportStaff) => {
        props.setSelectedSupportStaff(supportStaff);
        props.openModal("Support Staff", "view");
    }, [])

    const handleDelete = useCallback((supportStaff: ISupportStaff) => {
        props.setSelectedSupportStaff(supportStaff);
        props.openModal("Support Staff", "delete")
    }, [])

    const handleSearchOpen = (filterBy: StaffFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.staff.forEach(supportStaff => {
            if (supportStaff[selectedFilter] != null) {
                valueArr.push(supportStaff[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table">
        <div className="header">
            <div className="header-row">
                <span className="text">Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("firstName") }} />
            </div>
            <div className="header-row">
                <span className="text">Email</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("email") }} />
            </div>
            <div className="header-row">
                <span className="text">Phone No</span>
            </div>
            <div className="header-row">
                <span className="text">Doctor</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("assignedDoctor") }} />
            </div>
            <div className="header-row">
                <span className="text">Clinic</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("assignedClinic") }} />
                <div className="filter-content">
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                        : <div className="filter-icon" />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.staff?.map((supportStaff) => {
                return (<div key={supportStaff.id} className="data-row">
                    <div onClick={() => { props.setSelectedSupportStaff(supportStaff) }} className="cell text cursor">
                        {validStringField(supportStaff.firstName + " " + supportStaff.lastName)}
                    </div>
                    <div className="cell text">{validStringField(supportStaff.email)}</div>
                    <div className="cell text">{validStringField(supportStaff.phoneNumber)}</div>
                    <div className="cell text">{validStringField(supportStaff.assignedDoctor)}</div>
                    <div className="cell text">
                        {validStringField(supportStaff.assignedClinic)}
                        <div className="icon-container">
                            <img className="icon" src={ArrowRightIcon} alt={"ArrowRight"} onClick={() => { handleClick(supportStaff) }} />
                            <img className="icon space" src={Trash} alt={"Delete"} onClick={() => { handleDelete(supportStaff) }} />
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}