/* eslint-disable react/style-prop-object */
import Modal from "../Modal/Modal";
import FileUpload from "../FileUpload/FileUpload";
import Input from "../Input/Input";
import { useState, useEffect } from "react";
import InputDropDown from "../InputDropDown/InputDropDown";
import UserTypes from "../UserTypes/UserTypes";
import ApiService from "../../services/ApiService";
import { addMedia } from "../../helpers/FirebaseFunctions";
import DocumentButton from "../DocumentButton/DocumentButton";
import { IUser } from "../../helpers/Interfaces";

import "./EditUserModal.scss"

function EditUserModal(props: { open: boolean; close: CallableFunction; selectedUser: IUser; showAlert: CallableFunction }) {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        profileImage: "", firstName: "", lastName: "", email: "", newEmail: "", status: false, userType: [] as string[]
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [formDataErrors, setFormDataErrors] = useState({
        firstNameError: { showError: false, message: "" }, lastNameError: { showError: false, message: "" },
        emailError: { showError: false, message: "" }, statusError: { showError: false, message: "" },
        userTypeError: { showError: false, message: "" }
    });

    useEffect(() => {
        loadData();
    }, [props.selectedUser])

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("edit", shouldUpdate);
    }

    function loadData() {
        let newFormData = formData;
        newFormData.profileImage = props.selectedUser.profileImageUrl;
        newFormData.firstName = props.selectedUser.firstName;
        newFormData.lastName = props.selectedUser.lastName;
        newFormData.email = props.selectedUser.email;
        newFormData.newEmail = props.selectedUser.email;
        newFormData.status = props.selectedUser.status;
        newFormData.userType = props.selectedUser.roles;
        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
    }

    function clearData() {
        let newFormData = formData;
        newFormData.profileImage = "";
        newFormData.firstName = "";
        newFormData.lastName = "";
        newFormData.email = "";
        newFormData.status = false;
        newFormData.userType = [];
        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.firstNameError.showError = false;
        newFormDataErrors.lastNameError.showError = false;
        newFormDataErrors.emailError.showError = false;
        newFormDataErrors.statusError.showError = false;
        newFormDataErrors.userTypeError.showError = false;
        setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    function updateUser() {

        setLoading(true);

        if (formData.firstName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = true;
            newFormDataErrors.firstNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.firstNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.lastName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = true;
            newFormDataErrors.lastNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.lastNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.email?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = true;
            newFormDataErrors.emailError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.userType?.length === 0) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "SELECT USER ACCESS LEVEL";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

            let newFormDataErrors = formDataErrors;
            newFormDataErrors.userTypeError.showError = true;
            newFormDataErrors.userTypeError.message = "SELECT USER ACCESS LEVEL";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.userTypeError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

            let newShowAlert = showAlert;
            newShowAlert.open = false;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }

        let data = {
            profileImageUrl: formData.profileImage,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            newEmail: formData.newEmail,
            status: formData.status,
            roles: formData.userType
        }

        apiService.updateUser(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.firstName + " " + formData.lastName + " Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} open={props.open}
            close={handleClose} editable={false} styles={"edit-user-card"}>
            <div className="add-user-container">
                <span className="title">Edit User</span>
                <div className="file-uploader">
                    <FileUpload heading={"Upload Your Image"}
                        subHeading={" You can “drag & drop files” here or click to below link (JPEG, PNG)"} label={"Import Image File"}
                        showUploadedImage={true} acceptedFileTypes={['.png', ".jpeg", ".jpg"]}
                        defaultImage={formData.profileImage}
                        onChange={async (values: { url: string; base64Url: any; file: any; duration: number; }[]) => {
                            await addMedia(values[0].base64Url, values[0].file.name).then((link) => {
                                let newFormData = formData;
                                newFormData.profileImage = link;
                                setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                            });
                        }} />
                </div>
                <div className="add-user-content">
                    <div className="form-content">
                        <div className="row">
                            <div className="input-field">
                                <Input label="FIRST NAME*" value={formData.firstName}
                                    showError={formDataErrors.firstNameError.showError}
                                    type={"text"}
                                    onChange={(value: string) => {
                                        let newFormData = formData;
                                        newFormData.firstName = value;
                                        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label="LAST NAME*" value={formData.lastName}
                                    showError={formDataErrors.lastNameError.showError}
                                    type={"text"}
                                    onChange={(value: string) => {
                                        let newFormData = formData;
                                        newFormData.lastName = value;
                                        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <Input label="EMAIL ADDRESS*" value={formData.newEmail} type={"text"}
                                    showError={formDataErrors.emailError.showError}
                                    onChange={(value: string) => {
                                        let newFormData = formData;
                                        newFormData.newEmail = value;
                                        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                                    }} />
                            </div>
                            <div className="input-field">
                                <InputDropDown type='dropdown' title={"STATUS*"} defaultValue={formData.status}
                                    values={[{ label: 'Activate', value: true }, { label: 'Deactivate', value: false }]}
                                    onSelect={(value: any) => {
                                        if (value?.length > 0) {
                                            let newFormData = formData;
                                            newFormData.status = value?.[0].value;
                                            setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                                        }
                                    }} showError={formDataErrors.statusError.showError}
                                />
                            </div>
                        </div>
                        <div className="add-type-content">
                            <span className="title">USER TYPE*</span>
                            <div className="content">
                                <UserTypes currentAccess={formData.userType}
                                    showError={formDataErrors.userTypeError.showError}
                                    onUpdate={(value: string[]) => {
                                        let newFormData = formData;
                                        newFormData.userType = value;
                                        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                                    }} errorMessage={formDataErrors.userTypeError.message} />
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="cancel-container" onClick={() => { handleClose(false); }}>Cancel</div>
                        <div className="button">
                            <DocumentButton text="Update" loading={loading} onClick={() => { updateUser(); }} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default EditUserModal