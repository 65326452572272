import { IClinic } from "../../helpers/Interfaces";
import ArrowRightIcon from "../../assets/images/ArrowRight.svg";
import Trash from "../../assets/images/Trash.svg";
import { validStringField } from "../../helpers/HelperFunctions";
import { Fragment, useCallback, useState } from "react";
import { ClinicFilterTypes } from "../../helpers/Types";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import SearchableDown from "../../components/DropDown/DropDown";

type ClinicsUIProps = {
    clinics: IClinic[];
    setSelectedClinic: CallableFunction;
    openModal: (type: "Clinic" | "Doctor" | "Support Staff", modal: "add" | "edit" | "view" | "delete") => void;
    filter: (filterBy: ClinicFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: CallableFunction;
}

export default function ClinicsUI(props: ClinicsUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<ClinicFilterTypes>("clinicName");

    const handleView = useCallback((clinic: IClinic) => {
        props.setSelectedClinic(clinic);
        props.openModal("Clinic", "view");
    }, [])

    const handleDelete = useCallback((clinic: IClinic) => {
        props.setSelectedClinic(clinic);
        props.openModal("Clinic", "delete");
    }, [])

    const handleSearchOpen = (filterBy: ClinicFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.clinics.forEach(clinic => {
            if (clinic[selectedFilter] != null) {
                valueArr.push(clinic[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table">
        <div className="header">
            <div className="header-row">
                <span className="text">Clinic Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("clinicName") }} />
            </div>
            <div className="header-row">
                <span className="text">Address</span>
            </div>
            <div className="header-row">
                <span className="text">Contact Person</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("contactPerson") }} />
                <div className="filter-content">
                    <div className="size-box" />
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"Remove Filter"} onClick={handleClearFilter} />
                        : <Fragment />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.clinics?.map((clinic) => {
                return (<div key={clinic.id} className="data-row">
                    <div className="cell text">{validStringField(clinic.clinicName)}</div>
                    <div className="cell text">{validStringField(clinic.address)}</div>
                    <div className="cell text">
                        {validStringField(clinic.contactPerson)}
                        <div className="icon-container">
                            <img className="icon" src={ArrowRightIcon} alt={"ArrowRight"} onClick={() => { handleView(clinic) }} />
                            <img className="icon space" src={Trash} alt={"Delete"} onClick={() => { handleDelete(clinic) }} />
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}