import Modal from '../Modal/Modal';
import { useState, useEffect, SetStateAction, Dispatch, Fragment } from 'react'
import Input from '../Input/Input';
import DocumentButton from '../DocumentButton/DocumentButton';
import InputDropDown from '../InputDropDown/InputDropDown';
import { IAlert, IClinic, IDoctor, IEditReportTemplate, IReportData, IReportTemplate, IReportType, ITemplateData, ITextInput } from '../../helpers/Interfaces';
import { getTemplate } from '../../helpers/FirebaseFunctions';
import ApiService from '../../services/ApiService';

import './EditTemplateModal.scss'

type EditTemplateModalProps = {
    open: boolean;
    handleModals: (open: boolean, modal: "edit" | "delete", shouldUpdate: boolean) => void;
    selectedTemplate: IReportTemplate;
    reportTypes: IReportType[];
    showAlert: Dispatch<SetStateAction<IAlert>>;
}

function EditTemplateModal(props: EditTemplateModalProps) {

    const apiService = new ApiService();

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [formData, setFormData] = useState({
        reportTypeId: "", doctorId: "", clinicId: "", shortName: "", firebaseId: "", providerNumber: ""
    });
    const [formDataErrors, setFormDataErrors] = useState({
        reportTypeIdError: { showError: false, message: "" }, shortNameError: { showError: false, message: "" },
    });
    const [loading, setLoading] = useState(false);
    const [reportTypes, setReportTypes] = useState([] as ITextInput[]);
    const [templateContent, setTemplateContent] = useState({} as ITemplateData);
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [tempDoctors, setTempDoctors] = useState([] as IDoctor[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);

    useEffect(() => {
        if (props.reportTypes.length > 0) {
            getReportTypeTextInput(props.reportTypes);
        }
    }, [props.reportTypes])

    useEffect(() => {
        if (props.selectedTemplate) {
            getDoctors();
            getTemplateById();
        }
    }, [props.selectedTemplate])

    
    useEffect(() => {
        console.log(templateContent);
    }, [templateContent])

    useEffect(() => {
        if (formData?.doctorId !== "" && tempDoctors.length !== 0) {
            getClinics();
            getReportTypes();
        }
        if (formData.doctorId?.trim() !== "" && formData.clinicId !== "") {
            getReportTypes();
        }
    }, [formData?.doctorId, formData?.clinicId])


    async function getDoctors() {
        setLoading(true);
        apiService.getDoctors().then((result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }
            setTempDoctors(data?.doctors)
            getDoctorTextInput(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    async function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData?.doctorId
        }
        apiService.getClinicsByDoctorId(data).then((result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch((e) => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            return;
        }).finally(() => {
            setLoading(false);
        });
    }

    function getReportTypes() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId,
            clinicId: formData.clinicId
        }

        apiService.getReportTypesByDoctorIdOrClinicId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getReportTypeTextInput(data?.reportTypes);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    async function getTemplateById() {

        setLoading(true);

        let data = {
            templateId: props.selectedTemplate.id
        }

        apiService.getTemplateById(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            loadData(data?.template);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    async function loadData(template: IEditReportTemplate) {
        let newFormData = formData;
        newFormData.reportTypeId = template.reportTypeId;
        newFormData.clinicId = template.clinicId;
        newFormData.doctorId = template.doctorId;
        newFormData.shortName = template.shortName;
        newFormData.firebaseId = template.firebaseId;
        newFormData.providerNumber = template.providerNumber;
        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));

        getClinics();
        await getTemplate(template.firebaseId, setTemplateContent);
    }

    function getClinicTextInput(clinics: IClinic[]) {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }


    function getReportTypeTextInput(reportTypes: IReportType[]) {

        setLoading(true);

        let reportTypesArr = [] as ITextInput[];

        reportTypes.forEach(reportType => {
            let data = {
                label: reportType.reportName,
                value: reportType.id
            }

            reportTypesArr.push(data);
        });

        setReportTypes(reportTypesArr);

        setLoading(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.handleModals(false, "edit", shouldUpdate);
    }

    function getDoctorTextInput(doctors: IDoctor[]) {

        setLoading(true);

        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

        setLoading(false);
    }

    function clearData() {
        let newFormData = formData;
        newFormData.reportTypeId = "";
        newFormData.shortName = "";
        newFormData.firebaseId = "";
        setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.reportTypeIdError.showError = false;
        newFormDataErrors.shortNameError.showError = false;
        setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setTemplateContent({} as ITemplateData);
        setReportTypes([]);
        setLoading(false);
    }

    async function update() {

        setLoading(true);

        if (formData.reportTypeId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = true;
            newFormDataErrors.reportTypeIdError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.shortName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.shortNameError.showError = true;
            newFormDataErrors.shortNameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.shortNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        let data = {
            templateId: props.selectedTemplate.id,
            reportTypeId: formData.reportTypeId,
            shortName: formData.shortName,
            providerNumber: formData.providerNumber,
        }

        apiService.updateTemplate(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Template Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} open={props.open} close={handleClose} editable={false}
            styles={"card-select-template"}>
            <div className="edit-select-template-container">
                <div className="header">Edit Report Template</div>
                <div className="form-area">
                    <div className='report-type-row'>
                        <div className="report-type">
                            <InputDropDown title='Doctor' type='dropdown' values={doctors}
                                defaultValue={formData.doctorId}
                                showError={formDataErrors.reportTypeIdError.showError}

                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        newFormData.doctorId = value[0].value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
                                    }
                                }} />
                        </div>
                        <div className="report-type">
                            <InputDropDown title='Clinic' type='dropdown' values={clinics}
                                defaultValue={formData.clinicId}
                                showError={formDataErrors.reportTypeIdError.showError}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        newFormData.clinicId = value[0].value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
                                    }
                                }} />
                        </div>
                        <div className="report-type">
                            <InputDropDown title='REPORT TYPE*' type='dropdown' values={reportTypes}
                                defaultValue={formData.reportTypeId}
                                showError={formDataErrors.reportTypeIdError.showError}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = formData;
                                        newFormData.reportTypeId = value[0].value;
                                        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
                                    }
                                }} />
                        </div>
                    </div>
                    <div className='report-type-row'>
                        <div className="report-con">
                            <Input showError={formDataErrors.shortNameError.showError} label='SHORT NAME' type='text' value={formData.shortName}
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.shortName = value;
                                    setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                        <div className="report-con">
                            <Input label='Provider Number' type='text' value={formData.providerNumber}
                                onChange={(value: any) => {
                                    let newFormData = formData;
                                    newFormData.providerNumber = value;
                                    setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }))
                                }} />
                        </div>
                    </div>
                </div>
                <div className="editor-body">
                    {templateContent?.pdfLink
                        ? (<iframe height={'98%'} width={'98%'} src={`${templateContent?.pdfLink}#toolbar=0`} title="Embedded PDF" />)
                        : (<Fragment />)
                    }
                </div>
                <div className="footer">
                    <div className="cancel-button" onClick={() => { handleClose(false); }}>Cancel</div>
                    <div className="button">
                        <DocumentButton loading={loading} text='Update' onClick={() => { update(); }} />
                    </div>
                </div>

            </div >
        </Modal >
    )
}

export default EditTemplateModal