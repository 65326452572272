import axios from "axios";
import { getBaseURL, getTokens } from "../helpers/HelperFunctions";
export default class ApiService {

    async login(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Auth/login", data);
    }

    async otp(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Auth/admin", data);
    }

    async getReportTypes() {
        const ax = await this.ax();
        return await ax.get("/api/ReportType/all");
    }

    async addReportType(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/ReportType/add-report-type", data);
    }

    async updateReportType(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/ReportType/update", data);
    }

    async addClinic(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Clinic/add-clinic", data);
    }

    async getClinics() {
        const ax = await this.ax();
        return await ax.get("/api/Clinic/all");
    }

    async addDoctor(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Doctor/add-doctor", data);
    }

    async getDoctors() {
        const ax = await this.ax();
        return await ax.get("/api/Doctor/all");
    }

    async addStaffMember(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/StaffMember/add-staff", data);
    }

    async requestChanges(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/request-changes", data);
    }

    async getPermission(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Role/page", data);
    }

    async addUser(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/User/register", data);
    }

    async getUsers() {
        const ax = await this.ax();
        return await ax.get("/api/User/all");
    }

    async getClinicsByDoctorId(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Clinic/get-clinics-by-id", data);
    }

    async getScribersByDoctorId(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/User/get-scriber-by-id", data);
    }

    async getDoctorsByClinicId(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Doctor/get-doctors", data);
    }

    async getReportTypesByDoctorIdOrClinicId(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/ReportType/get-report-types", data);
    }

    async getReports() {
        const ax = await this.ax();
        return await ax.get("/api/Document/all");
    }

    async addReport(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/add", data);
    }

    async getAssignees() {
        const ax = await this.ax();
        return await ax.get("/api/Document/assignees");
    }

    async completeStage(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/complete-stage", data);
    }

    async getDocumentById(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/get-document", data);
    }

    async getStaffMemberById(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/StaffMember/get-staff-member", data);
    }

    async updateReport(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/update", data);
    }

    async updateUser(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/User/update", data);
    }

    async getSupportStaff() {
        const ax = await this.ax();
        return await ax.get("/api/StaffMember/all");
    }

    async updateSupportStaff(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/StaffMember/update", data);
    }

    async updateClinic(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Clinic/update", data);
    }

    async updateDoctor(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Doctor/update", data);
    }

    async addTemplates(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Template/add-template", data);
    }

    async getTemplates() {
        const ax = await this.ax();
        return await ax.get("/api/Template/all");
    }

    async getTemplatesById(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Template/get-templates", data);
    }

    async getReportTypeById(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/ReportType/get-by-id", data);
    }

    async getTemplateById(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Template/get-template", data);
    }

    async updateDuration(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/update-duration", data);
    }

    async getSummaryDetails() {
        const ax = await this.ax();
        return await ax.get("/api/Document/get-summary");
    }

    async getDoctorSummary(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Doctor/get-summary", data);
    }

    async updateUserDetails(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/User/update", data);
    }

    async updateTemplate(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Template/update", data);
    }

    async getReportStatistics(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/get-report-statistics", data);
    }

    async getMemberStatistics(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/get-member-statistics", data);
    }

    async resetPassword(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Auth/reset", data);
    }

    async logout() {
        const ax = await this.ax();
        return await ax.get("/api/Auth/logout");
    }

    async getAnalytics(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/analytics", data);
    }

    async getUserAnalytics(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/user-analytics", data);
    }

    async assignUsers(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/assign", data);
    }

    async getAssigneeDetails(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/assignee-details", data);
    }

    async getRolePermission(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Role/role", data);
    }

    async updateRolePermission(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Role/save", data);
    }

    async getAudits(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Audit/all", data);
    }

    async releaseDocument(data: {}) {
        const ax = await this.ax();
        return await ax.post("/api/Document/release", data);
    }

    async deleteClinic(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Clinic/delete", data);
    }

    async deleteReport(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/delete", data);
    }

    async deleteSupportStaff(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/StaffMember/delete", data);
    }

    async deleteReportType(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/ReportType/delete", data);
    }

    async deleteTemplate(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Template/delete", data);
    }

    async deleteDoctor(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Doctor/delete", data);
    }

    async addReferrer(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Referrer/add", data);
    }

    async deleteReferrer(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Referrer/delete", data);
    }

    async updateReferrer(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Referrer/update", data);
    }

    async getReferrers() {
        const ax = await this.ax();
        return await ax.get("/api/Referrer/all");
    }

    async getTaggedReports(id: string) {
        const ax = await this.ax();
        return await ax.get(`/api/Document/tagged-reports/${id}`);
    }

    async getPriorities() {
        const ax = await this.ax();
        return await ax.get("/api/Priority/all");
    }

    async getPatient() {
        const ax = await this.ax();
        return await ax.get("/api/Patient/all");
    }

    async reopenDocument(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Document/open", data);
    }

    async getRequestedChangesById(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/RequestedChanges/get-requested-changes", data);
    }

    async getTaggedReportsByDocumentId(data: any) {
        const ax = await this.ax();
        return await ax.get("/api/Document/tagged-reports-document/" + data);
    }

    async forgetPassword(data: any) {
        const ax = await this.ax();
        return await ax.post("/api/Auth/forget-password", data);
    }

    private async ax() {

        let baseUlr = getBaseURL();

        return axios.create({
            baseURL: baseUlr,
            timeout: 60000,
            headers: {
                Authorization: `Bearer ${getTokens().jwt}`,
                "Content-Type": "application/json",
            }
        });
    }
}