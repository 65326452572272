import Layout from "../../components/Layout/Layout";
import DoctorSummary from "./DoctorSummary";
import AssignChart from "./AssignChart";
import { useState } from "react";
import MuiAlert from "../../components/Alert/Alert";

import "./Home.scss";

export default function Home() {

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    return (<Layout selectedIndex={0}>
        <div className="home-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                }} />
            <div className="header">
                <span className="text">Home</span>
            </div>

            <AssignChart setLoading={setLoading} setShowAlert={setShowAlert} />
            <DoctorSummary setLoading={setLoading} setShowAlert={setShowAlert} />
        </div>
    </Layout >)
}