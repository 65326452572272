import { Checkbox } from '@mui/material';
import { Colors } from '../../helpers/Colors';

function CheckBox(props: { onChange: CallableFunction; icon: any; checkedIcon: any; checked: boolean; }) {
    return (
        <Checkbox onChange={(data: any) => props.onChange(data.target.checked)} icon={<img alt="icon" src={props.icon} />}
            checkedIcon={<img alt="checkbox" src={props.checkedIcon} />} style={{ color: Colors.white_10 }}
            value="cryon" checked={props.checked} />
    )
}

export default CheckBox