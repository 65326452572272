import { Fragment, useCallback, useEffect, useState } from 'react'
import BlueHomeDot from "../../assets/images/BlueHomeDot.svg";
import BlueHomeDotDisable from "../../assets/images/BlueHomeDotDisable.svg";
import OrangeHomeDotDisable from "../../assets/images/OrangeHomeDotDisable.svg";
import GreenHomeDotDisable from "../../assets/images/GreenHomeDotDisable.svg";
import RedHomeDotDisable from "../../assets/images/RedHomeDotDisable.svg";
import OrangeHomeDot from "../../assets/images/OrangHomeDot.svg";
import GreenHomeDot from "../../assets/images/GreenHomeDot.svg";
import RedHomeDot from "../../assets/images/RedHomeDot.svg";
import ChartBox from "../../components/ChartBox/ChartBox";
import { Area, AreaChart, XAxis, YAxis, Tooltip } from "recharts";
import CheckBox from "../../components/CheckBox/CheckBox";
import Tabs from '../../components/Tabs/Tabs';
import { IMemberStatistics, IMemberStatisticsData, IReportStatistic } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';

type ChartProps = {
    setLoading: (loading: boolean) => void;
    setShowAlert: (alert: { isError: boolean, message: string, open: boolean }) => void;
}

function AssignChart(props: ChartProps) {

    const apiService = new ApiService();

    const [selectedView, setSelectedView] = useState(0);
    const [showData, setShowData] = useState({
        reports: { scribe: true, proof: true, review: true, completed: true },
        member: { scribe: true, proof: true, review: true }
    });
    const [reportStatistics, setReportStatistics] = useState([] as IReportStatistic[]);
    const [memberStatistics, setMemberStatistics] = useState([] as IMemberStatistics[]);
    const [selectedPeriod, setSelectedPeriod] = useState("Monthly");
    const [selectedMember, setSelectedMember] = useState({} as IMemberStatistics);

    useEffect(() => {
        if (selectedView === 0) {
            getReportStatistics();
        } else if (selectedView === 1) {
            getMemberStatistics();
        }
    }, [selectedPeriod, selectedView])

    useEffect(() => {
        if (memberStatistics.length > 0) {
            setSelectedMember(memberStatistics[0]);
        }
    }, [memberStatistics])

    const handleChangeView = useCallback((value: any) => {
        setSelectedView(value);
    }, []);

    function getMemberStatistics() {

        props.setLoading(true);

        let data = {
            period: selectedPeriod
        }

        apiService.getMemberStatistics(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = { isError: true, message: data?.errorMessage, open: true };
                props.setShowAlert(newShowAlert);
                return;
            }

            setMemberStatistics(data?.memberStatistics)
        }).catch(() => {
            let newShowAlert = { isError: true, message: "Unexpected Error", open: true };
            props.setShowAlert(newShowAlert);
        }).finally(() => {
            props.setLoading(false);
        });
    }

    function getReportStatistics() {

        props.setLoading(true);

        let data = {
            period: selectedPeriod
        }

        apiService.getReportStatistics(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = { isError: true, message: data?.errorMessage, open: true };
                props.setShowAlert(newShowAlert);
                return;
            }

            setReportStatistics(data?.reportStatistics)
        }).catch(() => {
            let newShowAlert = { isError: true, message: "Unexpected Error", open: true };
            props.setShowAlert(newShowAlert);
        }).finally(() => {
            props.setLoading(false);
        });
    }

    return (
        <div className="assigned-targets">
            <div className="table-view">
                <div className="header">
                    <span className="title">Targets Achieved</span>
                    <div className="content">
                        <div className="time-plan">
                            <div onClick={() => { setSelectedPeriod("Monthly") }}
                                className={`plan ${selectedPeriod === "Monthly" ? "plan-active" : ""}`}>
                                Monthly
                            </div>
                            <div onClick={() => { setSelectedPeriod("Yearly") }}
                                className={`plan ${selectedPeriod === "Yearly" ? "plan-active" : ""}`}>
                                Yearly
                            </div>
                        </div>
                    </div>
                </div>
                {selectedView == 0
                    ? <div className="chart-content">
                        <ChartBox showData={showData.reports} reportStatistics={reportStatistics} />
                    </div>
                    : <Fragment />
                }
                {selectedView == 1
                    ? <div className="area-chart">
                        <AreaChart width={window.innerWidth * (0.5)} height={260} data={selectedMember.data}
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#203FAD" stopOpacity={0.3} />
                                    <stop offset="95%" stopColor="#203FAD" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#EC7430" stopOpacity={0.3} />
                                    <stop offset="95%" stopColor="#EC7430" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorAmt" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#2BD765" stopOpacity={0.3} />
                                    <stop offset="95%" stopColor="#2BD765" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <YAxis color="transparent" width={20} axisLine={false} style={{ opacity: "0" }} />
                            <XAxis tickLine={false} dataKey="label" style={{ fontSize: "10px", fontWeight: "700", opacity: "0.5" }} />
                            <Tooltip content={<CustomTooltip />} contentStyle={{ border: "10" }} />
                            {showData.member.scribe
                                ? <Area type="monotone" dataKey="scribe" stroke="#203FAD" fillOpacity={1} fill="url(#colorUv)" />
                                : <Fragment />
                            }
                            {showData.member.proof
                                ? <Area type="monotone" dataKey="proof" stroke="#EC7430" fillOpacity={1} fill="url(#colorPv)" />
                                : <Fragment />
                            }
                            {showData.member.review
                                ? <Area type="monotone" dataKey="review" stroke="#2BD765" fillOpacity={1} fill="url(#colorAmt)" />
                                : <Fragment />
                            }
                        </AreaChart>
                    </div>
                    : <Fragment />
                }
            </div>
            <div className="data-view">
                <div className="tabs">
                    <Tabs titles={["Reports", "Member"]} selectedIndex={selectedView} onClick={handleChangeView} />
                </div>
                {selectedView == 0
                    ? <div className="data">
                        <div className="role-row">
                            <span className="role-name">Scribing</span>
                            <CheckBox icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot} checked={showData.reports.scribe}
                                onChange={(isSelected: boolean) => {
                                    let newShowData = showData;
                                    newShowData.reports.scribe = isSelected;
                                    setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                }} />
                        </div>
                        <div className="role-row">
                            <span className="role-name">Proofing</span>
                            <CheckBox icon={OrangeHomeDotDisable} checkedIcon={OrangeHomeDot} checked={showData.reports.proof}
                                onChange={(isSelected: boolean) => {
                                    let newShowData = showData;
                                    newShowData.reports.proof = isSelected;
                                    setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                }} />
                        </div>
                        <div className="role-row">
                            <span className="role-name">Reviewing</span>
                            <CheckBox icon={GreenHomeDotDisable} checkedIcon={GreenHomeDot} checked={showData.reports.review}
                                onChange={(isSelected: boolean) => {
                                    let newShowData = showData;
                                    newShowData.reports.review = isSelected;
                                    setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                }} />
                        </div>
                        <div className="role-row">
                            <span className="role-name">Completed</span>
                            <CheckBox icon={RedHomeDotDisable} checkedIcon={RedHomeDot} checked={showData.reports.completed}
                                onChange={(isSelected: boolean) => {
                                    let newShowData = showData;
                                    newShowData.reports.completed = isSelected;
                                    setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                }} />
                        </div>
                    </div>
                    : <Fragment />
                }

                {selectedView == 1
                    ? <div className="level-data">
                        <div className="list-user">
                            {memberStatistics.map((member) => {
                                return (
                                    <div onClick={() => { setSelectedMember(member) }} key={member.id}
                                        className={`level-row ${selectedMember.id === member.id ? "active-level" : ''}`} >
                                        <span className="user-name">{member.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="role-list">
                            <div className="check-role">
                                <CheckBox icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot} checked={showData.member.scribe}
                                    onChange={(isSelected: boolean) => {
                                        let newShowData = showData;
                                        newShowData.member.scribe = isSelected;
                                        setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                    }} />
                                <span className="title">Scribing</span>
                            </div>
                            <div className="check-role">
                                <CheckBox icon={OrangeHomeDotDisable} checkedIcon={OrangeHomeDot} checked={showData.member.proof}
                                    onChange={(isSelected: boolean) => {
                                        let newShowData = showData;
                                        newShowData.member.proof = isSelected;
                                        setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                    }} />
                                <span className="title">Proofing</span>
                            </div>
                            <div className="check-role">
                                <CheckBox icon={GreenHomeDotDisable} checkedIcon={GreenHomeDot} checked={showData.member.review}
                                    onChange={(isSelected: boolean) => {
                                        let newShowData = showData;
                                        newShowData.member.review = isSelected;
                                        setShowData((prevDetails: any) => ({ ...prevDetails, ...newShowData }));
                                    }} />
                                <span className="title">Reviewing</span>
                            </div>
                        </div>
                    </div>
                    : <Fragment />
                }
            </div>
        </div>
    )
}

export default AssignChart

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="tooltip-header">
                    <span className="label-value">Reports</span>
                </div>
                {payload[0]?.value
                    ? (<span className="label-value">{`Scribed -  ${payload[0]?.value}`}</span>)
                    : (<></>)
                }
                {payload[1]?.value
                    ? (<span className="label-value">{`Proofed -  ${payload[1]?.value}`}</span>)
                    : (<></>)
                }
                {payload[2]?.value
                    ? (<span className="label-value">{`Reviewed - ${payload[2]?.value}`}</span>)
                    : (<></>)
                }
            </div>
        );
    }

    return <></>;
};