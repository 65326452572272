import DocumentButton from "../../components/DocumentButton/DocumentButton";

import "./Error.scss";

export default function ErrorUI() {

    function goBack() {
        window.history.back();
    }

    return (<main className="error-container">
        <section className="empty">
        </section>
        <section className="button-container">
            <div>
                <DocumentButton text="Go Back" loading={false} onClick={() => { goBack(); }} />
            </div>
        </section>
    </main>)
}