import { ReactElement } from "react";
import SideBar from "../Sidebar/SideBar";

import "./Layout.scss";

export default function Layout(props: { selectedIndex: number, children: ReactElement; }) {
    return (<div className="layout-container">
        <SideBar selectedIndex={props.selectedIndex} />
        <div className="text-container">
            {props.children}
        </div>
        {/* <a href='ms-word:ofv|u|https://firebasestorage.googleapis.com/v0/b/fir-testing-67a64.appspot.com/o/Test.docx?alt=media&token=e101a15e-9bab-4c19-99a0-1022cbf63978'>Edit</a> */}

    </div>)
}