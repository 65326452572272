import { useState, Fragment, useEffect } from 'react'
import DateInput from '../DateInput/DateInput';
import DocumentButton from '../DocumentButton/DocumentButton';
import Input from '../Input/Input';
import InputDropDown from '../InputDropDown/InputDropDown';
import Modal from '../Modal/Modal';
import Stepper from '../Stepper/Stepper';
import BlueHomeDot from "../../assets/images/BlueHomeDot.svg";
import BlueHomeDotDisable from "../../assets/images/BlueHomeDotDisable.svg";
import ReportBlue from "../../assets/images/ReportBlue.svg";
import DownloadIcon from "../../assets/images/Download.svg";
import moment from 'moment';
import ApiService from '../../services/ApiService';
import { IAssignee, IClinic, IDoctor, IFile, IPatient, IPriority, IReferrer, IReportData, IReportType, ITaggedReport, ITextInput } from '../../helpers/Interfaces';
import { createMediaDocument, removeMedia } from '../../helpers/FirebaseFunctions';
import { calculateDueDate, downloadWordDocument } from '../../helpers/HelperFunctions';
import CheckBox from '../CheckBox/CheckBox';
import FileUploadUI from '../FileUploadUI/FileUploadUI';

import "./AddReport.scss"

export default function AddReport(props: { open: boolean, close: CallableFunction, showAlert: CallableFunction }) {

    const apiService = new ApiService();

    const [view, setView] = useState(0);
    const [formData, setFormData] = useState({
        patientName: "", patientNameId: "", dueDate: moment().utc().local(), priorityId: null, doctorId: "", clinicId: null, reportTypeId: "",
        providerNumber: null, placeOfAssessment: null, dateOfAssessment: moment().utc().local(), referrerId: null, reportName: null,
        notes: null, mediaId: new Date().getTime().toString(), totalDuration: 0, scribeAssignedTo: null, adHoc: false,
    });
    const [formDataErrors, setFormDataErrors] = useState({
        patientNameError: { showError: false, message: "" }, dueDateError: { showError: false, message: "" },
        priorityIdError: { showError: false, message: "" }, doctorIdError: { showError: false, message: "" },
        reportTypeIdError: { showError: false, message: "" }, dateOfAssessmentError: { showError: false, message: "" }
    });
    const [media, setMedia] = useState([] as IFile[]);
    const [addedFiles, setAddedFiles] = useState([] as IFile[]);
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [doctors, setDoctors] = useState([] as ITextInput[]);
    const [clinics, setClinics] = useState([] as ITextInput[]);
    const [patients, setPatients] = useState([] as IPatient[]);
    const [filteredPatients, setFilteredPatients] = useState([] as ITextInput[]);
    const [referrers, setReferrers] = useState([] as ITextInput[]);
    const [reportTypes, setReportTypes] = useState([] as ITextInput[]);
    const [scribers, setScribers] = useState([] as ITextInput[]);
    const [priorities, setPriorities] = useState([] as ITextInput[]);
    const [tempPriorities, setTempPriorities] = useState([] as IPriority[]);
    const [taggedReports, setTaggedReports] = useState([] as ITaggedReport[]);
    const [selectedTaggedReports, setSelectedTaggedReports] = useState([] as ITaggedReport[]);
    const [selectedDownloadReport, setSelectedDownloadReport] = useState({} as IReportData);

    useEffect(() => {
        getDoctors();
        getAssignees();
        getReferrers();
        getPriorities();
        getPatient();
    }, [])

    useEffect(() => {
        if (formData.priorityId !== null) {
            let newDueDate = calculateDueDate(
                tempPriorities.filter((priority) => { return priority.id == formData.priorityId })[0]?.numberOfDaysAdded);
            let newFormData = formData;
            newFormData.dueDate = newDueDate;
            setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
        }
    }, [formData.priorityId])

    useEffect(() => {
        if (formData.patientName !== "") {
            getTaggedReports();
        }
    }, [formData.patientNameId])

    useEffect(() => {
        if (formData.doctorId.trim() !== "") {
            getClinics();
            getReportTypes();
        }

        if (formData.doctorId.trim() !== "" && formData.clinicId !== null) {
            getReportTypes();
        }

        let _patients: IPatient[] = patients.filter((patient) => patient.doctor.id === formData.doctorId);
        getPatientTextInput(_patients);

    }, [formData.doctorId, formData.clinicId])

    useEffect(() => {
        if (clinics.length > 0) {
            if (!clinics.some((clinic) => clinic.value === formData.clinicId)) {
                let newFormData = formData;
                newFormData.clinicId = null
                setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
            }
        }

        if (reportTypes.length > 0) {
            if (!reportTypes.some((clinic) => clinic.value === formData.reportTypeId)) {
                let newFormData = formData;
                newFormData.reportTypeId = ""
                setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
            }
        }
    }, [clinics, reportTypes])

    useEffect(() => {

        let duration = 0;

        media.forEach((file) => {
            duration = duration + file.duration
        });

        let form = formData;
        form.totalDuration = duration;
        if (duration > 0) {
            form.adHoc = false;
        }
        setFormData((previousData) => ({ ...previousData, ...form }));
    }, [media, media.length]);

    function getScribersTextInput(assignees: IAssignee[]) {

        setLoading(true);

        let filteredAssignees = assignees.filter((assignee) => {
            return assignee.role === "Scribe"
        });

        let scribersArr = [] as ITextInput[];

        filteredAssignees.forEach(scriber => {
            let data = {
                label: scriber.name,
                value: scriber.email
            }

            scribersArr.push(data);
        });

        setScribers(scribersArr);

        setLoading(false);
    }

    function getReferrers() {

        setLoading(true);

        apiService.getReferrers().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getReferrersTextInput(data?.referrers);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getTaggedReports() {
        setLoading(true);

        apiService.getTaggedReports(formData.patientNameId).then((result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setTaggedReports(data.reports)

        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPatient() {

        setLoading(true);

        apiService.getPatient().then((result) => {
            let data = result.data;
            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setPatients(data.patients);
            getPatientTextInput(data.patients);

        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPriorities() {

        setLoading(true);

        apiService.getPriorities().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setTempPriorities(data?.priorities);
            getPrioritiesTextInput(data?.priorities);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPatientTextInput(patients: IPatient[]) {
        let patientArr = [] as ITextInput[];

        patients.forEach(patient => {
            let data = {
                label: patient.name,
                value: patient.id
            }

            patientArr.push(data);
        });

        setFilteredPatients(patientArr);
    }

    function getReferrersTextInput(referrers: IReferrer[]) {

        setLoading(true);

        let referrersArr = [] as ITextInput[];

        referrers.forEach(referrer => {
            let data = {
                label: referrer.name,
                value: referrer.id
            }

            referrersArr.push(data);
        });

        setReferrers(referrersArr);

        setLoading(false);
    }

    function getPrioritiesTextInput(priorities: IPriority[]) {

        setLoading(true);

        let prioritiesArr = [] as ITextInput[];

        priorities.forEach(priority => {
            let data = {
                label: priority.name,
                value: priority.id.toString()
            }

            prioritiesArr.push(data);
        });

        setPriorities(prioritiesArr);

        setLoading(false);
    }

    function getAssignees() {

        setLoading(true);

        apiService.getAssignees().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getScribersTextInput(data?.users);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const closeAlert = async () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleNext = () => {
        setView(view + 1);
    }

    const handlePrevious = () => {
        setView(view - 1);
    }

    function getReportTypes() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId,
            clinicId: formData.clinicId
        }

        apiService.getReportTypesByDoctorIdOrClinicId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getReportTypeTextInput(data?.reportTypes);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        let data = {
            doctorId: formData.doctorId
        }

        apiService.getClinicsByDoctorId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getClinicTextInput(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const clearData = () => {
        let newFormData = formData;
        newFormData.patientName = "";
        newFormData.dueDate = moment().utc().local();
        newFormData.priorityId = null;
        newFormData.doctorId = "";
        newFormData.clinicId = null;
        newFormData.reportTypeId = "";
        newFormData.providerNumber = null;
        newFormData.placeOfAssessment = null;
        newFormData.dateOfAssessment = moment().utc().local();
        newFormData.referrerId = null;
        newFormData.reportName = null;
        newFormData.notes = null;
        newFormData.mediaId = new Date().getTime().toString();
        newFormData.totalDuration = 0;
        newFormData.scribeAssignedTo = null;
        newFormData.adHoc = false;
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.patientNameError.showError = false;
        newFormDataErrors.dueDateError.showError = false;
        newFormDataErrors.priorityIdError.showError = false;
        newFormDataErrors.doctorIdError.showError = false;
        newFormDataErrors.reportTypeIdError.showError = false;
        newFormDataErrors.dateOfAssessmentError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setMedia([]);
        setLoading(false);
        setView(0);
    }

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("add", shouldUpdate);
    }

    const closeModal = (shouldUpdate: boolean) => {

        setLoading(true);

        addedFiles.forEach(async (file) => {
            await removeMedia(file.fileId);
            let _formData = formData;
            _formData.totalDuration = formData.totalDuration - file.duration;
            setFormData(_formData);
        });

        handleClose(shouldUpdate);
    }

    const updateFormData = (newFormData: any) => {
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
    }

    function toMedia() {

        setLoading(true);

        if (formData.patientName?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.patientNameError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.patientNameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!(formData.dueDate instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dueDateError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dueDateError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.priorityId === null) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.priorityIdError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.priorityIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.doctorId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.doctorIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.reportTypeId?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.reportTypeIdError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!(formData.dateOfAssessment instanceof moment)) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dateOfAssessmentError.showError = true;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.dateOfAssessmentError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        setLoading(false);
        handleNext();
    }

    function getDoctors() {

        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            getDoctorTextInput(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinicTextInput(clinics: IClinic[]) {

        setLoading(true);

        let clinicsArr = [] as ITextInput[];

        clinics.forEach(clinic => {
            let data = {
                label: clinic.clinicName,
                value: clinic.id
            }

            clinicsArr.push(data);
        });

        setClinics(clinicsArr);

        setLoading(false);
    }

    function getDoctorTextInput(doctors: IDoctor[]) {

        setLoading(true);

        let doctorsArr = [] as ITextInput[];

        doctors.forEach(doctor => {
            let data = {
                label: doctor.firstName + " " + doctor.lastName,
                value: doctor.id
            }

            doctorsArr.push(data);
        });

        setDoctors(doctorsArr);

        setLoading(false);
    }

    function getReportTypeTextInput(reportTypes: IReportType[]) {

        setLoading(true);

        let reportTypesArr = [] as ITextInput[];

        reportTypes.forEach(reportType => {
            let data = {
                label: `${reportType.reportName} - ${reportType.clinicName}`,
                value: reportType.id
            }

            reportTypesArr.push(data);
        });

        setReportTypes(reportTypesArr);

        setLoading(false);
    }

    async function saveReport() {

        setLoading(true);

        let mediaAdded = await createMediaDocument(formData.mediaId, media);

        if (!mediaAdded) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
            setLoading(false);
            return;
        }

        let ds2Files = media.filter(media => media.fileName.includes('.ds2'));

        if (ds2Files.length === media.length) {
            let newForm = formData;
            newForm.adHoc = true;
            setFormData((previous) => ({ ...previous, newForm }));
        }

        let _taggedReports = [] as string[];

        selectedTaggedReports.forEach(report => {
            _taggedReports.push(report.documentId)
        });

        const data = Object.assign(formData, { addedDS2: (ds2Files.length > 0), taggedReports: _taggedReports })


        console.log(data);


        apiService.addReport(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Report Added";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    async function removeFile(file: IFile) {
        setLoading(true);
        await removeMedia(file.fileId).then((response: any) => {
            if (response) {
                let _media = media;
                let _filteredArray = _media.filter((_file) => _file.fileId !== file.fileId);
                setMedia(_filteredArray);

                let _addedFilesMedia = addedFiles;
                let _filterAddedArray = _addedFilesMedia.filter((_file) => _file.fileId !== file.fileId);
                setAddedFiles(_filterAddedArray);
            }

            setLoading(false);
        })
    }

    function getTitle() {
        if (formData.patientName.trim() !== "") {
            return "Add Report - " + formData.patientName;
        }

        return "Add Report"
    }

    const handleSelectTaggedReport = (selected: boolean, report: ITaggedReport) => {
        if (selected) {
            setSelectedTaggedReports(previous => [...previous, report])
        } else {
            setSelectedTaggedReports(previous => previous.filter((value, _) => value.documentId !== report.documentId))
        }
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={closeModal}
            styles={"add-report-card"} >
            <div className="add-report-container">
                <span className='title'>{getTitle()}</span>

                {view === 0
                    ? (<Form patients={filteredPatients} formData={formData} setFormData={updateFormData} formDataErrors={formDataErrors} toAddMedia={toMedia}
                        doctors={doctors} clinics={clinics} reportTypes={reportTypes} loading={loading} referrers={referrers}
                        priorities={priorities} />)
                    : (<Fragment />)}
                {view === 1
                    ? (<TagReport selectedDownloadReport={selectedDownloadReport} setSelectedDownloadReport={setSelectedDownloadReport} setLoading={setLoading} loading={loading} taggedReports={taggedReports} patients={filteredPatients} formData={formData} setFormData={updateFormData} formDataErrors={formDataErrors} addTags={handleNext}
                        back={handlePrevious} onSelectReport={handleSelectTaggedReport} selectedReports={selectedTaggedReports} />)
                    : (<Fragment />)
                }
                {view === 2
                    ? (<AddMedia addedFiles={addedFiles} setAddedFiles={setAddedFiles} removeFile={removeFile} next={handleNext} back={handlePrevious} formData={formData} setFormData={updateFormData}
                        loading={loading} setLoading={setLoading} media={media} setShowAlert={setShowAlert} setMedia={setMedia} />)
                    : (<Fragment />)
                }
                {view === 3
                    ? (<AssignTo save={saveReport} back={handlePrevious} scribers={scribers} formData={formData}
                        setFormData={updateFormData} loading={loading} />)
                    : (<Fragment />)
                }
            </div>
        </Modal>
    )
}

function TagReport(props: {
    back: CallableFunction, addTags: CallableFunction, formData: any; setFormData: CallableFunction; patients: ITextInput[]; formDataErrors: any; taggedReports: ITaggedReport[]
    onSelectReport: CallableFunction, selectedReports: ITaggedReport[], loading: boolean, setSelectedDownloadReport: CallableFunction, selectedDownloadReport: IReportData, setLoading: CallableFunction
}) {

    return (
        <div className='tag-report-content'>
            <div className="stepper">
                <Stepper steps={[
                    { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form" },
                    { circleStyle: "active", descriptionStyle: "description-active", description: "Tag reports" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to" }
                ]} />
            </div>
            <div className="report-content">
                <div className="input-field">
                    <InputDropDown title='PATIENT NAME*' type='search' typeable
                        defaultValue={props.formData.patientName} values={props.patients}
                        showError={props.formDataErrors?.priorityIdError?.showError}
                        onSelect={(val: any, asValue: any) => {
                            let newFormData = props.formData;
                            if (asValue) {
                                newFormData.patientName = val;
                                newFormData.patientNameId = "";
                            } else {
                                newFormData.patientNameId = val[0].value;
                                newFormData.patientName = val[0].label.toString().split(' - ')[0];
                            }
                            props.setFormData(newFormData);
                        }} />
                </div>
                <div className="report-view">
                    {props.taggedReports.length > 0
                        ? props.taggedReports.map((report) => (
                            <div key={report.documentId} className="report-data">
                                <CheckBox onChange={(value: boolean) => {
                                    props.onSelectReport(value, report)
                                }} icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot} checked={props.selectedReports.filter(_report => report.documentId === _report.documentId).length > 0 ?? false} />
                                <div className="report-data-content">
                                    <div className="report-name">
                                        <img src={ReportBlue} alt="ReportBlue" />
                                        <span>{report.reportName}</span>
                                    </div>
                                    <img onClick={() => downloadWordDocument({ documentId: report.documentId, reportName: report.reportName, setLoading: props.setLoading, setReport: props.setSelectedDownloadReport, report: props.selectedDownloadReport })} className='download-icon' src={DownloadIcon} alt="ReportBlue" />
                                </div>
                            </div>
                        ))
                        : <div className="report-empty">No Reports</div>}
                </div>
            </div>
            <div className="footer-btn">
                <span onClick={() => props.back()} className="back">Back</span>
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => props.addTags()} text={"Next"} />
                </div>
            </div>
        </div>
    )
}

function Form(props: {
    formData: any; setFormData: CallableFunction; formDataErrors: any; doctors: ITextInput[]; clinics: ITextInput[]; patients: ITextInput[];
    reportTypes: ITextInput[]; toAddMedia: CallableFunction; loading: boolean; referrers: ITextInput[]; priorities: ITextInput[];
}) {
    return (
        <div className="fill-form">
            <div className="header">
                <div className="stepper">
                    <Stepper steps={[
                        { circleStyle: "active", descriptionStyle: "description-active", description: "Fill the form" },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag reports" },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                        { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to" }
                    ]} />
                </div>
                <div className="form">
                    <div className="row">
                        <div className="input-field">
                            <InputDropDown title='PATIENT NAME*' type='search' typeable
                                defaultValue={props.formData.patientName} values={props.patients}
                                showError={props.formDataErrors?.priorityIdError?.showError}
                                onSelect={(val: any, asValue: any) => {
                                    let newFormData = props.formData;
                                    if (asValue) {
                                        newFormData.patientName = val;
                                        newFormData.patientNameId = "";
                                    } else {
                                        newFormData.patientNameId = val[0].value;
                                        newFormData.patientName = val[0].label.toString().split(' - ')[0];
                                    }
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='PRIORITY*' type='dropdown'
                                defaultValue={props.formData.priorityId} values={props.priorities}
                                showError={props.formDataErrors?.priorityIdError?.showError}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.priorityId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <DateInput label="DUE DATE*" value={props.formData?.dueDate}
                                showError={props.formDataErrors?.dueDateError?.showError}
                                onChange={(value: any) => {
                                    if (value) {
                                        let newFormData = props.formData;
                                        newFormData.dueDate = value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>

                        <div className="input-field">
                            <InputDropDown title='DOCTOR*' values={props.doctors} type='search'
                                defaultValue={props.formData.doctorId}
                                showError={props.formDataErrors?.doctorIdError?.showError}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.doctorId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='CLINIC' values={props.clinics} type='search'
                                defaultValue={props.formData.clinicId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.clinicId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='REPORT TYPE*' values={props.reportTypes} type='search'
                                showError={props.formDataErrors?.reportTypeIdError?.showError}
                                defaultValue={props.formData.reportTypeId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.reportTypeId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='PROVIDER NUMBER' value={props.formData.providerNumber} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.providerNumber = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='PLACE OF ASSESSMENT' value={props.formData.placeOfAssessment} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.placeOfAssessment = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field">
                            <DateInput label="DATE OF ASSESSMENT*" value={props.formData.dateOfAssessment}
                                showError={props.formDataErrors?.dateOfAssessmentError?.showError}
                                onChange={(value: any) => {
                                    if (value) {
                                        let newFormData = props.formData;
                                        newFormData.dateOfAssessment = value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <InputDropDown title='REFERRER' values={props.referrers} type='search'
                                defaultValue={props.formData.referrerId}
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.referrerId = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                        <div className="input-field">
                            <Input label='REPORT NAME' value={props.formData.reportName} type='text'
                                onChange={(value: any) => {
                                    let newFormData = props.formData;
                                    newFormData.reportName = value;
                                    props.setFormData(newFormData);
                                }} />
                        </div>
                        <div className="input-field"></div>
                    </div>
                    <div className="row">
                        <Input label='COMMENTS' value={props.formData.notes} type='textarea' rows={5}
                            onChange={(value: any) => {
                                let newFormData = props.formData;
                                newFormData.notes = value;
                                props.setFormData(newFormData);
                            }} />
                    </div>
                </div>
            </div>
            <div className="footer-btn">
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => { props.toAddMedia() }} text={"Next"} />
                </div>
            </div>
        </div>
    )
}

function AddMedia(props: {
    next: CallableFunction; back: CallableFunction; formData: any; media: IFile[]; setShowAlert: CallableFunction;
    setFormData: CallableFunction; loading: boolean; setLoading: CallableFunction; setMedia: CallableFunction;
    removeFile: CallableFunction; addedFiles: IFile[]; setAddedFiles: CallableFunction
}) {
    return (
        <div className="add-media">
            <div className="stepper">
                <Stepper steps={[
                    { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form", },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag reports" },
                    { circleStyle: "active", descriptionStyle: "description-inactive", description: "Add media files", },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Assign to", },
                ]} />
            </div>
            <FileUploadUI addedFiles={props.addedFiles} setAddedFiles={props.setAddedFiles} removeFile={props.removeFile}
                setLoading={props.setLoading} setMedia={props.setMedia} media={props.media}
                setShowAlert={props.setShowAlert} />
            <div className="footer-btn">
                {props.formData.totalDuration === 0
                    ? (<span className="skip">
                        <CheckBox icon={BlueHomeDotDisable} checkedIcon={BlueHomeDot} checked={props.formData.adHoc}
                            onChange={() => {
                                let newFormData = props.formData;
                                newFormData.adHoc = !props.formData.adHoc;
                                props.setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }));
                            }} />
                        Add Documentation
                    </span>)
                    : (<Fragment />)
                }
                <div className='next-button-content'>
                    <span onClick={() => { props.back() }} className="back">Back</span>
                    <div className="button">
                        <DocumentButton loading={props.loading} onClick={() => { props.next() }} text={"Next"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function AssignTo(props: {
    save: CallableFunction; back: CallableFunction; scribers: ITextInput[]; formData: any; setFormData: CallableFunction;
    loading: boolean;
}) {
    return (
        <div className="assign-to">
            <div className="stepper">
                <Stepper steps={[
                    { circleStyle: "inactive", descriptionStyle: "description-active", description: "Fill the form" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Tag reports" },
                    { circleStyle: "inactive", descriptionStyle: "description-inactive", description: "Add media files" },
                    { circleStyle: "active", descriptionStyle: "description-inactive", description: "Assign to" }
                ]} />
            </div>
            <div className="form">
                <div className="row">
                    <div className="item">
                        <div className="input-field">
                            <InputDropDown title='SCRIBER' values={props.scribers} type='search'
                                onSelect={(value: any) => {
                                    if (value?.length > 0) {
                                        let newFormData = props.formData;
                                        newFormData.scribeAssignedTo = value[0].value;
                                        props.setFormData(newFormData);
                                    }
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-btn">
                <span onClick={() => props.back()} className="back">Back</span>
                <div className="button">
                    <DocumentButton loading={props.loading} onClick={() => { props.save() }} text={"Add Report"} />
                </div>
            </div>
        </div>
    )
}