import { createContext, useEffect, useState } from "react";
import { userDecrypt } from "../helpers/HelperFunctions";
import { getFirebaseUser } from "../helpers/FirebaseFunctions";

export const User = createContext([] as any);
export const FirebaseUser = createContext([] as any);

export default function Store({ children }: any) {

    const [user, setUser] = useState(null as any);
    const [firebaseUser, setFirebaseUser] = useState(null as any);

    useEffect(() => {
        if (sessionStorage.getItem("user") !== null && user == null) {
            setUser(userDecrypt());
        }

        if (user?.notificationId && firebaseUser == null) {
            getFirebaseUser(user?.notificationId, setFirebaseUser);
        }
    }, [user])

    return (
        <User.Provider value={[user, setUser]}>
            <FirebaseUser.Provider value={[firebaseUser, setFirebaseUser]}>
                {children}
            </FirebaseUser.Provider>
        </User.Provider>
    );
}
