import { SvgIcon, SvgIconProps, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ToolTip from "../ToolTip/ToolTip";
import ErrorShield from "../../assets/images/ErrorShield.svg";

import "./DateInput.scss";

type DateInputProps = {
  label: string;
  value: string;
  onChange: CallableFunction;
  showError: boolean;
  readonly height?: number,
}

export default function DateInput(props: DateInputProps) {

  return (
    <div className="input-container">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker label={props.label} value={props.value} components={{ OpenPickerIcon: Icon }}
          inputFormat="DD/MM/yyyy"
          onChange={(value: any) => {
            if (value != null) {
              props.onChange(value);
            }
          }}
          renderInput={(params: any) => (

            <TextField
              id="standard-size-normal"
              variant="standard"
              sx={{
                width: '100%',
                height: props.height,
              }}
              classes={{
                root: `text-field-base ${props.showError ? 'text-field-error' : ''}`
              }}
              {...params} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        color="white" stroke="#B4B4B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 2V6" stroke="#B4B4B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2V6" stroke="#B4B4B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 10H21" stroke="#B4B4B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

function toolTip(message: string) {
  return (<div className="wrapper-error">
    <img className="img" src={ErrorShield} alt={"Shield"} />
    <span className="text-error">{message}</span>
  </div>)
}
