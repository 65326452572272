import Import from "../../assets/images/Import.svg";
import EditUserProfile from "../../assets/images/EditUserProfile.svg";
import { useEffect, useState } from "react";
import { getDuration, toBase64 } from "../../helpers/HelperFunctions";
import { useDropzone } from 'react-dropzone'
import MuiAlert from "../Alert/Alert";

import "./FileUpload.scss";

type FileUploadProps = {
  heading: string;
  subHeading: string;
  label: string;
  showUploadedImage: boolean;
  acceptedFileTypes: [".mp3", ".m4a", ".wav", ".ds2", ".amr"] | [".doc", ".docx", ".pdf", ".txt"] | [".png", ".jpeg", ".jpg"] | [".mp3", ".m4a", ".wav", ".ds2", ".amr", ".doc", ".docx", ".pdf", ".png", ".jpeg", ".jpg", ".txt"]
  onChange: CallableFunction;
  defaultImage?: string;
  multipleFiles?: boolean
}

function FileUpload(props: FileUploadProps) {

  const [file, setFile] = useState("");
  const [media, setMedia] = useState([] as { url: string; base64Url: any; file: {}; duration: number; }[]);
  const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
  const [noOfAcceptedFiles, setnoOfAcceptedFIles] = useState(0);

  const onDrop = async (acceptedFiles: File[]) => {
    setMedia([]);
    setnoOfAcceptedFIles(acceptedFiles.length);

    if (acceptedFiles.length > 10) {
      setShowAlert({ isError: true, message: "Maximum File Limit Exceeded", open: true });
      return;
    }

    for (let i = 0; i <= acceptedFiles.length; i++) {
      await toBase64(acceptedFiles[i]).then(async (base64Url: any) => {
        let url = URL.createObjectURL(acceptedFiles[i]);

        let duration = 0;

        if (acceptedFiles[i]?.type?.includes("audio")) {
          duration = await getDuration(url) as any;
        }

        let mediaArr = media;

        mediaArr.push({ url: url, base64Url: base64Url, file: acceptedFiles[i], duration: duration });
        setMedia(mediaArr);
        setFile(url);
      });
      if (acceptedFiles.length === (i + 1)) {
        props.onChange(media);
        setMedia([]);
      }
    }
  }

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, accept: {
      'image/png': props.acceptedFileTypes,
      'text/html': props.acceptedFileTypes,
    }
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      let newShowAlert = showAlert;
      newShowAlert.open = true;
      newShowAlert.message = "Invalid File Types were Rejected"
      newShowAlert.isError = true
      setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
      if (noOfAcceptedFiles > 10) {
        let newShowAlert = showAlert;
        newShowAlert.open = true;
        newShowAlert.message = "Maximum File Limit Exceeded"
        newShowAlert.isError = true
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
      }
    }
  }, [fileRejections]);

  useEffect(() => {
    if (props.defaultImage) {
      setFile(props.defaultImage);
    }
  }, [props.defaultImage]);

  return (
    <label {...getRootProps({ className: 'dropzone' })} htmlFor="file-input" className={`${((props.showUploadedImage && file !== "") ? "edit-upload-alignment" : "alignment")} 
    file-upload-container`} >
      <input {...getInputProps()} style={{ display: 'none' }} />
      {(props.showUploadedImage && file !== "")
        ? (<img className="action" src={file !== "" ? file : EditUserProfile} alt={"Edit"} />)
        : (<></>)
      }
      <MuiAlert error={showAlert?.isError} message={showAlert?.message} open={showAlert?.open} onClose={() => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
      }} />
      <div className={`${((props.showUploadedImage && file !== "")
        ? "edit-upload-content" : "upload-content")}`}>
        <div className="heading">{props.heading}</div>
        <div className="sub-heading">{props.subHeading}</div>
        <div className={`${((props.showUploadedImage && file !== "") ? "edit-file" : "add-file")}`}>
          <div className="label-input" >
            <img className="trash" src={Import} alt={"Trash"} />
          </div>
          <div className="label-input">{isDragActive ? "Drop the files here ..." : props.label}</div>
        </div>
      </div>
    </label>
  );
}

export default FileUpload;