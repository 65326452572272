import { useState } from 'react'
// import Doctor from "../../assets/images/Doctor.png";
import BlueLogo from "../../assets/images/BlueLogo.svg";
import Input from '../../components/Input/Input';
import ApiService from '../../services/ApiService';
import MuiAlert from '../../components/Alert/Alert';
import { useNavigate } from "react-router-dom";
import DocumentButton from '../../components/DocumentButton/DocumentButton';
import { emailValidation, getUrlParams, passwordValidation } from '../../helpers/HelperFunctions';

import "./ResetPassword.scss"

function ResetPassword() {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: "", newPassword: "", confirmPassword: "" });
    const [formDataErrors, setFormDataErrors] = useState({
        emailError: { showError: false, message: "" }, newPasswordError: { showError: false, message: "" },
        confirmPasswordError: { showError: false, message: "" },
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });

    const navigate = useNavigate();

    function passwordConfirm() {

        setLoading(true);

        if (formData.newPassword !== formData.confirmPassword) {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.newPasswordError.showError = true;
            newFormDataErrors.newPasswordError.message = "PASSWORDS DO NOT MATCH";
            newFormDataErrors.confirmPasswordError.showError = true;
            newFormDataErrors.confirmPasswordError.message = "PASSWORDS DO NOT MATCH";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.newPasswordError.showError = false;
            newFormDataErrors.confirmPasswordError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.email.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = true;
            newFormDataErrors.emailError.message = "ENTER YOUR EMAIL ADDRESS";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.emailError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.newPassword.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.newPasswordError.showError = true;
            newFormDataErrors.newPasswordError.message = "ENTER A VALID PASSWORD";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.newPasswordError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (formData.confirmPassword.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.confirmPasswordError.showError = true;
            newFormDataErrors.confirmPasswordError.message = "ENTER A VALID PASSWORD";
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.confirmPasswordError.showError = false;
            setFormDataErrors(prevDetails => ({ ...prevDetails, ...newFormDataErrors }));
        }

        if (!passwordValidation(formData.newPassword?.trim())) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Password must be at least 8 characters long and must also contain an upper case letter, "
                + "a number and a special character";
            newShowAlert.open = true;
            setLoading(false);
            return;
        }

        // if (emailValidation(formData.email?.trim())) {
        //     let newFormDataErrors = formDataErrors;
        //     newFormDataErrors.emailError.showError = true;
        //     newFormDataErrors.emailError.message = "ENTER A VALID EMAIL ADDRESS";
        //     setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        //     setLoading(false);
        //     return;
        // }

        let data = {
            token: getUrlParams().token,
            email: formData.email,
            newPassword: formData.newPassword
        };

        apiService.resetPassword(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = "Password Changed";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));

            setTimeout(() => {
                navigate("/");
            }, 2000);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='reset-password-container'>
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                }} />
            {/* <div className="doctor-content">
                <img className='login-img' src={Doctor} alt="Doctor" />
            </div> */}
            <div className="login-data">
                <img src={BlueLogo} alt="Logo" />
                <div className="text-content">
                    <span className='text-content-header'>Reset Password</span>
                </div>
                <div className="inputs">
                    <Input label='EMAIL ADDRESS' value={formData.email} type='text'
                        showError={formDataErrors.emailError.showError}
                        onChange={(value: string) => {
                            let newFormData = formData;
                            newFormData.email = value;
                            setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                        }} />
                </div>
                <div className="inputs">
                    <Input label='NEW PASSWORD' value={formData.newPassword} type='password'
                        showError={formDataErrors.newPasswordError.showError}
                        onChange={(value: string) => {
                            let newFormData = formData;
                            newFormData.newPassword = value;
                            setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                        }} />
                </div>
                <div className="inputs">
                    <Input label='CONFIRM PASSWORD' value={formData.confirmPassword} type='password'
                        showError={formDataErrors.confirmPasswordError.showError}
                        onChange={(value: string) => {
                            let newFormData = formData;
                            newFormData.confirmPassword = value;
                            setFormData(prevDetails => ({ ...prevDetails, ...newFormData }));
                        }} />
                </div>
                <div className="button-content">
                    <DocumentButton text='Reset Password' loading={loading} onClick={() => { passwordConfirm(); }} />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword