import BlueDot from "../../assets/images/BlueDot.svg";
import RedDot from "../../assets/images/RedDot.svg";
import OrangeDot from "../../assets/images/OrangeDot.svg";
import GreenDot from "../../assets/images/GreenDot.svg";
import { Fragment } from "react";

import "./ReportLegend.scss";

type ReportLegendProps = {
    showLegend: boolean;
    showDraft: boolean;
}

export default function ReportLegend(props: ReportLegendProps) {

    function showLegend() {

        if (!props.showLegend) {
            return <></>
        }

        return (<div className="indicator-box">
            <div className="indicator">
                <span className="indicator-text margin-right">
                    <img className="dot" src={RedDot} alt={"Dot"} />
                    ASSIGNED
                </span>
                <span className="indicator-text margin-right">
                    <img className="dot" src={BlueDot} alt={"Dot"} />
                    PENDING
                </span>
                {props.showDraft
                    ? (<span className="indicator-text margin-right">
                        <img className="dot" src={OrangeDot} alt={"Dot"} />
                        DRAFT
                    </span>)
                    : (<Fragment />)
                }
                <span className="indicator-text margin-right">
                    <img className="dot" src={GreenDot} alt={"Dot"} />
                    CHANGES REQUESTED
                </span>
            </div>
        </div>)
    }

    return showLegend();
}