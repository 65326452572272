import "./Tabs.scss";

export default function Tabs(props: { titles: string[], selectedIndex: number; onClick: CallableFunction }) {
    return (<div className="tabs-container">
        {props.titles.map((name: string, index: number) => {
            return (<div className={index === props.selectedIndex ? "tab active" : "tab"} key={index}
                onClick={() => { props.onClick(index) }} style={{ marginLeft: index === 0 ? "0px" : "10px" }}>
                {name}
            </div>)
        })}
    </div>)
}