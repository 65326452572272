import { useState } from "react";
import { EditAccessAccordion } from "./EditAccessAccordion";

export function EditAccess() {

    const [accordionStates, setAccordionStates] = useState({
        Scribe: false, Proof: false, Review: false,
        Admin: false, Manager: false, User: false
    });

    return (
        <div className="accordion-content">
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"Admin"} label={"Admin"} />
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"Scribe"} label={"Scribe"} />
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"Proof"} label={"Proof"} />
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"Review"} label={"Review"} />
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"Manager"} label={"Doctor"} />
            <EditAccessAccordion accordionStates={accordionStates} setAccordionStates={setAccordionStates}
                accordion={"User"} label={"Staff Member"} />
        </div>
    );
}


