import { IReferrer } from "../../helpers/Interfaces";
import Trash from "../../assets/images/Trash.svg";
import { validStringField } from "../../helpers/HelperFunctions";
import { useState } from "react";
import { ReferrerFilterTypes } from "../../helpers/Types";
import SearchableDown from "../../components/DropDown/DropDown";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import EditModal from "../../assets/images/EditModal.svg";

type ReferrerUIProps = {
    referrers: IReferrer[];
    setSelectedReferrer: CallableFunction;
    openModal: (type: "Clinic" | "Doctor" | "Support Staff" | "Referrer", modal: "add" | "edit" | "view" | "delete") => void;
    filter: (filterBy: ReferrerFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: CallableFunction;
}

export default function ReferrerUI(props: ReferrerUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<ReferrerFilterTypes>("name");

    const handleEdit = (referrer: IReferrer) => {
        props.setSelectedReferrer(referrer);
        props.openModal("Referrer", "edit");
    }

    const handleDelete = (referrer: IReferrer) => {
        props.setSelectedReferrer(referrer);
        props.openModal("Referrer", "delete");
    }

    const handleSearchOpen = (filterBy: ReferrerFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.referrers.forEach(referrer => {
            if (referrer[selectedFilter] != null) {
                valueArr.push(referrer[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table">
        <div className="header">
            <div className="header-row">
                <span className="text">Referrer</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("name") }} />
                <div className="filter-content">
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                        : <div className="filter-icon" />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.referrers?.map((referrer) => {
                return (<div key={referrer.id} className="data-row">
                    <div className="cell text">
                        {validStringField(referrer.name)}
                        <div className="icon-container">
                            <img className="icon-edit space" src={EditModal} alt={"Edit"} onClick={() => handleEdit(referrer)} />
                            <img className="icon space" src={Trash} alt={"Delete"} onClick={() => handleDelete(referrer)} />
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}