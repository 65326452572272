import { useState } from 'react'
import "./DeletePrompt.scss"
import Modal from "../Modal/Modal";
import TrashRedBack from "../../assets/images/TrashRedBack.svg";
import DocumentButton from '../DocumentButton/DocumentButton';
import { IAlert } from '../../helpers/Interfaces';

type DeletePromptProps = {
    close: () => void;
    open: boolean;
    header: string;
    body: string;
    onDelete: () => void;
    loading: boolean;
}

function DeletePrompt(props: DeletePromptProps) {

    const [showAlert, setShowAlert] = useState({} as IAlert);

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    const handleClose = () => {
        props.close();
    }

    const handleDelete = () => {
        props.onDelete();
    }

    return (
        <Modal open={props.open} close={handleClose} styles={'card-delete'} showAlert={showAlert}
            closeAlert={closeAlert} editable={false}>
            <div className="delete-doc-container">
                <img className='trash-icon' src={TrashRedBack} alt="TrashRedBack" />
                <div className="content">
                    <label className='header'>{props.header}</label>
                    <label className='body'>{props.body}</label>
                </div>
                <div className="footer">
                    <span onClick={handleClose} className="cancel">Cancel</span>
                    <DocumentButton backgroundColor='#dd3a3a' text={'Delete'} onClick={handleDelete} loading={props.loading} />
                </div>
            </div>
        </Modal>
    )
}

export default DeletePrompt