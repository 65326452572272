import { Fragment, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import NewButton from "../../components/NewButton/NewButton";
import Tabs from "../../components/Tabs/Tabs";
import AddClinic from "../../components/AddClinic/AddClinic";
import AddDoctor from "../../components/AddDoctor/AddDoctor";
import AddStaffMember from "../../components/AddStaffMember/AddStaffMember";
import ViewClinic from "../../components/ViewClinic/ViewClinic";
import ViewDoctor from "../../components/ViewDoctor/ViewDoctor";
import ViewStaff from "../../components/ViewStaff/ViewStaff";
import EditClinic from "../../components/EditClinic/EditClinic";
import EditDoctor from "../../components/EditDoctor/EditDoctor";
import EditStaffMember from "../../components/EditStaffMember/EditStaffMember";
import ClinicsUI from "./ClincsUI";
import { IClinic, IDoctor, IReferrer, ISupportStaff } from "../../helpers/Interfaces";
import DoctorsUI from "./DoctorsUI";
import SupportStaffUI from "./SupportStaffUI";
import ApiService from "../../services/ApiService";
import MuiAlert from "../../components/Alert/Alert";
import { ClinicFilterTypes, DoctorFilterTypes, ReferrerFilterTypes, StaffFilterTypes } from "../../helpers/Types";
import DeletePrompt from "../../components/DeletePrompt/DeletePrompt";
import ReferrerUI from "./ReferrerUI";
import AddReferrer from "../../components/AddReferrer/AddReferrer";
import EditReferrer from "../../components/EditReferrer/EditReferrer";

import "./Doctors.scss";

export default function Doctors() {

    const apiService = new ApiService();

    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([] as IClinic[]);
    const [doctors, setDoctors] = useState([] as IDoctor[]);
    const [supportStaff, setSupportStaff] = useState([] as ISupportStaff[]);
    const [referrers, setReferrers] = useState([] as IReferrer[]);
    const [selectedClinic, setSelectedClinic] = useState({} as IClinic)
    const [selectedDoctor, setSelectedDoctor] = useState({} as IDoctor)
    const [selectedSupportStaff, setSelectedSupportStaff] = useState({} as ISupportStaff)
    const [selectedReferrer, setSelectedReferrer] = useState({} as IReferrer)
    const [filteredClinics, setFilteredClinics] = useState([] as IClinic[]);
    const [filteredDoctors, setFilteredDoctors] = useState([] as IDoctor[]);
    const [filteredSupportStaff, setFilteredStaff] = useState([] as ISupportStaff[]);
    const [filteredReferrers, setFilteredReferrers] = useState([] as IReferrer[]);
    const [clinicModals, setClinicModals] = useState({ add: false, edit: false, view: false, delete: false });
    const [doctorModals, setDoctorModals] = useState({ add: false, edit: false, view: false, delete: false });
    const [supportStaffModals, setSupportStaffModals] = useState({ add: false, edit: false, view: false, delete: false });
    const [referrerModals, setRefererModals] = useState({ add: false, edit: false, view: false, delete: false });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [showCancelFilter, setShowCancelFilter] = useState(false);

    useEffect(() => {
        getClinics();
        getDoctors();
        getSupportStaff();
        getReferrers();
    }, [])

    useEffect(() => {
        if (clinics?.length !== filteredClinics?.length) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filteredClinics])

    useEffect(() => {
        if (doctors?.length !== filteredDoctors?.length) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filteredDoctors])

    useEffect(() => {
        if (supportStaff?.length !== filteredSupportStaff?.length) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filteredSupportStaff])

    useEffect(() => {
        if (referrers?.length !== filteredReferrers?.length) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filteredReferrers])

    function resetDefault() {
        setFilteredClinics(clinics);
        setFilteredDoctors(doctors);
        setFilteredStaff(supportStaff);
        setFilteredReferrers(referrers);
    }

    const handleOpen = (type: "Clinic" | "Doctor" | "Support Staff" | "Referrer", modal: "add" | "edit" | "view" | "delete") => {

        if (type === "Clinic") {
            let newClinicModals = clinicModals;
            newClinicModals[modal] = true;
            setClinicModals((prevDetails) => ({ ...prevDetails, ...newClinicModals }));
        }

        if (type === "Doctor") {
            let newDoctorModals = doctorModals;
            newDoctorModals[modal] = true;
            setDoctorModals((prevDetails) => ({ ...prevDetails, ...newDoctorModals }));
        }

        if (type === "Support Staff") {
            let newSupportStaffModals = supportStaffModals;
            newSupportStaffModals[modal] = true;
            setSupportStaffModals((prevDetails) => ({ ...prevDetails, ...newSupportStaffModals }));
        }

        if (type === "Referrer") {
            let newReferrer = referrerModals;
            newReferrer[modal] = true;
            setRefererModals((prevDetails) => ({ ...prevDetails, ...newReferrer }));
        }
    }

    const handleClose = (type: "Clinic" | "Doctor" | "Support Staff" | "Referrer", modal: "add" | "edit" | "view" | "delete",
        shouldUpdate = false) => {
        if (type === "Clinic") {
            let newClinicModals = clinicModals;
            newClinicModals[modal] = false;
            setClinicModals((prevDetails) => ({ ...prevDetails, ...newClinicModals }));

            if (shouldUpdate) {
                getClinics();
            }
        }

        if (type === "Doctor") {
            let newDoctorModals = doctorModals;
            newDoctorModals[modal] = false;
            setDoctorModals((prevDetails) => ({ ...prevDetails, ...newDoctorModals }));

            if (shouldUpdate) {
                getDoctors();
            }
        }

        if (type === "Support Staff") {
            let newSupportStaffModals = supportStaffModals;
            newSupportStaffModals[modal] = false;
            setSupportStaffModals((prevDetails) => ({ ...prevDetails, ...newSupportStaffModals }));

            if (shouldUpdate) {
                getSupportStaff();
            }
        }

        if (type === "Referrer") {
            let newReferrerModals = referrerModals;
            newReferrerModals[modal] = false;
            setRefererModals((prevDetails) => ({ ...prevDetails, ...newReferrerModals }));

            if (shouldUpdate) {
                getReferrers();
            }
        }
    }

    function filterClinics(filterBy: ClinicFilterTypes, query: string) {
        let filteredList = filteredClinics.filter((clinic) => {
            return clinic[filterBy] === query
        })

        setFilteredClinics(filteredList);
    }

    function filterDoctors(filterBy: DoctorFilterTypes, query: string) {
        let filteredList = filteredDoctors.filter((doctor) => {
            return doctor[filterBy] === query
        })

        setFilteredDoctors(filteredList);
    }

    function filterSupportStaff(filterBy: StaffFilterTypes, query: string) {
        let filteredList = filteredSupportStaff.filter((staff) => {
            return staff[filterBy] === query
        })

        setFilteredStaff(filteredList);
    }

    function filterReferrers(filterBy: ReferrerFilterTypes, query: string) {
        let filteredList = filteredReferrers.filter((referrer) => {
            return referrer[filterBy] === query
        })

        setFilteredReferrers(filteredList);
    }

    function getSupportStaff() {

        setLoading(true);

        apiService.getSupportStaff().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setSupportStaff(data?.staff);
            setFilteredStaff(data?.staff);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getDoctors() {
        setLoading(true);

        apiService.getDoctors().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setDoctors(data?.doctors);
            setFilteredDoctors(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getClinics() {

        setLoading(true);

        apiService.getClinics().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setClinics(data?.clinics);
            setFilteredClinics(data?.clinics);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getReferrers() {

        setLoading(true);

        apiService.getReferrers().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setReferrers(data?.referrers);
            setFilteredReferrers(data?.referrers);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }


    function deleteClinic() {

        setLoading(true);

        let data = {
            clinicId: selectedClinic.id
        }

        apiService.deleteClinic(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedClinic.clinicName + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose("Support Staff", "delete", true);
            handleClose("Doctor", "delete", true);
            handleClose("Clinic", "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function deleteDoctor() {

        setLoading(true);

        let data = {
            doctorId: selectedDoctor.id
        }

        apiService.deleteDoctor(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedDoctor.firstName + " " + selectedDoctor.lastName + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose("Support Staff", "delete", true);
            handleClose("Doctor", "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function deleteStaffMember() {

        setLoading(true);

        let data = {
            staffMemberId: selectedSupportStaff.id
        }

        apiService.deleteSupportStaff(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedSupportStaff.firstName + " " + selectedSupportStaff.lastName + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose("Support Staff", "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function deleteReferrer() {

        setLoading(true);

        let data = {
            referrerId: selectedReferrer.id
        }

        apiService.deleteReferrer(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = selectedReferrer.name + " Deleted";
            newShowAlert.open = true;
            setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose("Referrer", "delete", true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (<Layout selectedIndex={2}>
        <div className="doctors-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert, }));
                }} />
            <div className="top-bar">
                <div className="row">
                    <span className="text">Doctors</span>
                </div>
            </div>
            <div className="doctor-table-container">
                <div className="row tab-selector">
                    <Tabs titles={["Clinics", "Doctor", "Support Staff", "Referrer"]} selectedIndex={selectedTab} onClick={setSelectedTab} />
                    {selectedTab === 0
                        ? (<NewButton text="Add Clinic" onClick={() => { handleOpen("Clinic", "add") }} />)
                        : (<Fragment />)
                    }
                    {selectedTab === 1
                        ? (<NewButton text="Add Doctor" onClick={() => { handleOpen("Doctor", "add") }} />)
                        : (<Fragment />)
                    }
                    {selectedTab === 2
                        ? (<NewButton text="Add Support Staff" onClick={() => { handleOpen("Support Staff", "add") }} />)
                        : (<Fragment />)
                    }
                    {selectedTab === 3
                        ? (<NewButton text="Add Referrer" onClick={() => { handleOpen("Referrer", "add") }} />)
                        : (<Fragment />)
                    }
                </div>

                {selectedTab === 0
                    ? (<ClinicsUI cancelFilter={showCancelFilter} resetDefault={resetDefault} clinics={filteredClinics}
                        setSelectedClinic={setSelectedClinic} openModal={handleOpen} filter={filterClinics} />)
                    : (<Fragment />)
                }
                {selectedTab === 1
                    ? (<DoctorsUI cancelFilter={showCancelFilter} resetDefault={resetDefault} doctors={filteredDoctors}
                        setSelectedDoctor={setSelectedDoctor} openModal={handleOpen} filter={filterDoctors} />)
                    : (<Fragment />)
                }
                {selectedTab === 2
                    ? (<SupportStaffUI cancelFilter={showCancelFilter} resetDefault={resetDefault} staff={filteredSupportStaff}
                        setSelectedSupportStaff={setSelectedSupportStaff} openModal={handleOpen} filter={filterSupportStaff} />)
                    : (<Fragment />)
                }
                {selectedTab === 3
                    ? (<ReferrerUI cancelFilter={showCancelFilter} resetDefault={resetDefault} referrers={filteredReferrers}
                        setSelectedReferrer={setSelectedReferrer} openModal={handleOpen} filter={filterReferrers} />)
                    : (<Fragment />)
                }
            </div>

            {clinicModals.add
                ? (<AddClinic open={clinicModals.add} close={handleClose} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {doctorModals.add
                ? (<AddDoctor open={doctorModals.add} close={handleClose} clinics={clinics} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {supportStaffModals.add
                ? (<AddStaffMember open={supportStaffModals.add} close={handleClose} doctors={doctors} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {referrerModals.add
                ? (<AddReferrer open={referrerModals.add} close={handleClose} showAlert={setShowAlert} />)
                : (<Fragment />)
            }

            {clinicModals.view
                ? (<ViewClinic open={clinicModals.view} close={handleClose} selectedClinic={selectedClinic} openEdit={handleOpen} />)
                : (<Fragment />)
            }
            {doctorModals.view
                ? (<ViewDoctor open={doctorModals.view} close={handleClose} selectedDoctor={selectedDoctor} openEdit={handleOpen} />)
                : (<Fragment />)
            }
            {supportStaffModals.view
                ? (<ViewStaff open={supportStaffModals.view} close={handleClose} selectedStaffMember={selectedSupportStaff}
                    openEdit={handleOpen} />)
                : (<Fragment />)
            }

            {clinicModals.edit
                ? (<EditClinic open={clinicModals.edit} close={handleClose} selectedClinic={selectedClinic} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {doctorModals.edit
                ? (<EditDoctor open={doctorModals.edit} close={handleClose} selectedDoctor={selectedDoctor} clinics={clinics}
                    showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {supportStaffModals.edit
                ? (<EditStaffMember open={supportStaffModals.edit} close={handleClose} selectedStaffMemberId={selectedSupportStaff.id}
                    doctors={doctors} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {referrerModals.edit
                ? (<EditReferrer open={referrerModals.edit} close={handleClose} showAlert={setShowAlert}
                    selectedReferrer={selectedReferrer} />)
                : (<Fragment />)
            }

            {clinicModals.delete
                ? (<DeletePrompt loading={loading} close={() => { handleClose("Clinic", "delete", false) }} open={clinicModals.delete}
                    header={"Delete " + selectedClinic.clinicName + "?"} onDelete={deleteClinic}
                    body={"This will result in the removal of all associated doctors, appointments, report types, templates, and " +
                        "support staff. Please note that this action is irreversible. Are you certain you wish " +
                        "to proceed with this operation?"} />)
                : (<Fragment />)
            }
            {doctorModals.delete
                ? (<DeletePrompt loading={loading} close={() => handleClose("Doctor", "delete", false)} open={doctorModals.delete}
                    header={"Delete " + selectedDoctor.firstName + " " + selectedDoctor.lastName + "?"}
                    body={"This will result in the removal of all associated appointments and support staff. " +
                        "Please note that this action is irreversible. Are you certain you wish to proceed with this operation?"}
                    onDelete={deleteDoctor} />)
                : (<Fragment />)
            }
            {supportStaffModals.delete
                ? (<DeletePrompt loading={loading} close={() => handleClose("Support Staff", "delete", false)}
                    open={supportStaffModals.delete} onDelete={deleteStaffMember} header={"Delete " + selectedSupportStaff.firstName +
                        " " + selectedSupportStaff.lastName + "?"}
                    body={"Please note that this action is irreversible. Are you certain you wish to proceed with this operation?"} />)
                : (<Fragment />)
            }
            {referrerModals.delete
                ? (<DeletePrompt loading={loading} close={() => { handleClose("Referrer", "delete", false) }} open={referrerModals.delete}
                    header={"Delete " + selectedReferrer.name + "?"} onDelete={deleteReferrer}
                    body={"This will result in the removal of this Referrer"} />)
                : (<Fragment />)
            }
        </div>
    </Layout>)
}