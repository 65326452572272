import EditIcon from "../../assets/images/Edit.svg";
import Trash from "../../assets/images/Trash.svg";
import { IReportType } from "../../helpers/Interfaces";
import Show from "../../components/Show/Show";
import { validStringField } from "../../helpers/HelperFunctions";
import { ReportTemplateFilterTypes } from "../../helpers/Types";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import SearchableDown from "../../components/DropDown/DropDown";

type ReportTypeUIProps = {
    canEdit: boolean;
    canDelete: boolean;
    reportTypes: IReportType[];
    setSelectedReportType: Dispatch<SetStateAction<IReportType>>;
    handleModals: (open: boolean, modal: "add" | "edit" | "delete", shouldUpdate?: boolean) => void;
    filter: (filterBy: ReportTemplateFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: () => void;
}

export default function ReportTypesUI(props: ReportTypeUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<ReportTemplateFilterTypes>("reportName");

    const handleClick = useCallback((selectedReport: IReportType) => {
        props.setSelectedReportType(selectedReport);
        props.handleModals(true, "edit");
    }, []);

    const handleSearchOpen = (filterBy: ReportTemplateFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleDelete = useCallback((report: IReportType) => {
        props.setSelectedReportType(report);
        props.handleModals(true, "delete");
    }, []);

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.reportTypes.forEach(report => {
            if (report[selectedFilter] != null) {
                valueArr.push(report[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table">
        <div className="header">
            <div className="header-row">
                <span className="text">Report Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("reportName") }} />
            </div>
            <div className="header-row">
                <span className="text">Doctor</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("doctorName") }} />
            </div>
            <div className="header-row">
                <span className="text">Clinic</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("clinicName") }} />
                <div className="size-box" />
                <div className="filter-content">
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                        : <div className="filter-icon" />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.reportTypes.map((reportType) => {
                return (<div key={reportType.id} className="data-row">
                    <div className="cell text">{validStringField(reportType.reportName)}</div>
                    <div className="cell text">{validStringField(reportType.doctorName)}</div>
                    <div className="cell text">
                        {validStringField(reportType.clinicName)}
                        <div className="icon-container">
                            <Show hasAccess={props.canEdit}>
                                <img className="icon" src={EditIcon} alt={"Edit"} onClick={() => { handleClick(reportType); }} />
                            </Show>
                            <Show hasAccess={props.canDelete} >
                                <img className="icon space" src={Trash} alt={"Delete"}
                                    onClick={() => { handleDelete(reportType) }} />
                            </Show>
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}