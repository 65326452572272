import { Fragment, useCallback } from 'react'
import { convertDuration, divideCompletedStages, showEditDocument, validDateField, validStringField } from '../../helpers/HelperFunctions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DocumentButton from '../DocumentButton/DocumentButton';
import Show from '../Show/Show';
import { IReport } from '../../helpers/Interfaces';

type DocumentDetailsViewProps = {
    readonly report: IReport;
    readonly openEdit: () => void;
    readonly loading: boolean;
    readonly canEdit: boolean;
    readonly handleReopenDocument: () => void;
}

function DocumentDetailsView(props: DocumentDetailsViewProps) {

    const handleClick = useCallback(() => {
        props.openEdit();
    }, [])

    return (
        <div className="report-view">
            <div className='content'>
                <div className="row">
                    {DataView("Patient Name", validStringField(props.report.patientName))}
                    {DataView("Doctor", validStringField(props.report.doctorName))}
                    {DataView("Clinic", validStringField(props.report.clinicName))}
                    {DataView("Report Type", validStringField(props.report.reportType))}
                    {DataView("Created Date", validDateField(props.report.createdDate))}
                    {DataView("Due Date", validDateField(props.report.dueDate))}
                    {DataView("Place of Assessment", validStringField(props.report.placeOfAssessment))}
                    {DataView("Date of Assessment", validDateField(props.report.dateOfAssessment))}
                    {DataView("Priority", validStringField(props.report.priority))}
                    {DataView("Status", validStringField(props.report.documentStatus))}
                    {DataView("Completed Stages", divideCompletedStages(props.report.completedStages))}
                    {DataView("Stage", validStringField(props.report.stage))}
                    {DataView("Assigned To", validStringField(props.report.assignee))}
                    {DataView("Provider Number", validStringField(props.report.providerNumber))}
                    {(DataView("Submitted Date", validDateField(props.report.submittedDate)))}
                    {(DataView("Completed Date", validDateField(props.report.completedDate)))}
                    {DataView("Referrer", validStringField(props.report.referrer))}
                    {DataView("Duration", convertDuration(props.report.totalDuration))}
                    {DataView("Report Name", validStringField(props.report.reportName))}
                </div>
                <div className="row">
                    {CommentView("Comments", validStringField(props.report.notes))}
                </div>
            </div>

            <div className="footer-doc">
                <Show hasAccess={props.canEdit}>
                    {showEditDocument(props.report.documentStatus) && (props.report.documentStatus != "Submitted"
                        && props.report.documentStatus != "Completed")
                        ? (<div className="footer-btn">
                            <DocumentButton loading={props.loading} onClick={handleClick} text={"Edit"} />
                        </div>)
                        : (<Fragment />)
                    }
                </Show>
                <div className="footer-btn">
                    {props.report.documentStatus === 'Completed' ? <DocumentButton loading={props.loading} onClick={props.handleReopenDocument} text={"Reopen Document"} /> : <></>}
                </div>
            </div>
        </div>
    );
}


function DataView(header: string, data: string) {
    return (<div className="data-view">
        <span className="header">{header}</span>
        <span className="data">{data}</span>
    </div>)
}

function CommentView(header: string, data: string) {
    return (<div className="data-view">
        <span className="header">{header}</span>
        <span className="comment" style={{ whiteSpace: "pre-wrap" }}>{data}</span>
    </div>)
}

export default DocumentDetailsView