import { Modal } from "@mui/material";
import { ReactElement, Fragment } from "react";
import CloseIcon from "../../assets/images/Close.svg";
import EditModal from "../../assets/images/EditModal.svg";
import MuiAlert from "../Alert/Alert";
import { IAlert } from "../../helpers/Interfaces";

import "./Modal.scss";

type MUIModalProps = {
  open: boolean;
  close: CallableFunction;
  children: ReactElement;
  styles: string;
  showAlert: IAlert;
  closeAlert: CallableFunction;
  editable: boolean;
  openEdit?: () => void;
}

export default function MUIModal(props: MUIModalProps) {

  function closeAlert() {
    props.closeAlert()
  }

  return (
    <Modal open={props.open} classes={{ root: "modal-container" }}>
      <>
        <MuiAlert error={props.showAlert.isError} message={props.showAlert.message} open={props.showAlert.open} onClose={closeAlert} />
        <div className={`card ${props.styles}`}>
          <div className="close">
            {props.editable ? <img className="img edit" src={EditModal} alt={"Close"}
              onClick={() => props.openEdit?.()} /> : <Fragment />}
            <img className="img" src={CloseIcon} alt={"Close"}
              onClick={() => { props.close(); }} />
          </div>
          {props.children}
        </div>
      </>
    </Modal >
  );
}
