import { Fragment, useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import Close from "../../assets/images/Close.svg";
import { FirebaseUser, User } from "../../store/Store";
import NotificationIcon from "../../assets/images/NotificationIcon.svg";
import moment from 'moment';
import { updateFirebaseUserNotifications } from '../../helpers/FirebaseFunctions';
import { useNavigate } from "react-router-dom";
import { INotification } from '../../helpers/Interfaces';
import Tabs from '../../components/Tabs/Tabs';

import "./Notifications.scss";

function NotificationsUI() {

    const [selectedTab, setSelectedTab] = useState(0);
    const [newNotifications, setNewNotifications] = useState([] as INotification[]);
    const [readNotifications, setReadNotifications] = useState([] as INotification[]);

    const [user] = useContext(User);
    const [firebaseUser] = useContext(FirebaseUser);

    const navigate = useNavigate();

    useEffect(() => {
        let newNotifications = firebaseUser?.notifications?.filter((notification: INotification) => {
            return !notification.read
        });

        let readNotifications = firebaseUser?.notifications?.filter((notification: INotification) => {
            return notification.read
        });

        setNewNotifications(newNotifications);
        setReadNotifications(readNotifications);
    }, [firebaseUser])

    async function markAsRead(id: number) {

        let notification: INotification = firebaseUser?.notifications.find((notification: INotification) => {
            return notification?.id === id;
        });

        Object.assign(notification, { read: true });

        let newNotifications: INotification[] = firebaseUser?.notifications.filter((notification: INotification) => {
            return notification?.id !== id;
        });

        newNotifications.push(notification);
        await updateFirebaseUserNotifications(user?.notificationId, newNotifications);
    }

    async function removeNotification(id: number) {
        let newNotifications: INotification[] = firebaseUser?.notifications.filter((notification: INotification) => {
            return notification?.id !== id;
        });

        await updateFirebaseUserNotifications(user?.notificationId, newNotifications);
    }

    async function clearNotifications() {

        let newNotifications: INotification[] = [];

        if (selectedTab === 0) {
            newNotifications = firebaseUser?.notifications.filter((notification: INotification) => {
                return notification?.read;
            });
        } else if (selectedTab === 1) {
            newNotifications = firebaseUser?.notifications.filter((notification: INotification) => {
                return !notification?.read;
            });
        }

        await updateFirebaseUserNotifications(user?.notificationId, newNotifications);
    }

    function openViewReport(notificationId: number, documentId: string) {
        markAsRead(notificationId);
        navigate(`/dashboard/reports/${documentId}`);
    }

    function renderNotifications(notifications: INotification[]) {
        return (notifications?.map((notification: INotification, index: number) => {
            return (<div key={notification?.id + " - " + (index + 1)} className="notification-content">
                <div className="data">
                    <img className='profile-img' src={NotificationIcon} alt="Information" />
                    <span className="message">{notification?.text}</span>
                </div>
                <div className="other-data">
                    <span className="time">{moment.utc(notification?.createdDate?.seconds * 1000).fromNow()}</span>
                    <div className='action-btn-area'>
                        <div onClick={() => { openViewReport(notification?.id, notification?.documentId) }}
                            className="view-report">
                            View Report
                        </div>
                        <span className="close-notification">
                            <img className='close' src={Close} alt="Close"
                                onClick={() => {
                                    selectedTab === 0
                                        ? markAsRead(notification?.id)
                                        : removeNotification(notification?.id)
                                }} />
                        </span>
                    </div>
                </div>
            </div>)
        }))
    }

    return (<Layout selectedIndex={-1}>
        <div className='notification-container'>
            <div className="header">
                <div className="title">Notifications</div>
            </div>
            <div className="notification-body">
                <div className="notification-header tab-selector">
                    <Tabs titles={['New', 'History']} selectedIndex={selectedTab} onClick={setSelectedTab} />
                    <button onClick={() => { clearNotifications() }}>Clear All</button>
                </div>
                <div className="content">
                    {selectedTab == 0
                        ? (renderNotifications(newNotifications))
                        : (<Fragment />)
                    }
                    {selectedTab == 1
                        ? (renderNotifications(readNotifications))
                        : (<Fragment />)
                    }
                </div>
            </div>
        </div>
    </Layout>)
}

export default NotificationsUI