import { onSnapshot, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { deleteObject, getBlob, getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage } from "../services/FirebaseService";
import { sortNotificationsByDate, toBase64 } from "./HelperFunctions";
import { IFile, INotification } from "./Interfaces";
import { getAuth, signInAnonymously, signInWithCustomToken, signOut } from "firebase/auth";

export async function firebaseSignIn(token: string) {

    const auth = getAuth();

    let loggedIn = false;

    await signInAnonymously(auth).then(() => {
        loggedIn = true;
    }).catch((e: any) => {
        loggedIn = false;
    });

    // await signInWithCustomToken(auth, token).then(() => {
    //     loggedIn = true;
    // }).catch(() => {
    //     loggedIn = false;
    // });

    return loggedIn;
}

export async function downloadDocument(props: { fileName: string, downloadingName: string }) {
    getBlob(ref(storage, `/media/${props.fileName}`)).then(async (value) => {

        let base64: any = await toBase64(value);

        let link = document.createElement("a");

        if (link.download !== undefined) {
            link.download = props.downloadingName
            link.href = base64;
            link.click();
            link.remove();
        }
    }).catch((error) => {
    });
}


export async function firebaseSignOut() {

    const auth = getAuth();

    signOut(auth);
}

export async function removeMedia(fileName: string, storageBucket?: string) {
    let bucket = storageBucket ?? 'media';
    const storageRef = ref(storage, `/${bucket}/${fileName}`);

    let state = false;

    await deleteObject(storageRef).then(() => {
        state = true;
    }).catch(() => {
        state = false;
    })

    return state;
}

export async function addMedia(file: string, fileName: string) {

    const storageRef = ref(storage, `/media/${fileName}`);
    let downloadLink = "";

    await uploadString(storageRef, file, "data_url").then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then((link: string) => {
            downloadLink = link;
        })
    })

    return downloadLink;
}

export async function updateDocument(docId: string, document: string) {
    try {
        await updateDoc(doc(firestore, "documents", docId), { document: document });
        return true;
    } catch (err) {
        return false;
    }
}

export async function getMediaDocument(docId: string, setMedia: CallableFunction) {
    try {
        const docRef = doc(firestore, "media", docId);
        const docSnap = await getDoc(docRef);

        setMedia(docSnap.data()?.media);

        return true;
    } catch (err) {
        return false;
    }
}

export async function getDocument(docId: string, setReport: CallableFunction) {
    try {
        const docRef = doc(firestore, "documents", docId);
        const docSnap = await getDoc(docRef);

        setReport(docSnap?.data());

        return true;
    } catch (err) {
        return false;
    }
}

export async function getTemplate(docId: string, setTemplate: CallableFunction) {
    try {
        const docRef = doc(firestore, "templates", docId);
        const docSnap = await getDoc(docRef);

        const docSnapData = docSnap.data();
        setTemplate(docSnapData?.document);

        return true;
    } catch (err) {
        return false;
    }
}

export async function saveTemplates(docId: string, template: string) {
    try {
        await setDoc(doc(firestore, "templates", docId), { template: template });
        return true;
    } catch (err) {
        return false;
    }
}

export async function getFirebaseUser(docId: string, setFirebaseUser: CallableFunction) {
    onSnapshot(doc(firestore, "users", docId), ((snapshot) => {
        let data = snapshot.data() as any;

        let sortedNotifications = sortNotificationsByDate(data?.notifications)
        Object.assign(data, { notifications: sortedNotifications });

        setFirebaseUser((data));
    }));
}

export async function updateFirebaseUserNotifications(docId: string, notifications: INotification[]) {
    try {
        await updateDoc(doc(firestore, "users", docId), { notifications: notifications });
        return true;
    } catch (err) {
        return false;
    }
}

export async function updateFirebaseUserRefresh(docId: string, refresh: boolean) {
    try {
        await updateDoc(doc(firestore, "users", docId), { refresh: refresh });
        return true;
    } catch (err) {
        return false;
    }
}

export async function createMediaDocument(docId: string, media: IFile[]) {
    try {
        await setDoc(doc(firestore, "media", docId), { media: media });
        return true;
    } catch (err) {
        return false;
    }
}

export async function addDoctorDocuments(docId: string, media: IFile[]) {
    try {
        await setDoc(doc(firestore, "doctors", docId), { media: media });
        return true;
    } catch (err) {
        return false;
    }
}

export async function getDoctorDocuments(docId: string) {
    try {
        const docRef = doc(firestore, "doctors", docId);
        const docSnap = await getDoc(docRef);
        return docSnap?.data()
    } catch (err) {
        return undefined;
    }
}

export async function downloadMedia(fileName: string, fileId: string, storageBucket?: string) {
    let bucket = storageBucket ?? 'media';

    getBlob(ref(storage, `//${bucket}/${fileId}`)).then(async (value) => {

        let base64: any = await toBase64(value);

        let link = document.createElement("a");

        if (link.download !== undefined) {
            link.setAttribute("href", base64);
            link.setAttribute("download", fileName);
            link.setAttribute("target", "_blank");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }).catch((error) => {
    });
}


export function getBase64(fileName: string, setBase64: CallableFunction) {
    getBlob(ref(storage, `/media/${fileName}`)).then(async (blob) => {
        let base64: any = await toBase64(blob);
        setBase64(base64);
    });
}