import DocumentButton from '../../components/DocumentButton/DocumentButton';
import Email from "../../assets/images/Email.svg";

type EmailSentProps = {
    readonly email: string,
    readonly backToLogin: CallableFunction,
    readonly loading: boolean,

}

function EmailSent({ email, loading, backToLogin }: EmailSentProps
) {
    return (
        <>
            <img className="email-image" src={Email} alt="Email" />

            <div className="text-content">
                <span className='text-content-header'>Check your mail</span>
                <span className='text-content-text'> We have sent you an email that contains a link</span>
                <span className='text-content-text'>to reset your password.</span>
            </div>
            <div className='button-content'>
                <DocumentButton text={"Back To Login Page"} onClick={backToLogin} loading={loading} />
            </div>
            <p className='back-to-login-text'>Did not receive the email? Check your spam <br /> filter or try another email address</p>
            <div className='email-sent-footer'></div>

        </>
    );
}

export default EmailSent