import { useEffect, useState } from 'react'
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import DocumentButton from '../DocumentButton/DocumentButton';
import { IReferrer } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';

import "./EditReferrer.scss";

type EditReferrerProps = {
    open: boolean;
    close: CallableFunction;
    showAlert: CallableFunction;
    selectedReferrer: IReferrer;
};

function EditReferrer(props: EditReferrerProps) {

    const apiService = new ApiService();

    const [formData, setFormData] = useState({ name: "" });
    const [formDataErrors, setFormDataErrors] = useState({ nameError: { showError: false, message: "" } });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let newFormData = formData;
        newFormData.name = props.selectedReferrer.name;
        setFormData((prevDetails) => ({ ...prevDetails, ...newFormData, }));
    }, [props.selectedReferrer]);

    const handleClose = (shouldUpdate: boolean) => {
        clearData();
        props.close("Referrer", "edit", shouldUpdate);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function clearData() {
        let newFormData = formData;
        newFormData.name = "";
        setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData, }));

        let newFormDataErrors = formDataErrors;
        newFormDataErrors.nameError.showError = false;
        setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));

        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));

        setLoading(false);
    }

    function updateReferrer() {

        setLoading(true);

        if (formData.name?.trim() === "") {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.nameError.showError = true;
            newFormDataErrors.nameError.message = "REQUIRED";
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
            setLoading(false);
            return;
        } else {
            let newFormDataErrors = formDataErrors;
            newFormDataErrors.nameError.showError = false;
            setFormDataErrors((prevDetails) => ({ ...prevDetails, ...newFormDataErrors }));
        }

        let data = Object.assign(formData, { id: props.selectedReferrer.id });

        apiService.updateReferrer(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            let newShowAlert = showAlert;
            newShowAlert.isError = false;
            newShowAlert.message = formData.name + " Updated";
            newShowAlert.open = true;
            props.showAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));

            handleClose(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={false} open={props.open} close={handleClose}
            styles={"add-referrer-card"} >
            <div className="edit-referee-container">
                <div className="header-section">
                    <span className="header">Edit Referrer</span>
                </div>
                <div className="body">
                    <Input label='NAME*' value={formData.name} type='text'
                        showError={formDataErrors.nameError.showError}
                        onChange={(value: any) => {
                            let newFormData = formData;
                            newFormData.name = value;
                            setFormData((prevDetails: any) => ({ ...prevDetails, ...newFormData }))
                        }} />
                    <div className="footer">
                        <span onClick={() => { handleClose(false) }} className="cancel-btn">Cancel</span>
                        <div className="button">
                            <DocumentButton loading={loading} onClick={() => { updateReferrer() }} text={"Update Referrer"} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditReferrer