import { Autocomplete, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import DropdownIcon from "../../assets/images/DropdownBlack.svg";
import SearchIcon from "../../assets/images/Search.svg";

import "./InputDropDown.scss"

type InputDropDownProps = {
    onSelect: CallableFunction,
    title: string,
    values: { label: string, value: any }[],
    height?: number,
    type: "search" | "dropdown",
    defaultValue?: any,
    typeable?: boolean,
    showError?: boolean;
    width?: number
}

function InputDropDown(props: InputDropDownProps) {

    const [tempValue, setTempValue] = useState({ label: "", value: "" });

    useEffect(() => {
        if (props.defaultValue) {
            let value = props.values.filter((data: any) => data.value == props.defaultValue)[0];
            setTempValue(value);
        }

        if (props.typeable && props.defaultValue) {
            setTempValue({ label: props.defaultValue, value: '0' });
        }

    }, [props.defaultValue, props.values]);

    return (
        <div className="input-container">
            <Autocomplete disablePortal
                clearIcon
                sx={{
                    height: props.height ? props.height : '45px',
                    width: props.width ? props.width : '125px',
                    '.Mui-focused': { color: "black !important" },
                }}
                onInputChange={(_e: any, value: any) => {
                    if (props.typeable) {
                        if (props.values.filter((e: any) => e.label === value).length > 0) {
                            props.onSelect(props.values.filter((e: any) => {
                                return e.label === value
                            }));
                        } else {
                            if (_e)
                                props.onSelect(_e.target.value, true);
                        }
                        if (_e)
                            setTempValue({ label: _e.target.value, value: '0' });
                    } else {
                        props.onSelect(props.values.filter((e: any) => {
                            return e.label === value
                        }));
                        setTempValue(props.values.filter((val: any) => val.label === value)[0]);
                    }

                    if (props.values.filter((e: any) => e.label === value).length > 0) {
                        setTempValue({ label: value, value: '' });
                    }
                }}
                options={props.values}
                value={tempValue ? tempValue : { label: "", value: "" }}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                label={props.title}
                                id="standard-size-normal"
                                variant="standard"
                                sx={{
                                    "& .MuiAutocomplete-popupIndicator": props.type === 'search'
                                        ? { transform: "none" }
                                        : '',
                                }}
                                classes={{
                                    root: `text-field-base ${props.showError ? 'text-field-error' : ''}`
                                }}
                            />
                        </>
                    )
                }} popupIcon={
                    props.type === 'dropdown'
                        ? (<img className="icon" src={DropdownIcon} alt={"dropdown"} />)
                        : (<img className="icon" src={SearchIcon} alt={"Location"} />)
                }
            />
        </div >
    )
}

export default InputDropDown