import { Fragment, useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import NewButton from "../../components/NewButton/NewButton";
import moment from "moment";
import GreenDot from "../../assets/images/GreenDot.svg";
import RedDot from "../../assets/images/RedDot.svg";
import ArrowRightIcon from "../../assets/images/ArrowRight.svg";
import AddUserModal from "../../components/AddUserModal/AddUserModal";
import EditUserModal from "../../components/EditUserModal/EditUserModal";
import ViewUserModal from "../../components/ViewUserModal/ViewUserModal";
import SearchableDown from "../../components/DropDown/DropDown";
import { IUser } from "../../helpers/Interfaces";
import { divideRoles, validStringField } from "../../helpers/HelperFunctions";
import ApiService from "../../services/ApiService";
import MuiAlert from "../../components/Alert/Alert";
import ProfileImageLoader from "../../components/ProfileImageLoader/ProfileImageLoader";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import { UserFilterTypes } from "../../helpers/Types";

import "./Users.scss";

export default function Users() {

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);
    const [userModals, setUserModals] = useState({ add: false, edit: false, view: false });
    const [users, setUsers] = useState([] as IUser[])
    const [selectedUser, setSelectedUser] = useState({} as IUser)
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [filterUsers, setFilterUsers] = useState([] as IUser[])
    const [showCancelFilter, setShowCancelFilter] = useState([] as any);
    const [selectedFilter, setSelectedFilter] = useState<UserFilterTypes>("firstName");
    const [showSearch, setShowSearch] = useState(true);

    useEffect(() => {
        getUsers();
    }, [])

    useEffect(() => {
        if (users.length !== filterUsers?.length) {
            setShowCancelFilter(true);
        } else {
            setShowCancelFilter(false);
        }
    }, [filterUsers])

    const handleOpen = (modal: "add" | "edit" | "view") => {
        let newUserModals = userModals;
        newUserModals[modal] = true;
        setUserModals((prevDetails) => ({ ...prevDetails, ...newUserModals }));
    }

    const handleClose = useCallback((modal: "add" | "edit" | "view", shouldUpdate = false) => {
        let newUserModals = userModals;
        newUserModals[modal] = false;
        setUserModals((prevDetails) => ({ ...prevDetails, ...newUserModals }));

        if (shouldUpdate) {
            getUsers();
        }
    }, [])

    const handleSearchOpen = (filterBy: UserFilterTypes) => {
        setSelectedFilter(filterBy);
        setShowSearch(true);
    };

    function filter(filterBy: UserFilterTypes, query: string) {
        let filteredList = filterUsers.filter((user) => {
            return user[filterBy] === query
        })

        setFilterUsers(filteredList);
    }

    const handleSelectedItem = (selectedValue: string) => {
        filter(selectedFilter, selectedValue);
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        filterUsers.forEach(user => {
            if (user[selectedFilter] != null) {
                valueArr.push(user[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    const handleClearFilter = () => {
        setFilterUsers(users);
    }

    function getUsers() {

        setLoading(true);

        apiService.getUsers().then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setUsers(data?.users);
            setFilterUsers(data?.users);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (<Layout selectedIndex={3}>
        <div className="users-container">
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert, }));
                }} />
            <div className="top-bar">
                <span className="text">List of Users</span>
                <div className="elements">
                    <NewButton text="Add User" onClick={() => { handleOpen("add") }} />
                </div>
            </div>
            <div className="user-table-container">
              <div className="table">
                  <div className="header">
                      <div className="header-row large">
                          <span className="large-text">Name</span>
                          <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                              setSelectedFilter={function (): void { handleSearchOpen("firstName") }} />
                      </div>
                      <div className="header-row small">
                          <span className="large-text">User Type</span>
                      </div>
                      <div className="header-row small">
                          <span className="large-text">Status</span>
                      </div>
                      <div className="header-row small">
                          <span className="large-text">Last Login</span>
                          <div className="size-box" />
                          <span className="filter-cancel">
                              {showCancelFilter
                                  ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                                  : <div className="filter-icon" />
                              }
                          </span>
                      </div>
                  </div>
                  <div className="data">
                      {filterUsers.map((user) => {
                          return (<div key={user.email} className="data-row">
                              <div className="image-text large">
                                  <ProfileImageLoader name={user.firstName + " " + user.lastName} imageUrl={user.profileImageUrl}
                                      style={"profile-size"} />
                                  <div className="text">
                                      <span className="large-text">{validStringField(user.firstName + " " + user.lastName)}</span>
                                      <span className="small-text">{validStringField(user.email)}</span>
                                  </div>
                              </div>
                              <span className="cell large-text small">{divideRoles(user.roles)}</span>
                              <div className="cell small">
                                  {user.status
                                      ? (<>
                                          <img className="dot" src={GreenDot} alt={"Green"} />
                                          <span className="large-text">Active</span>
                                      </>)
                                      : (<>
                                          <img className="dot" src={RedDot} alt={"Green"} />
                                          <span className="large-text">Inactive</span>
                                      </>)
                                  }
                              </div>
                              <span className="cell large-text small justify ">
                                  <div className="active-time">
                                      {user?.lastLogin
                                      ? (moment.utc(user?.lastLogin).local().fromNow())
                                      : ("-")
                                      }
                                  </div>
                                  <div className="action-icon">
                                      <img className="action" src={ArrowRightIcon} alt={"ArrowRight"}
                                          onClick={() => { setSelectedUser(user); handleOpen("view"); }} />
                                  </div>
                              </span>
                          </div>)
                      })}
                  </div>
              </div>

            </div>
            {userModals.add
                ? (<AddUserModal open={userModals.add} close={handleClose} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {userModals.edit
                ? (<EditUserModal open={userModals.edit} close={handleClose} selectedUser={selectedUser} showAlert={setShowAlert} />)
                : (<Fragment />)
            }
            {userModals.view
                ? (<ViewUserModal open={userModals.view} close={handleClose} selectedUser={selectedUser} openEdit={handleOpen} />)
                : (<Fragment />)
            }
        </div>
    </Layout>)
}