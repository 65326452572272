import Logo from "../../assets/images/Logo.svg";
import LogoSmall from "../../assets/images/LogoSmall.svg";
import ActiveHome from "../../assets/images/sidebar/active/Home.svg";
import InactiveHome from "../../assets/images/sidebar/inactive/Home.svg";
import ActiveReports from "../../assets/images/sidebar/active/Reports.svg";
import InactiveReports from "../../assets/images/sidebar/inactive/Reports.svg";
import ActiveDoctors from "../../assets/images/sidebar/active/Doctors.svg";
import InactiveDoctors from "../../assets/images/sidebar/inactive/Doctors.svg";
import ActiveUsers from "../../assets/images/sidebar/active/Users.svg";
import InactiveUsers from "../../assets/images/sidebar/inactive/Users.svg";
import ActiveTemplates from "../../assets/images/sidebar/active/Templates.svg";
import InactiveTemplates from "../../assets/images/sidebar/inactive/Templates.svg";
import ActiveAnalytics from "../../assets/images/sidebar/active/Analytics.svg";
import InactiveAnalytics from "../../assets/images/sidebar/inactive/Analytics.svg";
import ActiveSettings from "../../assets/images/sidebar/active/Settings.svg";
import InactiveSettings from "../../assets/images/sidebar/inactive/Settings.svg";
import ActiveNotification from "../../assets/images/sidebar/active/Notification.svg";
import InactiveNotification from "../../assets/images/sidebar/inactive/Notification.svg";
import InactiveKnowledgeBase from "../../assets/images/sidebar/inactive/KnowledgeBase.svg";
import ActiveKnowledgeBase from "../../assets/images/sidebar/active/KnowledgeBase.svg";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useContext, useState } from "react";
import LogoutIcon from "../../assets/images/Logout.svg";
import { getCurrentDimension, isInRole, removeTokens, separateRoles } from "../../helpers/HelperFunctions";
import { FirebaseUser, User } from "../../store/Store";
import ApiService from "../../services/ApiService";
import Loading from "../Loading/Loading";
import ProfileImageLoader from "../ProfileImageLoader/ProfileImageLoader";
import { firebaseSignOut } from "../../helpers/FirebaseFunctions";
import { INotification } from "../../helpers/Interfaces";

import "./SideBar.scss";

export default function SideBar(props: { selectedIndex: number }) {
    let screenWidth = getCurrentDimension().width

    const apiService = new ApiService();

    const [loading, setLoading] = useState(false);

    const [user] = useContext(User);
    const [firebaseUser] = useContext(FirebaseUser);

    const navigate = useNavigate();

    function getNewNotificationsCount() {
        let newNotifications: INotification[] = firebaseUser?.notifications?.filter((notification: INotification) => {
            return !notification?.read
        });

        return newNotifications?.length;
    }

    function handleLogout() {

        setLoading(true);

        apiService.logout().finally(async () => {
            removeTokens();
            await firebaseSignOut();
            navigate("/login");
            setLoading(false);
        });
    }

    return (<div className="side-bar-container">
        <img className="logo" src={Logo} alt={"Logo"} />
        <img className="logo-small" src={LogoSmall} alt={"Logo"} />

        <div className="nav-link-container">
            {screenWidth > 425 && (props.selectedIndex === 0
                ? (<Link className="nav-link active" to={"/dashboard/home"}>
                    <img className="nav-img" src={ActiveHome} alt={"Home"} />
                    <span className="text active">Home</span>
                </Link>)
                : (<Link className="nav-link" to={"/dashboard/home"}>
                    <img className="nav-img" src={InactiveHome} alt={"Home"} />
                    <span className="text">Home</span>
                </Link>))
            }

            {props.selectedIndex === 1
                ? (<Link className="nav-link active" to={"/dashboard/reports"}>
                    <img className="nav-img" src={ActiveReports} alt={"Reports"} />
                    <span className="text active">Reports</span>
                </Link>)
                : (<Link className="nav-link" to={"/dashboard/reports"}>
                    <img className="nav-img" src={InactiveReports} alt={"Reports"} />
                    <span className="text">Reports</span>
                </Link>)
            }

            {isInRole(user?.roles, "Admin") &&
                (props.selectedIndex === 2
                    ? (<Link className="nav-link active" to={"/dashboard/doctors"}>
                        <img className="nav-img" src={ActiveDoctors} alt={"Doctors"} />
                        <span className="text active">Doctors</span>
                    </Link>)
                    : (<Link className="nav-link" to={"/dashboard/doctors"}>
                        <img className="nav-img" src={InactiveDoctors} alt={"Doctors"} />
                        <span className="text">Doctors</span>
                    </Link>)
                )
            }

            {isInRole(user?.roles, "Admin") &&
                (props.selectedIndex === 3
                    ? (<Link className="nav-link active" to={"/dashboard/users"}>
                        <img className="nav-img" src={ActiveUsers} alt={"Users"} />
                        <span className="text active">List of Users</span>
                    </Link>)
                    : (<Link className="nav-link" to={"/dashboard/users"}>
                        <img className="nav-img" src={InactiveUsers} alt={"Users"} />
                        <span className="text">List of Users</span>
                    </Link>)
                )
            }

            {props.selectedIndex === 4
                ? (<Link className="nav-link active" to={"/dashboard/templates"}>
                    <img className="nav-img" src={ActiveTemplates} alt={"Templates"} />
                    <span className="text active">Templates</span>
                </Link>)
                : (<Link className="nav-link" to={"/dashboard/templates"}>
                    <img className="nav-img" src={InactiveTemplates} alt={"Templates"} />
                    <span className="text">Templates</span>
                </Link>)
            }

            {(isInRole(user?.roles, "Admin") && screenWidth > 425) &&
                (props.selectedIndex === 5
                    ? (<Link className="nav-link active" to={"/dashboard/analytics"}>
                        <img className="nav-img" src={ActiveAnalytics} alt={"Analytics"} />
                        <span className="text active">Analytics</span>
                    </Link>)
                    : (<Link className="nav-link" to={"/dashboard/analytics"}>
                        <img className="nav-img" src={InactiveAnalytics} alt={"Analytics"} />
                        <span className="text">Analytics</span>
                    </Link>)
                )
            }

            {props.selectedIndex === 6
                ? (<Link className="nav-link active" to={"/dashboard/domain"}>
                    <img className="nav-img" src={ActiveKnowledgeBase} alt={"domain"} />
                    <span className="text active">Knowledge Base</span>
                </Link>)
                : (<Link className="nav-link" to={"/dashboard/domain"}>
                    <img className="nav-img" src={InactiveKnowledgeBase} alt={"domain"} />
                    <span className="text">Knowledge Base</span>
                </Link>)
            }
            {props.selectedIndex === 7
                ? (<Link className="nav-link active" to={"/dashboard/settings"}>
                    <img className="nav-img" src={ActiveSettings} alt={"Settings"} />
                    <span className="text active">Settings</span>
                </Link>)
                : (<Link className="nav-link" to={"/dashboard/settings"}>
                    <img className="nav-img" src={InactiveSettings} alt={"Settings"} />
                    <span className="text">Settings</span>
                </Link>)
            }

            {props.selectedIndex === 8
                ? (<Link className="notification-link-wrapper active" to={"/dashboard/notifications"}>
                    <img className="notification-img" src={ActiveNotification} alt={"Notifications"} />
                    <span className="notification-text">Notifications
                        {getNewNotificationsCount() > 0
                            ? (<span className="count">{getNewNotificationsCount()}</span>)
                            : (<Fragment />)
                        }
                    </span>
                </Link>)
                : (<Link className="notification-link-wrapper" to={"/dashboard/notifications"}>
                    <img className="notification-img" src={InactiveNotification} alt={"Notifications"} />
                    <span className="notification-text">
                        <span className="text">
                            Notifications
                        </span>
                        {getNewNotificationsCount() > 0
                            ? (<span className="count">{getNewNotificationsCount()}</span>)
                            : (<Fragment />)
                        }
                    </span>
                </Link>)
            }

        </div>
        <div className="profile">
            <span className="profile-text">Profile</span>
            <div className="image-text">
                <Link className="nav-text" to={"/dashboard/settings"}>
                    <ProfileImageLoader name={user?.firstName + " " + user?.lastName}
                        imageUrl={user?.profileImageUrl} style={"nav-img"} />
                </Link>
                <div className="text">
                    <span className="name">{user?.firstName}</span>
                    <span className="role">{separateRoles(user?.roles)}</span>
                </div>
            </div>
            <Link className="logout" to="#" onClick={() => { handleLogout() }}>
                {loading
                    ?
                    (<Loading color="black" />)
                    : (<>
                        <img className="logout-img" src={LogoutIcon} alt={"Logout"} />
                        <span className="logout-text">Logout</span>
                    </>)
                }
            </Link>
        </div>
    </div>)
}