import { useState, useEffect } from 'react'
import { validStringField } from '../../helpers/HelperFunctions';
import { IClinic, IDoctor } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import Modal from '../Modal/Modal';

import "./ViewClinic.scss"

function ViewClinic(props: { open: boolean, close: CallableFunction, selectedClinic: IClinic, openEdit: CallableFunction }) {

    const apiService = new ApiService();

    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState([] as IDoctor[]);

    useEffect(() => {
        if (props.selectedClinic.id) {
            getDoctors();
        }
    }, [props.selectedClinic.id])

    const handleClose = (shouldUpdate: boolean) => {
        props.close("Clinic", "view", shouldUpdate);
    }

    const handleOpenEdit = () => {
        props.openEdit("Clinic", "edit");
        handleClose(false);
    }

    const closeAlert = () => {
        let newShowAlert = showAlert;
        newShowAlert.open = false;
        setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
    }

    function getDoctors() {

        setLoading(true);

        let data = {
            clinicId: props.selectedClinic.id
        }

        apiService.getDoctorsByClinicId(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
                return;
            }

            setDoctors(data?.doctors);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert((prevDetails) => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal showAlert={showAlert} closeAlert={closeAlert} editable={true} openEdit={handleOpenEdit} open={props.open}
            close={handleClose} styles={"view-view-clinic-card"} >
            <div className="view-clinic-container">
                <div className='title'>View Clinic</div>
                <div className="content">

                    <div className="row">
                        <div className="data">
                            <span className="header">{"Clinic Name"}</span>
                            <span className="body">{validStringField(props.selectedClinic.clinicName)}</span>
                        </div>
                        <div className="data">
                            <span className="header">{"Address"}</span>
                            <span className="body">{validStringField(props.selectedClinic.address)}</span>
                        </div>
                        <div className="data">
                            <span className="header">{"Contact Person"}</span>
                            <span className="body">{validStringField(props.selectedClinic.contactPerson)}</span>
                        </div>
                    </div>
                    <div className="view-data">
                        <span className="header">Doctors</span>

                        <div className="details">
                            {doctors.map((doctor) => {
                                return (
                                    <div key={doctor.id} className="data">
                                        <span className="name">{validStringField(doctor.firstName + " " + doctor.lastName)}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewClinic