import React, { useContext, useRef, useState } from 'react'
// import Doctor from "../../assets/images/Doctor.png";
import BlueLogo from "../../assets/images/BlueLogo.svg";
import ApiService from '../../services/ApiService';
import MuiAlert from '../../components/Alert/Alert';
import { Link, useNavigate } from "react-router-dom";
import { userEncrypt, saveTokens } from '../../helpers/HelperFunctions';
import { User } from '../../store/Store';
import { firebaseSignIn } from '../../helpers/FirebaseFunctions';
import OtpForm from './OtpForm';
import LoginForm from './LoginForm';
import "./Login.scss"

function Login() {
    const apiService = new ApiService();
    const [loading, setLoading] = useState(false);
    const [loginForm, setLoginForm] = useState({ email: "", password: "" });
    const [loginFormErrors, setLoginFormErrors] = useState({
        emailError: { showError: false, message: "" },
        passwordError: { showError: false, message: "" },
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [otp, setOtp] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [_, setUser] = useContext(User);

    const navigate = useNavigate();

    function sendOtp() {
        setLoading(true);
        if (otp.length !== 8) {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Invalid OTP";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
            setLoading(false);
            return;
        } else {
            let newShowAlert = showAlert;
            newShowAlert.open = false;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }
        let data = {
            email: loginForm.email,
            otp: otp
        };

        apiService.login(data).then(async (result) => {
            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }

            let firebaseLoginResult = await firebaseSignIn(data?.token);

            if (firebaseLoginResult) {
                saveTokens(data.token, data.refreshToken);
                setUser(data?.user);
                userEncrypt(data?.user);
                return navigate("/dashboard/home");
            } else {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = "Login Failed. Please Contact Your Administrator";
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
            }
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function login() {
        setLoading(true);
        if (loginForm.email.trim() === "") {
            let newLoginFormErrors = loginFormErrors;
            newLoginFormErrors.emailError.showError = true;
            newLoginFormErrors.emailError.message = "REQUIRED";
            setLoginFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
            setLoading(false);
            return;
        } else {
            let newLoginFormErrors = loginFormErrors;
            newLoginFormErrors.emailError.showError = false;
            setLoginFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
        }

        if (loginForm.password.trim() === "") {
            let newLoginFormErrors = loginFormErrors;
            newLoginFormErrors.passwordError.showError = true;
            newLoginFormErrors.passwordError.message = "REQUIRED";
            setLoginFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
            setLoading(false);
            return;
        } else {
            let newLoginFormErrors = loginFormErrors;
            newLoginFormErrors.passwordError.showError = false;
            setLoginFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
        }

        let data = {
            email: loginForm.email,
            password: loginForm.password
        };

        apiService.otp(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }

            setShowOtp(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='login-container'>
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                }} />
            <div className='login-body'>
                {/*<div className="doctor-content">
                    <img className='login-img' src={Doctor} alt="Doctor" />
            </div>*/}
                <div className="login-data">
                    <img src={BlueLogo} alt="Logo" />
                    <div className="text-content">
                        <span className='text-content-header'>Hello Again!</span>
                    </div>
                    {showOtp
                        ? (<OtpForm email={loginForm.email} sendOtp={sendOtp} login={login} loading={loading} otp={otp} setOtp={setOtp} />)
                        : (<LoginForm loginFormErrors={loginFormErrors} loginForm={loginForm} setLoginForm={setLoginForm} loading={loading} login={login} resetPassword={() => { navigate("/forget-password") }} />)
                    }
                </div>
            </div>
            <span className='footer-text'>
                {"© 2020 Scribe on Time Pty Ltd. All rights reserved. "}
                <Link className='privacy' target='_blank' to={'/privacy-policy'}>{"Privacy Policy"}</Link>
            </span>
        </div>
    )
}

export default Login