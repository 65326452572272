import { IReport } from "../../helpers/Interfaces";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import { convertDuration, divideCompletedStages, getPriority, validDateField, validStringField } from "../../helpers/HelperFunctions";
import ArrowRightIcon from "../../assets/images/ArrowRight.svg";
import { useCallback, useState } from "react";
import { ReportFilterTypes } from "../../helpers/Types";
import SearchableDown from "../../components/DropDown/DropDown";
import AccordionOpen from "../../assets/images/AccordionOpenBlack.svg";

export default function AssignedReportsUI(props: {
    reports: IReport[], setSelectedReport: CallableFunction;
    openView: CallableFunction; filter: (filterBy: ReportFilterTypes, query: string) => void;
    cancelFilter: boolean; resetDefault: CallableFunction; setShowFilter?: CallableFunction
}) {

    const [selectedFilter, setSelectedFilter] = useState<ReportFilterTypes>("patientName");
    const [isDueDateClicked, setIsDueDateClicked] = useState(false);
    const [isAscending, setIsAscending] = useState(true);

    const handleClick = useCallback((selectedReport: IReport) => {
        props.setSelectedReport(selectedReport);
        props.openView("view");
    }, []);

    const handleSearchOpen = (filterBy: ReportFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const sortByDueDate = (): IReport[] => {
        const sortedReports: IReport[] = [...props.reports];

        sortedReports.sort((a, b) => {
            const dateA = a.dueDate ? new Date(a.dueDate) : new Date(0);
            const dateB = b.dueDate ? new Date(b.dueDate) : new Date(0);

            return isAscending ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });
        return sortedReports;
    };

    const handleDueDateClick = () => {
        setIsAscending(!isAscending);
        setIsDueDateClicked(true);
        props.setShowFilter!(true);
    };

    const handleClearFilter = () => {
        setIsDueDateClicked(false);
        props.resetDefault();
        props.setShowFilter!(false);
    };

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);

    };

    function getInitialValues() {
        let valueArr = [] as any[];
        props.reports.forEach(report => {
            if (report[selectedFilter] != null) {
                valueArr.push(report[selectedFilter])
            }
            else {
                valueArr.push('No Value')
            }
        });
        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);
        return distinctValues;
    }

    const sortedReports = isDueDateClicked
        ? sortByDueDate() : props.reports;

    return (<div className="table">
        <div className="header">
            <div className="header-row medium">
                <span className="text">Patient Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("patientName") }} />
            </div>
            <div className="header-row medium">
                <span className="text">Doctor</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("doctorName") }} />
            </div>
            <div className="header-row medium">
                <span className="text">Completed Stages</span>
            </div>
            <div className="header-row small">
                <span className="text">Status</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("stage") }} />
            </div>
            <div className="header-row medium">
                <span className="text">Assigned To</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("assignee") }} />
            </div>
            <div className="header-row medium clickable" onClick={() => handleDueDateClick()}>
                <span className="text">Due Date</span>
                {isDueDateClicked ? <img src={AccordionOpen} alt="SortArrow" className={isAscending ? "image" : "invert-image"} /> : <div />}
            </div>
            <div className="header-row small">
                <span className="text">Audio Duration</span>
            </div>
            <div className="header-row small">
                <span className="text">Urgency</span>
                <SearchableDown searchable={false} initialDetails={getInitialValues()}
                    onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("priority") }} />
            </div>
            <div className="header-row extra-small justify-end">
                {props.cancelFilter
                    ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                    : <div className="filter-icon" />
                }
            </div>
        </div>
        <div className="data">
            {sortedReports.map((report) => {
                return (<div key={report.id} className="data-row pending-padding"
                    onClick={() => { handleClick(report); }} >
                    <div className="cell text medium">{validStringField(report.patientName)}</div>
                    <div className="cell text medium">{validStringField(report.doctorName)}</div>
                    <div className="cell text medium">{divideCompletedStages(report.completedStages)}</div>
                    <div className="cell text small">{validStringField(report.stage)}</div>
                    <div className="cell text medium">{validStringField(report.assignee)}</div>
                    <div className="cell text medium">
                        <span>{validDateField(report.dueDate)}</span>
                    </div>
                    <div className="cell text small">{convertDuration(report.totalDuration)}</div>
                    <div className="cell text small justify">
                        {getPriority(report.priority)}
                    </div>
                    <div className="cell text extra-small justify-end">
                        <img className="icon" src={ArrowRightIcon} alt={"ArrowRight"} />
                    </div>
                </div>)
            })}
        </div>
    </div>)
}