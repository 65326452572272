import ArrowRightIcon from "../../assets/images/ArrowRight.svg";
import Trash from "../../assets/images/Trash.svg";
import { IDoctor } from "../../helpers/Interfaces";
import { validStringField } from "../../helpers/HelperFunctions";
import { useCallback, useState } from "react";
import { DoctorFilterTypes } from "../../helpers/Types";
import RemoveFilter from "../../assets/images/RemoveFilter.svg";
import SearchableDown from "../../components/DropDown/DropDown";

type DoctorsUIProps = {
    doctors: IDoctor[];
    setSelectedDoctor: CallableFunction;
    openModal: (type: "Clinic" | "Doctor" | "Support Staff", modal: "add" | "edit" | "view" | "delete") => void;
    filter: (filterBy: DoctorFilterTypes, query: string) => void;
    cancelFilter: boolean;
    resetDefault: CallableFunction;
}

export default function DoctorsUI(props: DoctorsUIProps) {

    const [selectedFilter, setSelectedFilter] = useState<DoctorFilterTypes>("firstName");

    const handleClick = useCallback((doctor: IDoctor) => {
        props.setSelectedDoctor(doctor);
        props.openModal("Doctor", "view");
    }, [])

    const handleDelete = useCallback((doctor: IDoctor) => {
        props.setSelectedDoctor(doctor);
        props.openModal("Doctor", "delete");
    }, [])

    const handleSearchOpen = (filterBy: DoctorFilterTypes) => {
        setSelectedFilter(filterBy);
    };

    const handleSelectedItem = (selectedValue: string) => {
        props.filter(selectedFilter, selectedValue);
    };

    const handleClearFilter = () => {
        props.resetDefault()
    };

    function getInitialValues() {

        let valueArr = [] as any[];

        props.doctors.forEach(doctor => {
            if (doctor[selectedFilter] != null) {
                valueArr.push(doctor[selectedFilter])
            }
        });

        let distinctValues = valueArr.filter((value, index, array) => array.indexOf(value) === index);

        return distinctValues;
    }

    return (<div className="table">
        <div className="header">
            <div className="header-row">
                <span className="text">Doctor Name</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("firstName") }} />
            </div>
            <div className="header-row">
                <span className="text">Telephone Number</span>
            </div>
            <div className="header-row">
                <span className="text">Email Address</span>
                <SearchableDown searchable={true} initialDetails={getInitialValues()} onSelect={(selectedValue: string) => { handleSelectedItem(selectedValue); }}
                    setSelectedFilter={function (): void { handleSearchOpen("email") }} />
                <div className="size-box" />
                <div className="filter-content">
                    {props.cancelFilter
                        ? <img className="filter-icon" src={RemoveFilter} alt={"RemoveFilter"} onClick={handleClearFilter} />
                        : <div className="filter-icon" />
                    }
                </div>
            </div>
        </div>
        <div className="data">
            {props.doctors.map((doctor) => {
                return (<div key={doctor.id} className="data-row">
                    <div className="cell text">{validStringField(doctor.firstName + " " + doctor.lastName)}</div>
                    <div className="cell text">{validStringField(doctor.phoneNumber)}</div>
                    <div className="cell text">{validStringField(doctor.email)}
                        <div className="icon-container">
                            <img className="icon" src={ArrowRightIcon} alt={"ArrowRight"} onClick={() => { handleClick(doctor) }} />
                            <img className="icon space" src={Trash} alt={"Delete"} onClick={() => { handleDelete(doctor) }} />
                        </div>
                    </div>
                </div>)
            })}
        </div>
    </div>)
}