import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useState, useEffect } from 'react'
import AccordionOpen from "../../assets/images/AccordionOpenBlack.svg";
import { convertToMinutes } from '../../helpers/HelperFunctions';
import { IDoctorSummary, IDoctorSummaryClinic } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import moment from "moment";

type DoctorSummaryProps = {
    setLoading: (loading: boolean) => void;
    setShowAlert: (alert: { isError: boolean, message: string, open: boolean }) => void;
}

function DoctorSummary(props: DoctorSummaryProps) {

    const apiService = new ApiService();

    const [doctorSummary, setDoctorSummary] = useState([] as IDoctorSummary[]);
    const [selectedTab, setSelectedTab] = useState({} as IDoctorSummary);
    const [formData, setFormData] = useState<{ fromDate: moment.Moment | null, toDate: moment.Moment | null }>({
        fromDate: null,
        toDate: null
    });

    useEffect(() => {
        if ((formData.fromDate != null && formData.toDate != null) || (formData.fromDate == null && formData.toDate == null)) {
            getDoctorSummary();
        }
    }, [formData.fromDate, formData.toDate])

    function getDoctorSummary() {

        props.setLoading(true);
        setDoctorSummary([] as IDoctorSummary[]);
        let data = {
            fromDate: formData.fromDate,
            toDate: formData.toDate
        }

        apiService.getDoctorSummary(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = { isError: true, message: data?.errorMessage, open: true };
                props.setShowAlert(newShowAlert);
                return;
            }

            setDoctorSummary(data?.doctors)
        }).catch(() => {
            let newShowAlert = { isError: true, message: "Unexpected Error", open: true };
            props.setShowAlert(newShowAlert);
        }).finally(() => {
            props.setLoading(false);
        });
    }

    function getReceivedTotal(clinics: IDoctorSummaryClinic[]) {

        let receivedTotal = 0;

        clinics.forEach((clinic) => {
            receivedTotal = receivedTotal + clinic.total;
        });

        return convertToMinutes(receivedTotal);
    }

    function getPendingTotal(clinics: IDoctorSummaryClinic[]) {

        let pendingTotal = 0;

        clinics.forEach((clinic) => {
            pendingTotal = pendingTotal + clinic.pending;
        });

        return convertToMinutes(pendingTotal);
    }

    function getCompletedTotal(clinics: IDoctorSummaryClinic[]) {

        let completedTotal = 0;

        clinics.forEach((clinic) => {
            completedTotal = completedTotal + clinic.completed;
        });

        return convertToMinutes(completedTotal);
    }

    function getAssignedTotal(clinics: IDoctorSummaryClinic[]) {

        let assignedTotal = 0;

        clinics.forEach((clinic) => {
            assignedTotal = assignedTotal + clinic.assigned;
        });

        return convertToMinutes(assignedTotal);
    }

    function getSubmittedTotal(clinics: IDoctorSummaryClinic[]) {

        let submittedTotal = 0;

        clinics.forEach((clinic) => {
            submittedTotal = submittedTotal + clinic.submitted;
        });

        return convertToMinutes(submittedTotal);
    }

    return (
        <div className="body">
            <div className="header-data top-header">
                <div className="header-row name large1">
                    <span className="title">Doctors</span>
                </div>
                <div style={{ marginBottom: "16px", marginRight: "50px" }}>
                    <DateRangePicker startPlaceHolder="FROM*" endPlaceHolder="TO*"
                        onChange={(startDate, endDate) => {
                            let newFormData = formData;
                            formData.fromDate = startDate ? moment.utc(startDate) : null;
                            formData.toDate = endDate ? moment.utc(endDate) : null;
                            setFormData((prevDetails) => ({ ...prevDetails, ...newFormData }));
                        }} />
                </div>
            </div>
            <div className="data">
                <div className="header-data top-header">
                    <div className="header-row name large1">
                        <span className="value">Doctor Name</span>
                    </div>
                    <div className="data-row-header">
                        <div className="header-row value small">
                            <span className="value">Received</span>
                        </div>
                        <div className="header-row value small">
                            <span className="value">Pending</span>
                        </div>
                        <div className="header-row value small">
                            <span className="value">Assigned</span>
                        </div>
                        <div className="header-row value small">
                            <span className="value">Submitted</span>
                        </div>
                        <div className="header-row value small">
                            <span className="value">Completed</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="according-content">
                {doctorSummary.map((summary) => {
                    return (
                        <Accordion onClick={() => {
                            if (summary?.doctorName === selectedTab?.doctorName) {
                                setSelectedTab({} as IDoctorSummary);
                            } else {
                                setSelectedTab(summary);
                            }
                        }} key={summary.doctorName}
                            expanded={summary?.doctorName === selectedTab?.doctorName}
                            className='accordion-root' sx={[{ marginTop: "10px", borderRadius: "12px !important", borderBottom: "none" }]}>
                            <AccordionSummary
                                expandIcon={<img src={AccordionOpen} alt="Arrow" />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <div className="acording-header">
                                    <div className="data">
                                        <div className="header-data">
                                            <div className="header-row name large2">
                                                <span className="name">{summary.doctorName}</span>
                                            </div>
                                            <div className="data-row-header">
                                                <div className="header-row value small">
                                                    <span className="value">{getReceivedTotal(summary.clinics) + " min"}</span>
                                                </div>
                                                <div className="header-row value small">
                                                    <span className="value">{getPendingTotal(summary.clinics) + " min"}</span>
                                                </div>
                                                <div className="header-row value small">
                                                    <span className="value">{getAssignedTotal(summary.clinics) + " min"}</span>
                                                </div>
                                                <div className="header-row value small">
                                                    <span className="value">{getSubmittedTotal(summary.clinics) + " min"}</span>
                                                </div>
                                                <div className="header-row value small">
                                                    <span className="value">{getCompletedTotal(summary.clinics) + " min"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails >
                                <div className="accordionDetails">
                                    {summary.clinics.map((clinic) => {
                                        return (
                                            <div key={clinic.clinicName} className="data data-row">
                                                <div className="header-data">
                                                    <div className="header-row name large3">
                                                        <span className="name">{clinic.clinicName}</span>
                                                    </div>
                                                    <div className="data-row-header">
                                                        <div className="header-row value small">
                                                            <span className="value">{convertToMinutes(clinic.total) + " min"}</span>
                                                        </div>
                                                        <div className="header-row value small">
                                                            <span className="value">{convertToMinutes(clinic.pending) + " min"}</span>
                                                        </div>
                                                        <div className="header-row value small">
                                                            <span className="value">{convertToMinutes(clinic.assigned) + " min"}</span>
                                                        </div>
                                                        <div className="header-row value small">
                                                            <span className="value">{convertToMinutes(clinic.submitted) + " min"}</span>
                                                        </div>
                                                        <div className="header-row value small">
                                                            <span className="value">{convertToMinutes(clinic.completed) + " min"}</span>
                                                        </div>
                                                        {/* <div className="header-row small" /> */}
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div >
    )
}

export default DoctorSummary