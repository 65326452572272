import { DatePicker } from 'antd';

import "./DateRangePicker.scss"

type DateRangePickerProps = {
    style?: "home";
    startPlaceHolder: string;
    endPlaceHolder: string;
    onChange: (startDate: Date | null, endDate: Date | null) => void;
}

function DateRangePicker(props: DateRangePickerProps) {

    const { RangePicker } = DatePicker;

    return (
        <div className={`date-range-picker ${props.style}`}>
            <RangePicker placeholder={[props.startPlaceHolder, props.endPlaceHolder]} format={"DD-MM-YYYY"}
                onChange={(data: any) => {
                    if (data) { props.onChange(data[0].$d, data[1].$d); }
                    else {
                        props.onChange(null, null);
                    }
                }} />
        </div>
    )
}

export default DateRangePicker