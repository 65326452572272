import { Fragment, useMemo, useState } from 'react'
import { getDocument } from '../../helpers/FirebaseFunctions';
import { IReport, IReportData, IRequestedChange } from '../../helpers/Interfaces';
import { getTimeDifference, validDateField } from '../../helpers/HelperFunctions';
import Loading from '../Loading/Loading';
import SendRequest from '../../assets/images/SendRequest.svg';

type ReportViewProps = {
    readonly report: IReport;
    readonly requestedChanges: IRequestedChange[];
    readonly requestChanges: CallableFunction;
    readonly loading: boolean;
    readonly setShowAlert: CallableFunction;
    readonly showAlert: any;
    readonly setLoading: CallableFunction;
}

function ReportView(props: ReportViewProps) {
    const [report, setReport] = useState({} as IReportData);
    const [requestedChange, setRequestedChange] = useState("");

    useMemo(() => {
        getDocumentMedia()
    }, [props.report]);

    function requestChanges() {

        props.setLoading(true);

        if (requestedChange === "") {
            let newShowAlert = props.showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Comment is Required";
            newShowAlert.open = true;
            props.setShowAlert((prevDetails: any) => ({ ...prevDetails, ...newShowAlert }));
            props.setLoading(false);
            return;
        }

        let data = {
            documentId: props.report.id,
            requestedChange: requestedChange
        }

        props.requestChanges(data);
    }

    async function getDocumentMedia() {
        await getDocument(props.report.id, setReport);
    }

    return (<div className='view-report'>
        <div className="document-view">
            {report?.pdf?.link
                ? (<iframe width={'100%'} height={'100%'} src={`${report?.pdf?.link}#toolbar=0`}
                    title="Embedded PDF" />)
                : (<Fragment />)}

            {props.report.documentStatus === "Submitted"
                ? (<div className="requested-changes">
                    <div className="requested-changes-title">
                        Requested Changes
                    </div>
                    <div className="requested-changes-view">
                        {props.requestedChanges?.map((change, index) => (
                            <div className="requested-changes-card-outline" key={index}>
                                <div className="requested-changes-card">
                                    <div className="changes-requested-header">
                                        <div className="user-name">{change.changeRequestedBy}</div>
                                        {!change.completedDate
                                            ? (<span>Pending</span>)
                                            : (<Fragment />)
                                        }
                                    </div>
                                    {change.completedDate
                                        ? (<div className="changes-completed-header">
                                            Completed<span>{validDateField(change?.completedDate)}</span>
                                        </div>)
                                        : (<Fragment />)
                                    }
                                    <div className="changes-note">
                                        {change.requestedChange}
                                    </div>
                                    <div className="duration">{getTimeDifference(change.createdDate)}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="add-request">
                        <div className="add-request-comment">
                            <textarea value={requestedChange} onChange={(data) => {
                                setRequestedChange(data.target.value);
                            }} rows={1}
                                placeholder="Enter Comment" />
                        </div>
                        {props.loading
                            ? (<Loading color='orange' />)
                            : (<img onClick={requestChanges} src={SendRequest} alt="SendRequest" />)}

                    </div>
                </div>)
                : (<Fragment />)}
        </div>
    </div>)
}

export default ReportView