import React, { useContext, useEffect, useRef, useState } from 'react'
// import Doctor from "../../assets/images/Doctor.png";
import BlueLogo from "../../assets/images/BlueLogo.svg";
import ApiService from '../../services/ApiService';
import MuiAlert from '../../components/Alert/Alert';
import { Link, useNavigate } from "react-router-dom";
import { userEncrypt, saveTokens } from '../../helpers/HelperFunctions';
import { User } from '../../store/Store';
import { firebaseSignIn } from '../../helpers/FirebaseFunctions';
import "./ForgetPassword.scss";
import ForgetPasswordForm from './ForgetPasswordForm';
import EmailSent from './EmailSent';

function ForgetPassword() {
    const apiService = new ApiService();
    const [loading, setLoading] = useState(false);
    const [resetForm, setResetForm] = useState({ email: "" });
    const [resetPasswordFormErrors, setResetPasswordFormErrors] = useState({
        emailError: { showError: false, message: "" },
    });
    const [showAlert, setShowAlert] = useState({ isError: false, message: "", open: false });
    const [showEmailSent, setShowEmailSent] = useState(false);

    const navigate = useNavigate();

    function resetPassword() {
        setLoading(true);
        if (resetForm.email.trim() === "") {
            let newLoginFormErrors = resetPasswordFormErrors;
            newLoginFormErrors.emailError.showError = true;
            newLoginFormErrors.emailError.message = "REQUIRED";
            setResetPasswordFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
            setLoading(false);
            return;
        } else {
            let newLoginFormErrors = resetPasswordFormErrors;
            newLoginFormErrors.emailError.showError = false;
            setResetPasswordFormErrors(prevDetails => ({ ...prevDetails, ...newLoginFormErrors }));
        }
        let data = {
            email: resetForm.email,
        };

        apiService.forgetPassword(data).then((result) => {

            let data = result.data;

            if (!data?.isSuccessful) {
                let newShowAlert = showAlert;
                newShowAlert.isError = true;
                newShowAlert.message = data?.errorMessage;
                newShowAlert.open = true;
                setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                setLoading(false);
                return;
            }

            setShowEmailSent(true);
        }).catch(() => {
            let newShowAlert = showAlert;
            newShowAlert.isError = true;
            newShowAlert.message = "Unexpected Error";
            newShowAlert.open = true;
            setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
        }).finally(() => {
            setLoading(false);
        })
    }

    function backToLogin() {
        navigate("/login");
    }

    return (
        <div className='login-container'>
            <MuiAlert error={showAlert.isError} message={showAlert.message} open={showAlert.open}
                onClose={() => {
                    let newShowAlert = showAlert;
                    newShowAlert.open = false;
                    setShowAlert(prevDetails => ({ ...prevDetails, ...newShowAlert }));
                }} />
            <div className='login-body'>
                {/*<div className="doctor-content">
                    <img className='login-img' src={Doctor} alt="Doctor" />
            </div>*/}
                <div className="login-data">
                    <img src={BlueLogo} alt="Logo" />

                    {showEmailSent
                        ? <EmailSent email={resetForm.email} loading={loading} backToLogin={backToLogin} />
                        : <ForgetPasswordForm passwordResetFormErrors={resetPasswordFormErrors} resetForm={resetForm} setResetForm={setResetForm} loading={loading} reset={() => resetPassword()} backToLogin={backToLogin} />
                    }
                </div>
            </div>
            <span className='footer-text'>
                {"© 2020 Scribe on Time Pty Ltd. All rights reserved. "}
                <Link className='privacy' target='_blank' to={'/privacy-policy'}>{"Privacy Policy"}</Link>
            </span>
        </div>
    )
}

export default ForgetPassword