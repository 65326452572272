import Loading from "../Loading/Loading";

import "./DocumentButton.scss";

function DocumentButton(props: { text: string; onClick: CallableFunction; loading: boolean; backgroundColor?: string }) {
  return (<button style={{ backgroundColor: props.backgroundColor }} disabled={props.loading} className="btn doc-button-container"
    onClick={() => { props.onClick(); }}>
    {props.loading
      ? (<Loading color="white" />)
      : (<>{props.text}</>)
    }
  </button>)
}

export default DocumentButton

